import { type FunctionComponent } from 'react';
import { CarSalesStatusEnum } from '~/gql/generated/graphql';
import styles from '~/styles/shared/car-status-tabs.module.css';

const blockClass = 'car-status-tabs';

type Props = {
  status: CarSalesStatusEnum;
  onChangeStatus: (status: CarSalesStatusEnum) => void;
};

export const CarStatusBar: FunctionComponent<Props> = (props) => {
  const { status, onChangeStatus } = props;

  return (
    <div className={styles[blockClass]}>
      <p
        className={`${styles[`${blockClass}__tab`]} ${
        status === CarSalesStatusEnum.OnSale ? styles[`${blockClass}__tab--active`] : ''}`
        }
        onClick={() => onChangeStatus(CarSalesStatusEnum.OnSale)}>

        販売中
      </p>
      <p
        className={`${styles[`${blockClass}__tab`]} ${
        status === CarSalesStatusEnum.Closed ? styles[`${blockClass}__tab--active`] : ''}`
        }
        onClick={() => onChangeStatus(CarSalesStatusEnum.Closed)}>

        ご成約した車
      </p>
    </div>);

};