import { useEffect, type FunctionComponent } from 'react';
import { useAnalytics } from '~/hooks';
import { useCameraCapture } from '~/hooks/useCameraCapture';
import styles from '~/styles/shared/inspectionCertificateScanModal/capture.module.css';
import { CameraAccessPopup } from '../CameraAccessPopup/CameraAccessPopup';
import { CaptureNotice } from './CaptureNotice';

const blockClass = 'capture';

type Props = {
  onCaptureImage: (imageFile: File) => void;
  onUploadedImage: (imageFile: File) => void;
  setShowLoading: (showLoading: boolean) => void;
  onError: () => void;
  onClose: () => void;
};

export const Capture: FunctionComponent<Props> = (props) => {
  const { onCaptureImage, onUploadedImage, setShowLoading, onError, onClose } = props;
  const { sendTrackEvent } = useAnalytics();
  const { videoRef, isCapturing, isCameraBlocked, startCamera, capture } = useCameraCapture();

  useEffect(() => {
    startCamera();
  }, [startCamera]);

  const handleClickCaptureButton = async () => {
    sendTrackEvent('click_selling_form_camera_capture_button');
    const imageFile = await capture();
    if (!imageFile) {
      onError();
      return;
    }
    onCaptureImage(imageFile);
  };

  return (
    <div className={styles[blockClass]}>
      <video className={styles[`${blockClass}__video`]} ref={videoRef} autoPlay playsInline muted />
      <CaptureNotice onUploadedImage={onUploadedImage} setShowLoading={setShowLoading} onError={onError} />
      <button
        className={styles[`${blockClass}__button`]}
        onClick={handleClickCaptureButton}
        disabled={isCapturing || isCameraBlocked}>

        <div className={styles[`${blockClass}__button-circle`]} />
      </button>
      {isCameraBlocked && <CameraAccessPopup close={onClose} />}
    </div>);

};