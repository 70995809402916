type Props = {
  isFavorite: boolean;
  src?: string;
  alt: string;
  handleFavorite?: () => void;
};

export const CarCardImageNormal = ({ isFavorite, src, alt, handleFavorite }: Props) => {
  return (
    <div className='relative size-full'>
      <img className='size-full rounded-md object-cover' src={src ? src : '/images/pictures/no_image.png'} alt={alt} />
      <img
        onClick={(e) => {
          e.stopPropagation();
          handleFavorite?.();
        }}
        className='absolute right-3 top-3 size-6'
        src={`/images/icons/${isFavorite ? 'ic_favorite_filled.svg' : 'ic_favorite_white.svg'}`}
        alt='favorite'
      />
    </div>
  );
};
