import { graphql } from '../generated';

export const destroyCarStockFavoriteMutationDocument = graphql(`
  mutation destroyCarStockFavorite($input: DestroyCarStockFavoriteInput!) {
    destroyCarStockFavorite(input: $input) {
      isSuccess
      errors {
        message
        path
      }
    }
  }
`);
