import { graphql } from '../generated';

export const convertLatlngQueryDocument = graphql(`
  query convertLatlng($longitude: Float!, $latitude: Float!) {
    convertLatlng(longitude: $longitude, latitude: $latitude) {
      zipCode
      address
      prefectureCode
      errors {
        message
      }
    }
  }
`);
