import { type FunctionComponent } from 'react';
import styles from '~/styles/shared/media-section.module.css';

const blockClass = 'media-section';

export const MediaSection: FunctionComponent = () => {
  const medias = [
  {
    alt: 'JIJI.com',
    icon: '/images/media/img_media_jijicom.jpg'
  },
  {
    alt: 'テレ朝news',
    icon: '/images/media/img_media_tvasahi.jpg'
  },
  {
    alt: 'TBS NEWS DIG',
    icon: '/images/media/img_media_tbs.jpg'
  },
  {
    alt: 'DX王',
    icon: '/images/media/img_media_dxking.jpg'
  },
  {
    alt: '企業トピby新R25',
    icon: '/images/media/img_media_r25.jpg'
  },
  {
    alt: 'MAG',
    icon: '/images/media/img_media_mag.jpg'
  },
  {
    alt: '日韓自動車新聞 電子版',
    icon: '/images/media/img_media_newspaper.jpg'
  },
  {
    alt: 'グーネット',
    icon: '/images/media/img_media_goonet.jpg'
  },
  {
    alt: 'One News',
    icon: '/images/media/img_media_onenews.jpg'
  }];


  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__header`]}>
        <p className={styles[`${blockClass}__header-title`]}>メディア掲載実績</p>
      </div>
      <div className={styles[`${blockClass}__medias`]}>
        {medias.map((media, index) =>
        <div className={styles[`${blockClass}__medias-item`]} key={index}>
            <img src={media.icon} alt={media.alt} className={styles[`${blockClass}__medias-item__icon`]} />
          </div>
        )}
      </div>
    </div>);

};