import { Link } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import styles from '~/styles/shared/breadcumb.module.css';

type Props = {
  text: string;
};

export const Breadcrumb: FunctionComponent<Props> = ({ text }) => {
  return (
    <div className={styles.breadcrumb}>
      <Link to='/' className={styles.top}>
        トップ
      </Link>
      <span className={styles.slash}>/</span>
      <span className={styles.text}>{text}</span>
    </div>);

};