import type { FunctionComponent } from 'react';
import styles from '~/styles/shared/share-modal.module.css';
import { Link } from '@remix-run/react';
import { Modal } from '..';
import { useClipboard } from '@mantine/hooks';
import { isSsr } from '~/utils/ssr';

const blockClass = 'share-modal';

type Props = {
  opened: boolean;
  close: () => void;
  url: string;
  title: string;
};

export const ShareModal: FunctionComponent<Props> = (props) => {
  const { opened, close, url, title } = props;
  const clipboard = useClipboard({ timeout: 1000 });
  const currentPath = !isSsr ? window.location.href : '';

  return (
    <Modal
      isOpen={opened}
      className={styles[blockClass]}
      onRequestClose={close}
      style={{
        overlay: {
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 201
        }
      }}>

      {opened &&
      <>
          <div className={styles[`${blockClass}__close`]} onClick={close}>
            <img src={'/images/icons/ic_close.svg'} alt='close' />
          </div>
          <p className={styles[`${blockClass}__title`]}>シェアする</p>
          <ul className={styles[`${blockClass}__block`]}>
            <li className={styles[`${blockClass}__item`]}>
              <Link to={`https://social-plugins.line.me/lineit/share?url=${url}`}>
                <img src={'/images/icons/ic_share_line.svg'} alt='LINEで友達に送る' />
              </Link>
            </li>
            <li className={styles[`${blockClass}__item`]}>
              <Link to={`https://twitter.com/intent/tweet?url=${url}&text=${title}&hashtags=バディカダイレクト`}>
                <img src={'/images/icons/ic_share_x.svg'} alt='Xで呟く' />
              </Link>
            </li>
            <li className={styles[`${blockClass}__item`]} onClick={() => clipboard.copy(currentPath)}>
              <img src={'/images/icons/ic_share_link.svg'} alt='リンクをコピーする' />
              {clipboard.copied && <p className={styles[`${blockClass}__popup`]}>コピーしました</p>}
            </li>
          </ul>
        </>
      }
    </Modal>);

};