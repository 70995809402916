import { useTimeout } from '@mantine/hooks';
import { useEffect, type FunctionComponent } from 'react';
import { useErrorFlashContext } from '~/providers';
import styles from '~/styles/shared/error-flash.module.css';

export const ErrorFlash: FunctionComponent = () => {
  const { opened, close, errorMessage } = useErrorFlashContext();
  const { start: startCloseTimer } = useTimeout(() => close(), 3000);

  useEffect(() => {
    if (!opened) return;

    startCloseTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  if (!opened) return <></>;

  return (
    <div className={styles['error-flash']}>
      <div className={styles.body}>
        <img src={'/images/icons/ic_error_face.svg'} className={styles.image} alt='error' />
        <div className={styles.text}>
          <p>エラーが発生しました。</p>
          <p>{errorMessage || 'お手数ですがもう一度お試しください。'}</p>
        </div>
      </div>
    </div>);

};