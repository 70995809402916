import { useNavigate } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { useEffect, useRef, useState, type FunctionComponent } from 'react';
import { graphql, useFragment, type FragmentType } from '~/gql/generated';
import { type CarMoviePopup_CarStockFragment } from '~/gql/generated/graphql';
import { useAnalytics, useAuth, useFavorite } from '~/hooks';
import { useDeliveryAddressContext, useErrorFlashContext, useLineFavoriteModalContext } from '~/providers';
import styles from '~/styles/shared/car-movie-popup.module.css';
import { type CarStockLandTransportCostType } from '~/types/landTransportCost';
import { displayMileageKm, priceNumber, priceText, shortCarModelYear } from '~/utils/carStock';

const blockClass = 'car-movie-popup';

const CarMoviesPopupCarStockFragment = graphql(`
  fragment CarMoviePopup_carStock on CarStock {
    id
    ulid
    carModelYear
    mileageKm
    carModelName
    shortGradeName
    totalPaymentAmount
    tags {
      id
      name
    }
    promotion {
      id
      catchCopy
      staffComment
      postedBy {
        id
        imageUrl
        fullName
      }
    }
    videos {
      id
      url
      thumbnailUrl
    }
  }
`);

type Props = {
  carStock: FragmentType<typeof CarMoviesPopupCarStockFragment>;
  hideBackButton: boolean;
  hideNextButton: boolean;
  hideCarContent?: boolean;
  nextMovie?: () => void;
  backMovie?: () => void;
  closePopup: () => void;
};

export const CarMoviePopup: FunctionComponent<Props> = (props) => {
  const carStock = useFragment(CarMoviesPopupCarStockFragment, props.carStock) as CarMoviePopup_CarStockFragment &
  CarStockLandTransportCostType;
  const { hideBackButton, hideNextButton, hideCarContent, nextMovie, backMovie, closePopup } = props;
  const { user } = useAuth();
  const isFavorited = user?.favoritedCarStocks?.map((carStock) => carStock.id).includes(carStock.id) || false;
  const [isMutted, setIsMutted] = useState(true);
  const { favorite } = useFavorite();
  const { open: openfavoriteModal } = useLineFavoriteModalContext();
  const { open: openErrorFlash } = useErrorFlashContext();
  const tag = carStock.tags?.[0]?.name;
  const { prefectureCode } = useDeliveryAddressContext();
  const [landTransportCost, setLandTransportCost] = useState<number | null>(null);
  const { sendTrackEvent } = useAnalytics();
  const [showLoading, setShowLoading] = useState(true);
  const baseElementId = `car-movie-popup-${carStock.id}`;
  const navigate = useNavigate();
  const [playVideoIndex, setPlayVideoIndex] = useState(0);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [currentVideoTime, setCurrentVideoTime] = useState<number>(0);
  const videoLength = carStock.videos?.length || 0;
  const playingVideo = carStock.videos?.[playVideoIndex];
  const videoRef = useRef<HTMLVideoElement>(null);
  let isClicking = false;
  let clickStartX = 0;
  // let clickEndX = 0;

  useEffect(() => {
    const bodyElement = document.querySelector('body');
    if (!bodyElement) return;

    bodyElement.style.overflow = 'hidden';

    return () => {
      bodyElement.style.overflow = 'visible';
    };
  }, []);

  useEffect(() => {
    setShowLoading(true);

    setCurrentVideoTime(0);

    const targetElement = videoRef.current;
    if (!targetElement) return;
    if (!targetElement.paused) {
      setShowLoading(false);
    }

    if (playingVideo) {
      sendTrackEvent('CV_video_start', {
        car_stock_id: carStock.id,
        car_stock_video_id: playingVideo.id
      });
    }

    targetElement.addEventListener('play', () => {
      setShowLoading(false);
      setVideoDuration(targetElement.duration);
    });
    targetElement.addEventListener('ended', () => {
      changeNextVideo();
    });
    targetElement.addEventListener('mousedown', (e: MouseEvent) => clickStart(e));
    targetElement.addEventListener('mouseup', () => clickEnd());
    // targetElement.addEventListener('touchmove', (e: TouchEvent) => {
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    //   clickEndX = e.changedTouches[0].pageX;
    // });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseElementId, playVideoIndex]);

  useEffect(() => {
    setPlayVideoIndex(0);
  }, [carStock]);

  useEffect(() => {
    if (landTransportCost) return;
    if (carStock.landTransportCost) {
      setLandTransportCost(carStock.landTransportCost);
      return;
    }
    if (!prefectureCode) return;

    const landTransportCostByPrefectureCode = carStock.landTransportCosts?.find(
      (cost) => cost.prefectureCode === prefectureCode
    )?.cost;
    if (!landTransportCostByPrefectureCode) return;

    setLandTransportCost(landTransportCostByPrefectureCode);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carStock.landTransportCost, carStock.landTransportCosts, prefectureCode]);

  const clickStart = (e: MouseEvent) => {
    isClicking = true;
    clickStartX = e.clientX;

    setTimeout(() => {
      if (!isClicking) return;
      const videoElement = videoRef.current;
      videoElement?.pause();
      isClicking = false;
    }, 500);
  };

  const clickEnd = () => {
    if (!isClicking) {
      const videoElement = videoRef.current;
      videoElement?.play()?.catch((e) => Sentry.captureException(e));
      return;
    }

    isClicking = false;
    changeVideo();

    // TODO スワイプ
    // const swipeDistance = clickStartX - clickEndX;
    // if (clickEndX === 0 || Math.abs(swipeDistance) < 50) {
    //   changeVideo();
    //   return;
    // }

    // if (swipeDistance > 0) {
    //   changeNextVideo();
    // } else {
    //   changeBackVideo();
    // }
    return;
  };

  const handleFavorite = async () => {
    const { isAuthError, isSuccess } = await favorite({
      isFavorited,
      carStockId: carStock.id,
      eventFrom: 'car-movie-popup'
    });

    if (isAuthError) {
      openfavoriteModal();
      return;
    }

    if (!isSuccess) {
      openErrorFlash();
      return;
    }
  };

  const muteVideoHandler = () => {
    setIsMutted(!isMutted);
  };

  const handleClickFooter = () => {
    sendTrackEvent('click_show_detail_on_movie_popup', { car_stock_id: carStock.id });
    navigate(`/cars/${carStock.ulid}`);
  };

  const changeBackVideo = () => {
    trackPlayVideoTime();

    if (playVideoIndex === 0) {
      if (!backMovie) {
        const currentVideo = videoRef.current;
        if (!currentVideo) return;
        currentVideo.currentTime = 0;
        currentVideo.play()?.catch((e) => Sentry.captureException(e));
        return;
      }
      backMovie();
      return;
    }

    const prevIndex = playVideoIndex - 1;
    setPlayVideoIndex(prevIndex);
  };

  const changeNextVideo = () => {
    trackPlayVideoTime();

    if (playVideoIndex === carStock.videos!.length - 1) {
      if (!nextMovie) {
        closePopup();
        return;
      }
      nextMovie();
      return;
    }

    const nextIndex = playVideoIndex + 1;
    setPlayVideoIndex(nextIndex);
  };

  const changeVideo = () => {
    const centerPositionX = window.innerWidth / 2;
    const isRightSide = clickStartX > centerPositionX;

    if (isRightSide) {
      changeNextVideo();
    } else {
      changeBackVideo();
    }
  };

  const handleTimeUpdate = () => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    setCurrentVideoTime(videoElement.currentTime);
  };

  const changePrevCarStockVideo = () => {
    trackPlayVideoTime();
    backMovie && backMovie();
  };

  const changeNextCarStockVideo = () => {
    trackPlayVideoTime();
    nextMovie && nextMovie();
  };

  const handleClose = () => {
    trackPlayVideoTime();
    closePopup();
  };

  const trackPlayVideoTime = () => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    sendTrackEvent('video_play_time', {
      car_stock_id: carStock.id,
      car_stock_video_id: playingVideo?.id,
      play_time: Math.floor(videoElement.currentTime)
    });
  };

  if (!carStock?.videos?.length) {
    return (
      <div className={styles[blockClass]}>
        <div className={styles[`${blockClass}__movie-container`]}>
          <div className={styles[`${blockClass}__video-container`]}>
            <div className={styles[`${blockClass}__close`]} onClick={closePopup} />
            {!hideNextButton && <div className={styles[`${blockClass}__next`]} onClick={nextMovie}></div>}
            {!hideBackButton && <div className={styles[`${blockClass}__back`]} onClick={backMovie}></div>}
          </div>
        </div>
      </div>);

  }

  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__movie-container`]}>
        <div className={styles[`${blockClass}__video-container`]}>
          <video
            ref={videoRef}
            muted={isMutted}
            playsInline
            autoPlay={true}
            src={playingVideo?.url || ''}
            className={styles[`${blockClass}__video`]}
            poster={playingVideo?.thumbnailUrl || '/images/pictures/video_loading.png'}
            key={playingVideo?.id}
            onTimeUpdate={handleTimeUpdate} />

          <div className={styles[`${blockClass}__bars`]}>
            {carStock.videos.map((_, index) =>
            <div
              className={styles[`${blockClass}__bar`]}
              key={index}
              style={{ width: `calc((100% - 4px * (${videoLength} - 1)) / ${videoLength})` }}>

                <div
                className={styles[`${blockClass}__bar-content`]}
                style={{
                  width:
                  index < playVideoIndex ?
                  '100%' :
                  index === playVideoIndex ?
                  `${currentVideoTime / videoDuration * 100}%` :
                  0
                }} />

                <div className={styles[`${blockClass}__bar-empty`]} />
              </div>
            )}
          </div>
          <div className={styles[`${blockClass}__close`]} onClick={handleClose}></div>
          <div
            className={[styles[`${blockClass}__mute`], isMutted ? styles['muted'] : styles['unmuted']].join(' ')}
            onClick={muteVideoHandler}>
          </div>
          <div className={styles[`${blockClass}__favorite`]} onClick={handleFavorite}>
            <img
              src={`/images/icons/${isFavorited ? 'ic_favorite_filled.svg' : 'ic_favorite_white.svg'}`}
              alt='お気に入り'
              width={32} />

          </div>
          {showLoading &&
          <div className={styles[`${blockClass}__loading`]}>
              <div className={styles[`${blockClass}__loader`]} />
            </div>
          }
          {!hideNextButton && playVideoIndex === carStock.videos!.length - 1 &&
          <div className={styles[`${blockClass}__next`]} onClick={changeNextCarStockVideo}>
              次の車
            </div>
          }
          {!hideBackButton && playVideoIndex === 0 &&
          <div className={styles[`${blockClass}__back`]} onClick={changePrevCarStockVideo}>
              前の車
            </div>
          }
          {!hideCarContent &&
          <>
              {tag && <p className={styles[`${blockClass}__tag`]}># {tag}</p>}
              <div className={styles[`${blockClass}__content`]}>
                {carStock.promotion &&
              <div className={styles[`${blockClass}__promotion`]}>
                    {carStock.promotion.postedBy &&
                <div className={styles[`${blockClass}__staff`]}>
                        <img
                    src={carStock.promotion.postedBy.imageUrl || '/images/pictures/no_image.png'}
                    alt='icon'
                    className={styles[`${blockClass}__staff-icon`]} />

                        <p className={styles[`${blockClass}__staff-name`]}>{carStock.promotion.postedBy.fullName}</p>
                      </div>
                }
                    {carStock.promotion.catchCopy &&
                <p className={styles[`${blockClass}__catch-copy`]}>{carStock.promotion.catchCopy}</p>
                }
                    {carStock.promotion.catchCopy &&
                <p className={styles[`${blockClass}__staff-comment`]}>{carStock.promotion.staffComment}</p>
                }
                  </div>
              }
                <div className={styles[`${blockClass}__features`]}>
                  <div className={styles[`${blockClass}__features-item`]}>
                    <span className={styles[`${blockClass}__features-item__icon`]}>年</span>
                    <span className={styles[`${blockClass}__features-item__text`]}>
                      {shortCarModelYear(carStock.carModelYear) || 'ー'}
                    </span>
                  </div>
                  <div className={styles[`${blockClass}__features-item`]}>
                    <span className={styles[`${blockClass}__features-item__icon`]}>走</span>
                    <span className={styles[`${blockClass}__features-item__text`]}>
                      {`${displayMileageKm(carStock.mileageKm)}km`}
                    </span>
                  </div>
                  <div className={styles[`${blockClass}__features-item`]}>
                    <span className={styles[`${blockClass}__features-item__icon`]}>車</span>
                    <span className={styles[`${blockClass}__features-item__text`]}>{carStock.carModelName}</span>
                  </div>
                  <div className={styles[`${blockClass}__features-item`]}>
                    <span className={styles[`${blockClass}__features-item__icon`]}>G</span>
                    <span className={styles[`${blockClass}__features-item__text`]}>{carStock.shortGradeName}</span>
                  </div>
                </div>
                <div className={styles[`${blockClass}__footer`]} onClick={handleClickFooter}>
                  <div className={styles[`${blockClass}__price`]}>
                    {carStock.totalPaymentAmount ?
                  <>
                        <span className={styles[`${blockClass}__price__text`]}>総額</span>
                        <span className={styles[`${blockClass}__price-number`]}>
                          {priceNumber(carStock.totalPaymentAmount, landTransportCost)}
                        </span>
                        <span className={styles[`${blockClass}__price-text`]}>
                          {priceText(carStock.totalPaymentAmount, landTransportCost)}
                        </span>
                      </> :

                  <span>価格はお問い合わせください</span>
                  }
                  </div>
                  <span className={styles[`${blockClass}__detail-link`]}>詳細を見る</span>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </div>);

};