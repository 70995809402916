import { graphql } from '../generated';

export const loginMutationDocument = graphql(`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      user {
        id
        lastName
        firstName
        zipCode
        lineAccount {
          id
          name
        }
        favoritedCarStocks {
          id
        }
        inquiries {
          id
          carStocks {
            id
          }
        }
      }
      isCreatedUser
      errors {
        path
        message
      }
    }
  }
`);
