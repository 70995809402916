import { type FunctionComponent, type ReactNode, createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useInfiniteScrollContext } from './infiniteScrollProvider';

type ContextType = {
  zipCode?: string;
  address?: string;
  prefectureCode?: number;
  setValues: ({
    zipCode,
    address,
    prefectureCode
  }: {
    zipCode?: string;
    address?: string;
    prefectureCode?: number;
  }) => void;
};

export const DeliveryAddressContext = createContext<ContextType>({
  setValues: () => {}
});
export const useDeliveryAddressContext = (): ContextType => useContext(DeliveryAddressContext);

type Props = {
  children: ReactNode;
};

export const DeliveryAddressProvider: FunctionComponent<Props> = ({ children }) => {
  const [zipCode, setZipCode] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [prefectureCode, setPrefectureCode] = useState<number>();
  const { clear } = useInfiniteScrollContext();

  useEffect(() => {
    const cookieZipCode = Cookies.get('zipCode');
    const cookieAddress = Cookies.get('address');
    const cookiePrefectureCode = Cookies.get('prefectureCode');

    if (!cookieZipCode || !cookieAddress || !cookiePrefectureCode) {
      return;
    }

    const numberCookiePrefectureCode = Number(cookiePrefectureCode);

    if (Number.isNaN(numberCookiePrefectureCode)) {
      return;
    }

    setZipCode(cookieZipCode);
    setAddress(cookieAddress);
    setPrefectureCode(numberCookiePrefectureCode);
  }, []);

  const setValues = ({
    zipCode,
    address,
    prefectureCode
  }: {
    zipCode?: string;
    address?: string;
    prefectureCode?: number;
  }) => {
    Cookies.set('zipCode', zipCode || '');
    Cookies.set('address', address || '');
    Cookies.set('prefectureCode', prefectureCode?.toString() || '');

    setZipCode(zipCode);
    setAddress(address);
    setPrefectureCode(prefectureCode);

    clear();
  };

  return (
    <DeliveryAddressContext.Provider
      value={{
        zipCode,
        address,
        prefectureCode,
        setValues
      }}
    >
      {children}
    </DeliveryAddressContext.Provider>
  );
};
