import { Link } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import styles from '~/styles/shared/members-section.module.css';

const blockClass = 'members-section';

export const MembersSection: FunctionComponent = () => {
  const members = [
  {
    name: '中野 優作',
    furigana: 'Yuusaku Nakano',
    comment: '新車を作らず、愛車を作ろう。我々BUDDICAは、どこよりも「透明性」の高いサービスをお約束します。',
    path: '/member/nakano',
    icon: '/images/members/nakano/list.jpg'
  },
  {
    name: '栗田 政浩',
    furigana: 'Masahiro Kurita',
    comment: 'お客様が最高の愛車に出会えるように全力でサポートし、楽しいクルマ選びを一緒にしていきましょう。',
    path: '/member/kurita',
    icon: '/images/members/kurita/list.jpg'
  },
  {
    name: '由佐 朋也',
    furigana: 'Tomoya Yusa',
    comment: 'BUDDICAの創業メンバー。お客様の未来を想像し全国のお客様に「愛車」を届けます。',
    path: '/member/yusa',
    icon: '/images/members/yusa/list.jpg'
  },
  {
    name: '長谷川 奨',
    furigana: 'Sho Hasegawa',
    comment: 'お客様の理想をとことんお伺いします！！その上で最高の一台を共に拘り抜いて創造していきましょう！！',
    path: '/member/hasegawa',
    icon: '/images/members/hasegawa/list.jpg'
  },
  {
    name: '緑川 凱久',
    furigana: 'Yoshihisa Midorikawa',
    comment: 'あなたと一生のお付き合いする覚悟で誰よりも寄り添って一緒に考えます。楽しいクルマ探しの旅をしましょう！',
    path: '/member/midorikawa',
    icon: '/images/members/midorikawa/list.jpg'
  }];


  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__header`]}>
        <p className={styles[`${blockClass}__header-title`]}>バディカダイレクトのスタッフ</p>
        <Link to='/members' className={styles[`${blockClass}__header-link`]}>
          もっと見る
        </Link>
      </div>
      <div className={styles[`${blockClass}__members`]}>
        {members.map((member, index) =>
        <div className={styles[`${blockClass}__members-item`]} key={index}>
            <div className={styles[`${blockClass}__members-item__header`]}>
              <img src={member.icon} alt='reviewer_icon' className={styles[`${blockClass}__members-item__icon`]} />
              <div>
                <p className={styles[`${blockClass}__members-item__name`]}>{member.name}</p>
                <p className={styles[`${blockClass}__members-item__furigana`]}>{member.furigana}</p>
              </div>
            </div>
            <p className={styles[`${blockClass}__members-item__comment`]}>{member.comment}</p>
            <div className={styles[`${blockClass}__members-item__link`]}>
              <Link to={member.path}>詳細を見る</Link>
            </div>
          </div>
        )}
      </div>
    </div>);

};