import { useState, type FunctionComponent } from 'react';
import { useAnalytics, useS3DirectUpload } from '~/hooks';
import styles from '~/styles/shared/inspectionCertificateScanModal/capture-confirm.module.css';
import { ImagePreviewFromFile } from '../ImagePreviewFromFile';

const blockClass = 'capture-confirm';

type Props = {
  imageFile: File;
  backToCapture: () => void;
  onUploadedImage: ({ contentToken, imageFile }: {contentToken: string;imageFile: File;}) => void;
  onError: () => void;
};

export const CaptureConfirm: FunctionComponent<Props> = (props) => {
  const { imageFile, backToCapture, onUploadedImage, onError } = props;
  const { directUpload } = useS3DirectUpload();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { sendTrackEvent } = useAnalytics();

  const uploadInspectionImage = async () => {
    sendTrackEvent('click_selling_form_camera_register_button');

    try {
      setIsSubmitting(true);
      const { contentToken } = await directUpload(imageFile);
      if (!contentToken) {
        throw new Error('Failed to upload image');
      }
      onUploadedImage({ contentToken, imageFile });
    } catch (e) {
      setIsSubmitting(false);
      onError();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__image`]}>
        <ImagePreviewFromFile imageFile={imageFile} />
      </div>
      <div className={styles[`${blockClass}__notice`]}>
        <p className={styles[`${blockClass}__notice-text`]}>
          車検証全体が収まっていて、文字がはっきり読み取れますか？
          <br />
          問題なければ写真を登録してください
        </p>
      </div>
      <button className={styles[`${blockClass}__button`]} onClick={uploadInspectionImage} disabled={isSubmitting}>
        登録する
      </button>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <span className={styles[`${blockClass}__link`]} onClick={backToCapture}>
          撮影しなおす
        </span>
      </div>
    </div>);

};