import type { FunctionComponent, ReactNode } from 'react';
import styles from '~/styles/layout/main.module.css';
// import { Footer } from './Footer';
// import { ErrorHeader } from './ErrorHeader';

type Props = {
  children: ReactNode;
};

export const ErrorLayout: FunctionComponent<Props> = ({ children }) => {
  return (
    <>
      {/* <ErrorHeader /> */}
      <main className={styles.main}>{children}</main>
      {/* <Footer /> */}
    </>);

};