import { CarStockSearchAmountTypeEnum, CarStockSearchBodyTypeEnum } from '~/gql/generated/graphql';

type BodyTypeTextType = {
  [k in CarStockSearchBodyTypeEnum]: string;
};

export const bodyTypeText: BodyTypeTextType = {
  [CarStockSearchBodyTypeEnum.Keicar]: '軽自動車',
  [CarStockSearchBodyTypeEnum.Compact]: 'コンパクト',
  [CarStockSearchBodyTypeEnum.Suv]: 'SUV',
  [CarStockSearchBodyTypeEnum.Minivan]: 'ミニバン',
  [CarStockSearchBodyTypeEnum.Other]: 'その他'
};

type AmountTypeTextType = {
  [k in CarStockSearchAmountTypeEnum]: string;
};

export const amountTypeText: AmountTypeTextType = {
  [CarStockSearchAmountTypeEnum.Low]: '~100万円',
  [CarStockSearchAmountTypeEnum.Mid]: '100~200万円',
  [CarStockSearchAmountTypeEnum.High]: '200万円〜'
};
