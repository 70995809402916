import { useDisclosure } from '@mantine/hooks';
import { type FunctionComponent, type ReactNode, createContext, useContext, useState } from 'react';

type ContextType = {
  opened: boolean;
  message: ReactNode;
  open: (message: ReactNode) => void;
  close: () => void;
};

export const FlashContext = createContext<ContextType>({
  opened: false,
  message: <></>,
  open: () => {},
  close: () => {}
});
export const useFlashContext = (): ContextType => useContext(FlashContext);

type Props = {
  children: ReactNode;
};

export const FlashProvider: FunctionComponent<Props> = ({ children }) => {
  const [opened, { open: openFlash, close }] = useDisclosure(false);
  const [message, setMessage] = useState<ReactNode>('');

  const open = (flashMessage: ReactNode) => {
    setMessage(flashMessage);
    openFlash();
  };

  return (
    <FlashContext.Provider
      value={{
        opened,
        message,
        open,
        close
      }}
    >
      {children}
    </FlashContext.Provider>
  );
};
