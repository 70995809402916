import { useDisclosure } from '@mantine/hooks';
import { type FunctionComponent, type ReactNode, createContext, useContext } from 'react';

type ContextType = {
  opened: boolean;
  open: () => void;
  close: () => void;
};

export const LineFavoriteModalContext = createContext<ContextType>({
  opened: false,
  open: () => {},
  close: () => {}
});
export const useLineFavoriteModalContext = (): ContextType => useContext(LineFavoriteModalContext);

type Props = {
  children: ReactNode;
};

export const LineFavoriteModalProvider: FunctionComponent<Props> = ({ children }) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <LineFavoriteModalContext.Provider
      value={{
        opened,
        open,
        close
      }}
    >
      {children}
    </LineFavoriteModalContext.Provider>
  );
};
