import liff from '@line/liff';
import { type ReactNode, type FunctionComponent, createContext, useContext, useState, useEffect } from 'react';
import { useMutation } from 'urql';
import { type UserQuery } from '~/gql/generated/graphql';
import { loginMutationDocument, updateLineAccountNameMutation } from '~/gql/mutations';
import { useLiffContext } from './liffProvider';
import { useTrackingManager } from '~/hooks/useTrackingManager';
import { z } from 'zod';
import { safeParser } from '~/utils/parser';

type ContextType = {
  fetching: boolean;
  isPause: boolean;
  isExecuteLogin: boolean;
  isExecuteUpdateLineUserName: boolean;
  login: () => void;
  updateLineUserName: (user: UserQuery['viewer']) => void;
  setIsPauseFetchUser: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AuthContext = createContext<ContextType>({
  fetching: false,
  isPause: false,
  isExecuteLogin: false,
  isExecuteUpdateLineUserName: false,
  login: () => {},
  updateLineUserName: () => {},
  setIsPauseFetchUser: () => {}
});
export const useAuthContext = (): ContextType => useContext(AuthContext);

type Props = {
  children: ReactNode;
};

const trackingSchema = z.unknown().pipe(z.record(z.unknown()));

export const AuthProvider: FunctionComponent<Props> = ({ children }) => {
  const loginMutate = useMutation(loginMutationDocument)[1];
  const updateLineUserNameMutate = useMutation(updateLineAccountNameMutation)[1];
  const [fetching, setFetching] = useState(true);
  const [user, setUser] = useState<UserQuery['viewer']>();
  const [isExecuteLogin, setIsExecuteLogin] = useState(false);
  const [isPause, setIsPause] = useState(false);
  const [isExecuteUpdateLineUserName, setIsExecuteUpdateLineUserName] = useState(false);
  const { isMiniApp } = useLiffContext();
  const { setTracking } = useTrackingManager();

  useEffect(() => {
    const tracking = safeParser(trackingSchema, user?.tracking || {});
    if (!tracking) return;
    setTracking(tracking);
  }, [user, setTracking]);

  useEffect(() => {
    if (!isExecuteLogin) {
      return;
    }

    liff.ready.then(async () => {
      if (!liff.isLoggedIn()) {
        setIsPause(true);
        setFetching(false);
        return;
      }

      const idToken = liff.getIDToken();
      if (!idToken) {
        setIsPause(true);
        setFetching(false);
        return;
      }

      try {
        const loginResult = await loginMutate({ input: { idToken: idToken, fromMiniapp: isMiniApp } });
        const loginUser = loginResult.data?.login?.user;
        if (!loginUser) {
          setIsPause(true);
          setFetching(false);
          return;
        }

        setFetching(false);
      } catch (e) {
        setIsPause(true);
        setFetching(false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExecuteLogin]);

  const login = () => {
    if (isExecuteLogin) return;
    setIsExecuteLogin(true);
  };

  useEffect(() => {
    if (!isExecuteUpdateLineUserName) {
      return;
    }

    if (!user) {
      return;
    }

    liff.ready.then(async () => {
      if (!liff.isLoggedIn()) {
        return;
      }

      const idToken = liff.getIDToken();
      if (!idToken) {
        return;
      }

      try {
        const lineProfile = await liff.getProfile();
        const lineUserName = lineProfile.displayName;

        if (lineUserName === user.lineAccount?.name) {
          return;
        }

        updateLineUserNameMutate({ input: { idToken: idToken, fromMiniapp: isMiniApp } });
      } catch (e) {}
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExecuteUpdateLineUserName]);

  const updateLineUserName = (user: UserQuery['viewer']) => {
    if (isExecuteUpdateLineUserName) return;
    setUser(user);
    setIsExecuteUpdateLineUserName(true);
  };

  return (
    <AuthContext.Provider
      value={{
        fetching: fetching,
        isPause,
        isExecuteLogin,
        isExecuteUpdateLineUserName,
        login,
        updateLineUserName,
        setIsPauseFetchUser: setIsPause
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
