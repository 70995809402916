import { useDisclosure } from '@mantine/hooks';
import { createContext, useContext, useState, type FunctionComponent, type ReactNode } from 'react';

type ContextType = {
  opened: boolean;
  errorMessage?: string;
  open: (message?: string) => void;
  close: () => void;
};

export const ErrorFlashContext = createContext<ContextType>({
  opened: false,
  open: () => {},
  close: () => {}
});
export const useErrorFlashContext = (): ContextType => useContext(ErrorFlashContext);

type Props = {
  children: ReactNode;
};

export const ErrorFlashProvider: FunctionComponent<Props> = ({ children }) => {
  const [opened, { open: openFlash, close }] = useDisclosure(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const open = (message?: string) => {
    setErrorMessage(message);
    openFlash();
  };

  return (
    <ErrorFlashContext.Provider
      value={{
        opened,
        errorMessage,
        open,
        close
      }}
    >
      {children}
    </ErrorFlashContext.Provider>
  );
};
