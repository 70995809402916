import { useSearchParams } from '@remix-run/react';
import { useEffect, useState, type FunctionComponent } from 'react';
import { amountTypeText, bodyTypeText } from '~/constants/searchTypeText';
import { CarStockSearchAmountTypeEnum, CarStockSearchBodyTypeEnum } from '~/gql/generated/graphql';
import { useCarsSearchParams } from '~/hooks';
import styles from '~/styles/shared/car-search-modal.module.css';
import { Modal } from '..';

const blockClass = 'car-search-modal';

type BodyType = {
  key: CarStockSearchBodyTypeEnum;
  icon: string;
};

type AmountType = {
  key: CarStockSearchAmountTypeEnum;
  icon: string;
};

type Props = {
  opened: boolean;
  close: () => void;
};

export const CarSearchModal: FunctionComponent<Props> = (props) => {
  const { opened, close } = props;
  const { changeBodyType, changeAmountType, changeNameKeyword } = useCarsSearchParams();
  const [searchParams] = useSearchParams();
  const defaultNameKeyword = searchParams.get('nameKeyword') || '';
  const [nameKeyword, setNameKeyword] = useState(defaultNameKeyword);
  const types: BodyType[] = [
  { key: CarStockSearchBodyTypeEnum.Keicar, icon: '/images/icons/ic_car_kei.svg' },
  { key: CarStockSearchBodyTypeEnum.Compact, icon: '/images/icons/ic_car_compact.svg' },
  { key: CarStockSearchBodyTypeEnum.Suv, icon: '/images/icons/ic_car_suv.svg' },
  { key: CarStockSearchBodyTypeEnum.Minivan, icon: '/images/icons/ic_car_minivan.svg' },
  { key: CarStockSearchBodyTypeEnum.Other, icon: '/images/icons/ic_car_other.svg' }];

  const amounts: AmountType[] = [
  { key: CarStockSearchAmountTypeEnum.Low, icon: '/images/icons/ic_coin_few.svg' },
  { key: CarStockSearchAmountTypeEnum.Mid, icon: '/images/icons/ic_coin_normal.svg' },
  { key: CarStockSearchAmountTypeEnum.High, icon: '/images/icons/ic_coin_many.svg' }];


  useEffect(() => {
    if (opened) {
      setNameKeyword(defaultNameKeyword);
    }
  }, [defaultNameKeyword, opened]);

  const selectBodyType = (type: CarStockSearchBodyTypeEnum) => {
    changeBodyType(type);
    close();
  };

  const selectPrice = (type: CarStockSearchAmountTypeEnum) => {
    changeAmountType(type);
    close();
  };

  const searchCarModelName = () => {
    changeNameKeyword(nameKeyword);
    close();
  };

  return (
    <Modal
      isOpen={opened}
      className={styles[blockClass]}
      onRequestClose={close}
      style={{
        overlay: {
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          zIndex: 201
        }
      }}>

      {opened &&
      <>
          <div className={styles[`${blockClass}__close`]} onClick={close}>
            <img src={'/images/icons/ic_close.svg'} alt='close' />
          </div>
          <div className={styles[`${blockClass}__group`]}>
            <p className={styles[`${blockClass}__group-title`]}>車名から見つける</p>
            <div className={styles[`${blockClass}__group-row`]}>
              <input
              type='text'
              placeholder='例:アルファード,N-BOX,フィット'
              value={nameKeyword}
              onChange={(e) => setNameKeyword(e.target.value)}
              className={styles[`${blockClass}__group-input`]} />

              <button className={styles[`${blockClass}__group-button`]} onClick={searchCarModelName}>
                検索
              </button>
            </div>
          </div>
          <div className={styles[`${blockClass}__group`]}>
            <p className={styles[`${blockClass}__group-title`]}>タイプから見つける</p>
            <div className={styles[`${blockClass}__group-tiles`]}>
              {types.map((type, index) =>
            <div
              className={styles[`${blockClass}__group-tile`]}
              key={index}
              onClick={() => selectBodyType(type.key)}>

                  <div>
                    <img src={type.icon} alt='car' className={styles[`${blockClass}__group-tile__image`]} />
                    <p className={styles[`${blockClass}__group-tile__text`]}>{bodyTypeText[type.key]}</p>
                  </div>
                </div>
            )}
            </div>
          </div>
          <div className={styles[`${blockClass}__group`]}>
            <p className={styles[`${blockClass}__group-title`]}>価格から見つける</p>
            <div className={styles[`${blockClass}__group-tiles`]}>
              {amounts.map((amount, index) =>
            <div
              className={styles[`${blockClass}__group-price-tile`]}
              key={index}
              onClick={() => selectPrice(amount.key)}>

                  <div>
                    <img src={amount.icon} alt='car' className={styles[`${blockClass}__group-price-tile__image`]} />
                    <p className={styles[`${blockClass}__group-price-tile__text`]}>{amountTypeText[amount.key]}</p>
                  </div>
                </div>
            )}
            </div>
          </div>
        </>
      }
    </Modal>);

};