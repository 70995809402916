import { useEffect, type FunctionComponent, useState } from 'react';
import styles from '~/styles/shared/carDeliveryAddress/car-delivery-address-modal.module.css';
import { useForm } from 'react-hook-form';
import { useClient } from 'urql';
import { convertLatlngQueryDocument, convertZipCodeQueryDocument } from '~/gql/queries';
import { Modal } from '..';
import { useDeliveryAddressContext } from '~/providers';

const blockClass = 'car-delivery-address-modal';

type Inputs = {
  zipCode: string;
};

type Props = {
  opened: boolean;
  onComplete: (zipCode?: string, address?: string, prefectureCode?: number) => void;
  close: () => void;
};

export const CarDeliveryAddressModal: FunctionComponent<Props> = (props) => {
  const client = useClient();
  const { opened, close, onComplete } = props;
  const [hasError, setHasError] = useState(false);
  const [hasLocationError, setHasLocationError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid, isSubmitted }
  } = useForm<Inputs>();
  const { zipCode: defaultZipCode } = useDeliveryAddressContext();

  useEffect(() => {
    setValue('zipCode', defaultZipCode || '');
  }, [setValue, defaultZipCode]);

  const submit = async (inputData: Inputs) => {
    setHasError(false);

    if (!inputData.zipCode) {
      onComplete();
      close();
      return;
    }

    try {
      setSubmitting(true);

      const result = await client.query(convertZipCodeQueryDocument, { zipCode: inputData.zipCode });
      const errors = result.data?.convertZipCode?.errors || [];
      if (errors.length > 0) {
        setHasError(true);
        return;
      }

      const resultZipCode = result.data?.convertZipCode?.zipCode || undefined;
      const resultAddress = result.data?.convertZipCode?.address || undefined;
      const resultPrefectureCode = result.data?.convertZipCode?.prefectureCode || undefined;
      onComplete(resultZipCode, resultAddress, resultPrefectureCode);

      close();
    } catch (e) {
      setHasError(true);
    } finally {
      setSubmitting(false);
    }
  };

  const getLocation = () => {
    setHasLocationError(false);

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        const result = await client.query(convertLatlngQueryDocument, { latitude: latitude, longitude: longitude });
        const errors = result.data?.convertLatlng?.errors || [];
        if (errors.length > 0) {
          setHasLocationError(true);
          return;
        }

        const resultZipCode = result.data?.convertLatlng?.zipCode || undefined;
        const resultAddress = result.data?.convertLatlng?.address || undefined;
        const resultPrefectureCode = result.data?.convertLatlng?.prefectureCode || undefined;
        onComplete(resultZipCode, resultAddress, resultPrefectureCode);

        close();
      },
      () => {
        setHasLocationError(true);
      }
    );
  };

  return (
    <Modal
      isOpen={opened}
      className={styles[blockClass]}
      onRequestClose={close}
      style={{
        overlay: {
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 201
        }
      }}>

      {opened &&
      <>
          <div className={styles[`${blockClass}__header`]}>
            <p className={styles[`${blockClass}__header-title`]}>お届け先の配送料見積もり</p>
            <div className={styles[`${blockClass}__header-close`]} onClick={close}>
              <img src={'/images/icons/ic_close.svg'} alt='close' />
            </div>
          </div>
          <div className={styles[`${blockClass}__description`]}>
            <p>郵便番号を入力してください。</p>
            <p>
              車両に<span>配送料込みの金額</span>を表示します。
            </p>
          </div>
          <form onSubmit={handleSubmit(submit)}>
            <input {...register('zipCode')} className={styles[`${blockClass}__input`]} placeholder='例:2700233' />
            {hasError && <p className={styles[`${blockClass}__error`]}>郵便番号を正しく入力してください</p>}
            <div className={styles[`${blockClass}__location`]} onClick={getLocation}>
              <img src={'/images/icons/ic_mark.svg'} alt='mark' />
              <p>現在地を取得</p>
            </div>
            {hasLocationError && <p className={styles[`${blockClass}__error`]}>現在地の取得に失敗しました</p>}
            <button
            type='submit'
            className={styles[`${blockClass}__button`]}
            disabled={isSubmitted && !isValid || submitting}>

              保存
            </button>
          </form>
        </>
      }
    </Modal>);

};