import { useMutation } from 'urql';
import { useAuth } from './useAuth';
import { createCarStockFavoriteMutationDocument, destroyCarStockFavoriteMutationDocument } from '~/gql/mutations';
import { errorHandle } from '~/utils/graphql/errorHandle';
import { type CreateCarStockFavoriteMutation, type DestroyCarStockFavoriteMutation } from '~/gql/generated/graphql';
import { useAnalytics } from './useAnalytics';

type FavoriteResponse = {
  isAuthError: boolean;
  isSuccess: boolean;
  mutateType: 'create' | 'destroy';
};

type FavoriteHook = {
  favorite: ({
    isFavorited,
    carStockId,
    eventFrom
  }: {
    isFavorited: boolean;
    carStockId: string;
    eventFrom: string;
  }) => Promise<FavoriteResponse>;
};

export const useFavorite = (): FavoriteHook => {
  const { isAuthenticated } = useAuth();
  const createCarStockFavorite = useMutation(createCarStockFavoriteMutationDocument)[1];
  const destroyCarStockFavorite = useMutation(destroyCarStockFavoriteMutationDocument)[1];
  const { sendTrackEvent } = useAnalytics();

  const favorite = async ({
    isFavorited,
    carStockId,
    eventFrom
  }: {
    isFavorited: boolean;
    carStockId: string;
    eventFrom: string;
  }): Promise<FavoriteResponse> => {
    const mutateType = isFavorited ? 'destroy' : 'create';

    if (!isAuthenticated) {
      return { isAuthError: true, isSuccess: false, mutateType };
    }

    sendTrackEvent('favorite', { car_stock_id: carStockId, event_from: eventFrom });

    if (mutateType === 'destroy') {
      try {
        const { data, error } = await destroyCarStockFavorite({ input: { carStockId: carStockId } });
        const { hasError } = errorHandle<DestroyCarStockFavoriteMutation['destroyCarStockFavorite']>(
          data?.destroyCarStockFavorite,
          error
        );
        return { isAuthError: false, isSuccess: !hasError, mutateType };
      } catch (e) {
        return { isAuthError: false, isSuccess: false, mutateType };
      }
    } else {
      try {
        const { data, error } = await createCarStockFavorite({ input: { carStockId: carStockId } });
        const { hasError } = errorHandle<CreateCarStockFavoriteMutation['createCarStockFavorite']>(
          data?.createCarStockFavorite,
          error
        );
        return { isAuthError: false, isSuccess: !hasError, mutateType };
      } catch (e) {
        return { isAuthError: false, isSuccess: false, mutateType };
      }
    }
  };

  return { favorite };
};
