import type { FunctionComponent, ReactNode } from 'react';
import { Header } from './Header';
import styles from '~/styles/layout/main.module.css';
import { Footer } from './Footer';

type Props = {
  children: ReactNode;
};

export const Layout: FunctionComponent<Props> = ({ children }) => {
  return (
    <>
      <Header />
      <main className={styles.main}>{children}</main>
      <Footer />
    </>);

};