import { useDisclosure } from '@mantine/hooks';
import { type FunctionComponent, type ReactNode, createContext, useContext, useState } from 'react';

type ContextType = {
  opened: boolean;
  message: string;
  open: (message: string) => void;
  close: () => void;
};

export const ToastContext = createContext<ContextType>({
  opened: false,
  message: '',
  open: () => {},
  close: () => {}
});
export const useToastContext = (): ContextType => useContext(ToastContext);

type Props = {
  children: ReactNode;
};

export const ToastProvider: FunctionComponent<Props> = ({ children }) => {
  const [opened, { open: openToast, close }] = useDisclosure(false);
  const [message, setMessage] = useState('');

  const open = (message: string) => {
    setMessage(message);
    openToast();

    setTimeout(() => {
      close();
    }, 4000);
  };

  return (
    <ToastContext.Provider
      value={{
        opened,
        message,
        open,
        close
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};
