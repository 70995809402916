import { Link } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import styles from '~/styles/shared/banners/consultation-banner.module.css';

export const ConsultationBanner: FunctionComponent = () => {
  return (
    <div className={styles['consultation-banner']}>
      <Link to='/add_line_friend?inflow_source_message=notifynew'>
        <img src={'/images/banners/consultation.png'} alt='買取査定はこちら' width={'100%'} />
      </Link>
    </div>);

};