import { graphql } from '../generated';

export const createCarStockFavoriteMutationDocument = graphql(`
  mutation createCarStockFavorite($input: CreateCarStockFavoriteInput!) {
    createCarStockFavorite(input: $input) {
      carStockFavorite {
        id
        carStock {
          id
          ...SmallCarCard_carStock
        }
      }
      errors {
        message
        path
      }
    }
  }
`);
