import { useDisclosure } from '@mantine/hooks';
import { type FunctionComponent } from 'react';
import styles from '~/styles/shared/carDeliveryAddress/car-delivery-address.module.css';
import { CarDeliveryAddressModal } from './CarDeliveryAddressModal';
import { useDeliveryAddressContext } from '~/providers';

const blockClass = 'car-delivery-address';

type Props = {
  onComplete: (zipCode?: string, address?: string, prefectureCode?: number) => void;
};

export const CarDeliveryAddress: FunctionComponent<Props> = ({ onComplete }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const { address } = useDeliveryAddressContext();

  return (
    <>
      <div className={styles[blockClass]} onClick={open}>
        <img src={'/images/icons/ic_truck.svg'} alt='truck' />
        <p className={styles[`${blockClass}__text`]}>{address ? `お届け先  ${address}` : 'お届け先の配送料見積もり'}</p>
      </div>
      <CarDeliveryAddressModal opened={opened} close={close} onComplete={onComplete} />
    </>);

};