import { graphql } from '../generated';

export const updateLineAccountNameMutation = graphql(`
  mutation updateLineAccountName($input: UpdateLineAccountNameInput!) {
    updateLineAccountName(input: $input) {
      lineAccount {
        id
        name
      }
      errors {
        message
        path
      }
    }
  }
`);
