import { TrackableLink } from '~/components/parts';
import styles from '~/styles/shared/car-request.module.css';

const blockClass = 'car-request';

export const CarRequest = () => {
  const linkEventName = 'open_request_form';
  const formLink = '/add_line_friend?inflow_source_message=notifynew';

  return (
    <div>
      <div className={styles[`${blockClass}__relieve`]}>
        <h2 className={styles[`${blockClass}__relieve-title`]}>お気軽にクルマをリクエストください！</h2>
        <ul className={styles[`${blockClass}__relieve-list`]}>
          <li className={styles[`${blockClass}__relieve-item`]}>
            掲載のない車両でも全国のネットワークを活用して見つけることが可能
          </li>
          <li className={styles[`${blockClass}__relieve-item`]}>追加費用なしでリクエスト可能</li>
        </ul>
        <p className={styles[`${blockClass}__relieve-text`]}>
          クルマ選びのプロたちが
          <br />
          あなたの愛車を探します！
        </p>
        <div className={styles[`${blockClass}__relieve-img`]}>
          <img src={'/images/other/img_request.png'} alt='' />
        </div>
      </div>
      <p className={styles[`${blockClass}__button`]}>
        <TrackableLink
          to={formLink}
          analyticsData={{ eventName: linkEventName }}
          className={styles[`${blockClass}__step-button`]}>

          クルマのリクエストはこちら
        </TrackableLink>
      </p>
    </div>);

};