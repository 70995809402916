import { useEffect, useRef, useState } from 'react';

const CAPTURED_IMAGE_MIME_TYPE = 'image/jpeg';

export const useCameraCapture = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const [isCameraBlocked, setIsCameraBlocked] = useState(false);
  const [isCapturing, setIsCapturing] = useState(false);

  useEffect(() => {
    return () => {
      stopCamera();
    };
  }, []);

  const startCamera = async () => {
    try {
      const media = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          width: { ideal: 1920 },
          height: { ideal: 1080 },
          facingMode: 'environment',
          aspectRatio: { ideal: 16 / 9 }
        }
      });

      if (!videoRef.current) {
        throw new Error('videoRef is not initialized');
      }

      videoRef.current.srcObject = media;
      streamRef.current = media;
    } catch (e) {
      console.error(e);
      setIsCameraBlocked(true);
    }
  };

  const stopCamera = () => {
    if (!streamRef.current) return;

    streamRef.current.getTracks().forEach((track) => track.stop());
    streamRef.current = null;
  };

  const capture = async (input?: { shouldRotate: boolean }) => {
    const { shouldRotate } = input || {};

    return new Promise<File>((resolve, reject) => {
      setIsCapturing(true);

      try {
        if (!videoRef.current) {
          throw new Error('videoRef is not initialized');
        }

        const canvas = document.createElement('canvas');

        if (shouldRotate) {
          canvas.width = videoRef.current.videoHeight;
          canvas.height = videoRef.current.videoWidth;
        } else {
          canvas.width = videoRef.current.videoWidth;
          canvas.height = videoRef.current.videoHeight;
        }

        const context = canvas.getContext('2d');
        if (!context) {
          throw new Error('Failed to get 2d context');
        }

        // 現在の描画状態を保存
        context.save();

        if (shouldRotate) {
          // キャンバスの中心に移動
          context.translate(canvas.width / 2, canvas.height / 2);
          // -90度回転
          context.rotate(-Math.PI / 2);
          // 描画位置を元に戻す
          context.translate(-canvas.height / 2, -canvas.width / 2);
        }

        // 画像を描画
        context.drawImage(videoRef.current, 0, 0);
        // 保存した描画状態を復元
        context.restore();

        canvas.toBlob(
          (blob) => {
            if (!blob) {
              throw new Error('Failed to create blob');
            }
            const timestamp = new Date().toISOString().replace(/[^\w]/g, '-');
            const fileName = `${timestamp}.jpg`;
            const file = new File([blob], fileName, { type: CAPTURED_IMAGE_MIME_TYPE });
            resolve(file);
          },
          CAPTURED_IMAGE_MIME_TYPE,
          1.0
        );
      } catch (e) {
        reject(e);
      } finally {
        setIsCapturing(false);
      }
    });
  };

  return {
    videoRef,
    startCamera,
    stopCamera,
    capture,
    isCapturing,
    isCameraBlocked
  };
};
