import { type FunctionComponent, type ReactNode, createContext, useContext, useState } from 'react';

type InfiniteScroll = Record<string, { cursors: string[]; elementId?: string }>;

type ContextType = {
  infiniteScroll: InfiniteScroll;
  addScrolledCursor: (key: string, cursor: string) => void;
  storeElementId: (key: string, elementId: string) => void;
  clear: () => void;
};

export const InfiniteScrollContext = createContext<ContextType>({
  infiniteScroll: {},
  addScrolledCursor: () => {},
  storeElementId: () => {},
  clear: () => {}
});
export const useInfiniteScrollContext = (): ContextType => useContext(InfiniteScrollContext);

type Props = {
  children: ReactNode;
};

export const InfiniteScrollProvider: FunctionComponent<Props> = ({ children }) => {
  const [infiniteScroll, setInfiniteScroll] = useState<InfiniteScroll>({});

  const addScrolledCursor = (key: string, cursor: string) => {
    const targetCursors = infiniteScroll[key]?.cursors || [];
    setInfiniteScroll({
      ...infiniteScroll,
      [key]: {
        cursors: [...new Set([...targetCursors, cursor])]
      }
    });
  };

  const storeElementId = (key: string, elementId: string) => {
    const targetCursors = infiniteScroll[key]?.cursors || [];
    if (targetCursors.length === 0) return;

    setInfiniteScroll({
      ...infiniteScroll,
      [key]: {
        ...infiniteScroll[key],
        elementId
      }
    });
  };

  const clear = () => {
    setInfiniteScroll({});
  };

  return (
    <InfiniteScrollContext.Provider
      value={{
        infiniteScroll,
        addScrolledCursor,
        storeElementId,
        clear
      }}
    >
      {children}
    </InfiniteScrollContext.Provider>
  );
};
