import { useNavigate, useSearchParams } from '@remix-run/react';
import { CarStockSortTypeEnum, type CarSalesStatusEnum } from '~/gql/generated/graphql';
import { useDeliveryAddressContext } from '~/providers';

type CarsSearchParamsHook = {
  changeStatus: (status: CarSalesStatusEnum) => void;
  changeZipCodeAndAddress: (zipCode?: string, address?: string, prefectureCode?: number) => void;
  changeBodyType: (bodyType: string) => void;
  changeAmountType: (amountType: string) => void;
  changeSortType: (sortType: CarStockSortTypeEnum) => void;
  changeNameKeyword: (nameKeyword: string) => void;
  clearScope: () => void;
};

export const useCarsSearchParams = (): CarsSearchParamsHook => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setValues } = useDeliveryAddressContext();

  const changeStatus = (status: CarSalesStatusEnum) => {
    searchParams.set('status', status);
    navigate(`/search?${searchParams.toString()}`);
  };

  const changeZipCodeAndAddress = (zipCode?: string, address?: string, prefectureCode?: number) => {
    setValues({ zipCode, address, prefectureCode });
    prefectureCode
      ? searchParams.set('prefectureCode', prefectureCode.toString())
      : searchParams.delete('prefectureCode');
    navigate(`/search?${searchParams.toString()}`);
  };

  const changeBodyType = (bodyType: string) => {
    searchParams.delete('amountType');
    searchParams.delete('nameKeyword');
    searchParams.set('bodyType', bodyType);
    navigate(`/search?${searchParams.toString()}`);
  };

  const changeAmountType = (amountType: string) => {
    searchParams.delete('bodyType');
    searchParams.delete('nameKeyword');
    searchParams.set('amountType', amountType);
    searchParams.set('sortType', CarStockSortTypeEnum.AmountLower);
    navigate(`/search?${searchParams.toString()}`);
  };

  const changeSortType = (sortType: CarStockSortTypeEnum) => {
    searchParams.set('sortType', sortType);
    navigate(`/search?${searchParams.toString()}`);
  };

  const changeNameKeyword = (nameKeyword: string) => {
    searchParams.delete('bodyType');
    searchParams.delete('amountType');
    if (nameKeyword) {
      searchParams.set('nameKeyword', nameKeyword);
    } else {
      searchParams.delete('nameKeyword');
    }
    navigate(`/search?${searchParams.toString()}`);
  };

  const clearScope = () => {
    searchParams.delete('bodyType');
    searchParams.delete('amountType');
    searchParams.delete('nameKeyword');
    navigate(`/search?${searchParams.toString()}`);
  };

  return {
    changeStatus,
    changeZipCodeAndAddress,
    changeBodyType,
    changeAmountType,
    changeSortType,
    changeNameKeyword,
    clearScope
  };
};
