import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';

type Props = {
  imageFile: File;
};
export const ImagePreviewFromFile: FunctionComponent<Props> = ({ imageFile }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const url = URL.createObjectURL(imageFile);
    setImageUrl(url);

    return () => {
      URL.revokeObjectURL(url);
    };
  }, [imageFile]);

  if (!imageUrl) {
    return null;
  }

  return <img src={imageUrl} alt='Preview' />;
};
