import { Link } from '@remix-run/react';
import type { FC } from 'react';
import styles from '~/styles/shared/review-section.module.css';

const blockClass = 'review-section';

type Props = {
  subColor?: boolean;
};
export const ReviewSection: FC<Props> = ({ subColor }) => {
  const reviews = [
  {
    name: '福井 K.H',
    car: 'フリード',
    icon: '/images/reviews/img_1226_kh.jpg',
    text: '昨今の中古車業界の状況から、信頼できるところから買いたかったのがはじめにありました。バディカさんで購入して本当によかったです。担当の遊佐さんは終始親切で、私の要望に合った車を一生懸命探していただきました。次に買うときもバディカさんで買いたいです。'
  },
  {
    name: '石川 Y.S',
    car: 'ランドクルーザープラド',
    icon: '/images/reviews/img_1019_ys.jpg',
    text: '先日、無事に車が届きました！購入を決めてからちょうど1ヶ月ですね！とてもスムーズで、本当に車が家届いちゃって、最高です子供も大喜び！ありがとうございました！またよろしくお願いいたします！'
  },
  {
    name: '福島 K.Z',
    car: 'ヴェルファイア',
    icon: '/images/reviews/img_1016_kz.jpg',
    text: '土曜日に無事に納車されました！車の状態も大変満足しています、ヘッドライトも磨いて頂きありがとうございました！妻と子供も喜んでいて、これから家族で出掛けるのが楽しみです。今回は中古車を購入するにあたりバディカ様を選んで良かったです。'
  }];


  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__header`]}>
        <p className={styles[`${blockClass}__header-title`]}>バディカダイレクト 利用者の声</p>
        <Link to='/reviews/' className={styles[`${blockClass}__header-link`]}>
          <span className={subColor ? 'font-bold text-color-text-sub' : ''}>もっと見る</span>
        </Link>
      </div>
      <div className={styles[`${blockClass}__reviews`]}>
        {reviews.map((review, index) =>
        <div className={styles[`${blockClass}__reviews-item`]} key={index}>
            <div className={styles[`${blockClass}__reviews-item__header`]}>
              <img src={review.icon} alt='reviewer_icon' className={styles[`${blockClass}__reviews-item__icon`]} />
              <div>
                <p className={styles[`${blockClass}__reviews-item__name`]}>{review.name}様</p>
                <p className={styles[`${blockClass}__reviews-item__car`]}>{review.car}</p>
              </div>
            </div>
            <div className={styles[`${blockClass}__reviews-item__text`]}>
              {review.text.split(/\n/).map((line, index) =>
            <p key={index}>{line}</p>
            )}
            </div>
          </div>
        )}
      </div>
    </div>);

};