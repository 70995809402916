import { graphql } from '../generated';

export const convertZipCodeQueryDocument = graphql(`
  query convertZipCode($zipCode: String!) {
    convertZipCode(zipCode: $zipCode) {
      zipCode
      address
      prefectureCode
      prefectureName
      cityName
      errors {
        message
      }
    }
  }
`);
