import { type FunctionComponent } from 'react';
import { useToastContext } from '~/providers/toastProvider';
import { Modal } from '../Modal/Modal';
import styles from '~/styles/shared/toast.module.css';

const blockClass = 'toast';

export const Toast: FunctionComponent = () => {
  const { opened, message, close } = useToastContext();

  return (
    <Modal
      isOpen={opened}
      className={styles[blockClass]}
      onRequestClose={close}
      style={{
        overlay: {
          backgroundColor: 'rgba(26, 30, 37, 0.9)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 201
        }
      }}>

      {opened && <p>{message}</p>}
    </Modal>);

};