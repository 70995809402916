import { graphql } from '../generated';

export const userQueryDocument = graphql(`
  query user {
    viewer {
      id
      lastName
      firstName
      zipCode
      tracking
      lineAccount {
        id
        name
      }
      favoritedCarStocks {
        id
      }
      inquiries {
        id
        carStocks {
          id
        }
      }
      activeOneClickContracts {
        id
        carStock {
          id
        }
      }
    }
  }
`);
