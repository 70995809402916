import { type FunctionComponent } from 'react';
import { LargeCarCard, SmallCarCard } from '~/components/shared';
import { type FragmentType, graphql, useFragment } from '~/gql/generated';
import styles from '~/styles/shared/car-card-group.module.css';

const blockClass = 'car-card-group';

const CarCardGroupCarStocksFragment = graphql(`
  fragment CarCardGroup_carStock on CarStock {
    id
    ...LargeCarCard_carStock
    ...SmallCarCard_carStock
    ...CarIndex_CarMovies_carStock
  }
`);

type Props = {
  carStocks: readonly FragmentType<typeof CarCardGroupCarStocksFragment>[];
  favoritedCarStockIds: string[];
};

export const CarCardGroup: FunctionComponent<Props> = (props) => {
  const { favoritedCarStockIds } = props;
  const carStocks = useFragment(CarCardGroupCarStocksFragment, props.carStocks);
  const firstLargeCarStock = carStocks[0];
  const displaySmallCarStocks = carStocks.length % 2 === 0 ? carStocks.slice(1, -1) : carStocks.slice(1);
  const lastLargeCarStock = carStocks.length > 1 && carStocks.length % 2 === 0 ? carStocks[carStocks.length - 1] : null;

  return (
    <div>
      {firstLargeCarStock &&
      <div className={styles[`${blockClass}__large-car`]}>
          <LargeCarCard
          carStock={firstLargeCarStock}
          isFavorited={favoritedCarStockIds.includes(firstLargeCarStock.id)}
          isStoreClickId={true} />

        </div>
      }
      {displaySmallCarStocks.slice(0, 2).length > 0 &&
      <div className={styles[`${blockClass}__cars`]}>
          {displaySmallCarStocks.slice(0, 2).map((carStock, index) =>
        <div className={styles[`${blockClass}__cars-item`]} key={index}>
              <SmallCarCard
            carStock={carStock}
            isFavorited={favoritedCarStockIds.includes(carStock.id)}
            isStoreClickId={true} />

            </div>
        )}
        </div>
      }
      {displaySmallCarStocks.slice(2, 4).length > 0 &&
      <div className={styles[`${blockClass}__cars`]}>
          {displaySmallCarStocks.slice(2, 4).map((carStock, index) =>
        <div className={styles[`${blockClass}__cars-item`]} key={index}>
              <SmallCarCard
            carStock={carStock}
            isFavorited={favoritedCarStockIds.includes(carStock.id)}
            isStoreClickId={true} />

            </div>
        )}
        </div>
      }
      {lastLargeCarStock &&
      <div className={styles[`${blockClass}__large-car`]}>
          <LargeCarCard
          carStock={lastLargeCarStock}
          isFavorited={favoritedCarStockIds.includes(lastLargeCarStock.id)}
          isStoreClickId={true} />

        </div>
      }
    </div>);

};