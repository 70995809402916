import { type ReactNode, type FunctionComponent, createContext, useState, useContext } from 'react';

type ContextType = {
  inquireSellingPopupOpened: boolean;
  setInquireSellingPopupOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PopupContext = createContext<ContextType>({
  inquireSellingPopupOpened: true,
  setInquireSellingPopupOpened: () => {}
});
export const usePopupContext = (): ContextType => useContext(PopupContext);

type Props = {
  children: ReactNode;
};

export const PopupProvider: FunctionComponent<Props> = ({ children }) => {
  const [inquireSellingPopupOpened, setInquireSellingPopupOpened] = useState(true);

  return (
    <PopupContext.Provider
      value={{
        inquireSellingPopupOpened,
        setInquireSellingPopupOpened
      }}
    >
      {children}
    </PopupContext.Provider>
  );
};
