import { type FunctionComponent } from 'react';
import { TrackableLink } from '~/components/parts';
import { usePopupContext } from '~/providers';
import styles from '~/styles/shared/banners/inquire-selling-popup-banner.module.css';

export const InquireSellingPopupBanner: FunctionComponent = () => {
  const { inquireSellingPopupOpened, setInquireSellingPopupOpened } = usePopupContext();

  if (!inquireSellingPopupOpened) {
    return null;
  }

  return (
    <div className={styles.banner}>
      <div className={styles.wrapper}>
        <TrackableLink to='/selling?top-banner' analyticsData={{ eventName: 'click_inquire_selling_popup' }}>
          <img src={'/images/banners/inquire_selling_popup.png'} alt='無料：愛車査定はこちら' width={132} />
        </TrackableLink>
        <div className={styles.close} onClick={() => setInquireSellingPopupOpened(false)}>
          <div className={styles['close-icon']} />
        </div>
      </div>
    </div>);

};