import { type FunctionComponent } from 'react';
import styles from '~/styles/shared/progress-bar.module.css';

const blockClass = 'progress-bar';

export const ProgressBar: FunctionComponent = () => {
  return (
    <div className={styles[blockClass]}>
      <span className={styles[`${blockClass}__bar`]} />
    </div>);

};