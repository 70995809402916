import { Link, useLocation } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import { useAuth } from '~/hooks';

import styles from '~/styles/shared/bottom-nav.module.css';

const blockClass = 'bottom-nav';

export const BottomNav: FunctionComponent = () => {
  const { user } = useAuth();
  const favoritedCarStocks = user?.favoritedCarStocks || [];
  const location = useLocation();
  const pathname = location.pathname;
  const navs = [
  {
    image: '/images/icons/ic_nav_home.svg',
    currentimage: '/images/icons/ic_nav_home_current.svg',
    link: '/',
    title: 'ホーム',
    alt: 'ホーム',
    current: ''
  },
  {
    image: '/images/icons/ic_nav_search.svg',
    currentimage: '/images/icons/ic_nav_search_current.svg',
    link: '/search',
    title: '見つける',
    alt: '見つける',
    current: 'search'
  },
  {
    image: '/images/icons/ic_nav_favorite.svg',
    currentimage: '/images/icons/ic_nav_favorite_current.svg',
    link: '/favorites',
    title: 'お気に入り',
    alt: 'お気に入り',
    current: 'favorites'
  }];


  const isCurrent = (path: string) => {
    if (pathname === '/' && path === '') {
      return true;
    } else {
      if (pathname.indexOf(path) !== -1 && path !== '') {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__list`]} data-pathname={pathname}>
        {navs.map((nav, index) =>
        <div
          className={[
          styles[`${blockClass}__item`],
          nav.current === 'favorites' && favoritedCarStocks.length > 0 ? styles['with-badge'] : ''].
          join(' ')}
          key={index}>

            <Link to={nav.link} className={styles[`${blockClass}__item-link`]}>
              {isCurrent(nav.current) &&
            <>
                  <img src={nav.currentimage} alt={nav.alt} className={styles[`${blockClass}__item-image_current`]} />
                  <p
                className={[styles[`${blockClass}__item-text`], styles[`${blockClass}__item-text_current`]].join(
                  ' '
                )}>

                    {nav.title}
                  </p>
                </>
            }
              {!isCurrent(nav.current) &&
            <>
                  <img src={nav.image} alt={nav.alt} className={styles[`${blockClass}__item-image`]} />
                  <p className={styles[`${blockClass}__item-text`]}>{nav.title}</p>
                </>
            }
            </Link>
          </div>
        )}
      </div>
    </div>);

};