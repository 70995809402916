import { useLocation, useNavigate } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import { LoginLink } from '~/components/parts';
import { useAuth } from '~/hooks';
import { useInfiniteScrollContext } from '~/providers';
import styles from '~/styles/shared/banners/request-banner.module.css';

export const NewNotificationBanner: FunctionComponent = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { storeElementId } = useInfiniteScrollContext();
  const currentPath = `${location.pathname}${location.search}`;
  const elementId = 'request-banner';

  const handleClickBanner = () => {
    storeElementId(location.key, elementId);
    navigate(`/search-condition?redirectPath=${currentPath}`);
  };

  return (
    <div className={styles['request-banner']} id={elementId}>
      {isAuthenticated ?
      <div onClick={handleClickBanner}>
          <img src={'/images/banners/new_notification.png'} alt='request' width={'100%'} />
        </div> :

      <LoginLink redirectPath={`/search-condition?redirectPath=${currentPath}`}>
          <img src={'/images/banners/new_notification.png'} alt='request' width={'100%'} />
        </LoginLink>
      }
    </div>);

};