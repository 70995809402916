import { type CombinedError } from 'urql';
import * as Sentry from '@sentry/remix';
import { z } from 'zod';
import { safeParser } from '../parser';

type DataErrors =
  | {
      errors?: Array<{ __typename?: 'ClientError'; message: string; path?: Array<string> | null }>;
    }
  | undefined
  | null;

type ErrorHandleResponse = {
  hasError: boolean;
  message?: string;
};

export const errorHandle = <T extends DataErrors>(data?: T, error?: CombinedError): ErrorHandleResponse => {
  if (data?.errors?.length) {
    console.error(data.errors);
    Sentry.captureException(new Error(data.errors.map((error) => error.message).join('\n')));
    return { hasError: true, message: data.errors[0].message };
  }

  if (error?.graphQLErrors?.length) {
    console.error(error.graphQLErrors);
    Sentry.captureException(new Error(error.graphQLErrors.map((error) => error.message).join('\n')));
    return { hasError: true, message: error.graphQLErrors[0].message };
  }

  if (error?.networkError) {
    console.error(error.networkError);
    Sentry.captureException(error.networkError);
    return { hasError: true, message: error.networkError.message };
  }

  return { hasError: false };
};

type Success = {
  success: true;
};
type Failure = {
  success: false;
  error: CombinedError;
};
type HandleCombinedErrorProps = {
  error: CombinedError | undefined;
};
export const handleCombinedError = ({ error }: HandleCombinedErrorProps): Success | Failure => {
  if (!error) {
    return { success: true };
  }

  console.error(error);
  Sentry.captureException(error);

  return {
    success: false,
    error: error
  };
};

type GetFormattedCombinedProps = {
  error: CombinedError | undefined;
};
export const getFormattedCombinedError = ({ error }: GetFormattedCombinedProps) => {
  if (!error) {
    return null;
  }

  const graphQLErrors = error?.graphQLErrors;

  if (!graphQLErrors) {
    return null;
  }

  return graphQLErrors.map((graphQLError) => {
    const message = graphQLError.message;
    const problems = graphQLError.extensions?.problems;
    if (!Array.isArray(problems)) {
      return { message };
    }

    const formattedProblems = problems.map((problem) => {
      const paths = safeParser(z.array(z.string()).nullable(), problem.path);
      const explanation = safeParser(z.string(), problem.explanation);

      return {
        paths,
        explanation
      };
    });

    return { message, problems: formattedProblems };
  });
};

type GetCombinedErrorPathsProps = {
  error: CombinedError | undefined;
};
export const getCombinedErrorPaths = ({ error }: GetCombinedErrorPathsProps) => {
  const formattedCombinedError = getFormattedCombinedError({ error });
  const problems = formattedCombinedError?.map((error) => error.problems).flat();
  return problems
    ?.map((problem) => problem?.paths)
    .flat()
    .filter((path) => path);
};
