import { useEffect, type FunctionComponent } from 'react';
import styles from '~/styles/shared/flash.module.css';
import { useTimeout } from '@mantine/hooks';
import { useFlashContext } from '~/providers/flashProvider';

export const Flash: FunctionComponent = () => {
  const { opened, close } = useFlashContext();
  const { start: startCloseTimer } = useTimeout(() => close(), 3000);
  const { message } = useFlashContext();

  useEffect(() => {
    if (!opened) return;

    startCloseTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  if (!opened) return <></>;

  return (
    <div className={styles.flash}>
      <div className={styles.body}>
        <img src={'/images/icons/ic_info.svg'} className={styles.image} alt='error' />
        <div className={styles.text}>{message}</div>
      </div>
    </div>);

};