import { type FunctionComponent } from 'react';
import styles from '~/styles/shared/banners/request-banner.module.css';

export const RequestBanner: FunctionComponent = () => {
  return (
    <div className={styles['request-banner']}>
      <a href='/request/'>
        <img src={'/images/banners/request.png'} alt='request' width={'100%'} />
      </a>
    </div>);

};