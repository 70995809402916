/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  query AishameterCushionOwnedCarsQuery {\n    ownedCars {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n    }\n  }\n": types.AishameterCushionOwnedCarsQueryDocument,
    "\n  mutation CompleteAutoCarEstimateValuation($input: CompleteAutoCarEstimateValuationInput!) {\n    completeAutoCarEstimateValuation(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n": types.CompleteAutoCarEstimateValuationDocument,
    "\n  mutation RequestDocument($input: RequestDocumentInput!) {\n    requestDocument(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.RequestDocumentDocument,
    "\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        zipCode\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UpdateUserDocument,
    "\n  query FavoritesIndexComponent($prefectureCode: Int) {\n    viewer {\n      id\n      favoritedCarStocks {\n        id\n        ulid\n        landTransportCost(prefectureCode: $prefectureCode)\n        ...SmallCarCard_carStock\n      }\n    }\n  }\n": types.FavoritesIndexComponentDocument,
    "\n  mutation CreateInflowSource($input: CreateInflowSourceInput!) {\n    createInflowSource(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CreateInflowSourceDocument,
    "\n  mutation InquireBuyingNewInquireBuying($input: InquireBuyingInput!) {\n    inquireBuying(input: $input) {\n      inquiry {\n        id\n        user {\n          id\n          lastName\n          zipCode\n          firstName\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.InquireBuyingNewInquireBuyingDocument,
    "\n  mutation createOneClickDetail($input: CreateOneClickDetailInput!) {\n    createOneClickDetail(input: $input) {\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CreateOneClickDetailDocument,
    "\n  query convertZipCode($zipCode: String!) {\n    convertZipCode(zipCode: $zipCode) {\n      zipCode\n      address\n      prefectureCode\n      prefectureName\n      cityName\n      errors {\n        message\n      }\n    }\n  }\n": types.ConvertZipCodeDocument,
    "\n  query userActiveOneClickContractsDetail {\n    viewer {\n      id\n      activeOneClickContracts {\n        id\n        carStock {\n          id\n        }\n        detail {\n          id\n          lastName\n          firstName\n          zipCode\n          address\n          paymentMethod\n          requestType\n          requestComment\n        }\n      }\n    }\n  }\n": types.UserActiveOneClickContractsDetailDocument,
    "\n  mutation oneClickContract($input: CreateOneClickContractInput!) {\n    createOneClickContract(input: $input) {\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.OneClickContractDocument,
    "\n  query SearchConditionShow {\n    viewer {\n      id\n      lineAccount {\n        id\n        carStockSearchCondition {\n          id\n          minAmount\n          maxAmount\n          maxMileageKm\n          minCarModeYearBeforeYear\n          bodyTypeGroups {\n            id\n            bodyTypeGroup\n          }\n          searchConditionCarNames {\n            id\n            carModelName\n            makeName\n          }\n        }\n      }\n    }\n  }\n": types.SearchConditionShowDocument,
    "\n  query SearchConditionShow_CarStockNames($carModelNameKeyword: String, $makeNameKeyword: String) {\n    carStockNames(carModelNameKeyword: $carModelNameKeyword, makeNameKeyword: $makeNameKeyword) {\n      carModelNames\n      makeNames\n    }\n  }\n": types.SearchConditionShow_CarStockNamesDocument,
    "\n  mutation UpdateCarStockSearchCondition($input: UpdateCarStockSearchConditionInput!) {\n    updateCarStockSearchCondition(input: $input) {\n      carStockSearchCondition {\n        id\n        minAmount\n        maxAmount\n        maxMileageKm\n        minCarModeYearBeforeYear\n        bodyTypeGroups {\n          id\n          bodyTypeGroup\n        }\n        searchConditionCarNames {\n          id\n          carModelName\n          makeName\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UpdateCarStockSearchConditionDocument,
    "\n  mutation DestroyCarStockSearchCondition($input: DestroyCarStockSearchConditionInput!) {\n    destroyCarStockSearchCondition(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.DestroyCarStockSearchConditionDocument,
    "\n  fragment SpecialShow_carStock on CarStock {\n    id\n    ...CarCardGroup_carStock\n  }\n": types.SpecialShow_CarStockFragmentDoc,
    "\n  query SpecialShowComponent(\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $tagGroup: TagGroupEnum\n    $prefectureCode: Int\n  ) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, tagGroup: $tagGroup) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...SpecialShow_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.SpecialShowComponentDocument,
    "\n  mutation createUserReviewBuyingAfterSale($input: CreateUserReviewBuyingAfterSaleInput!) {\n    createUserReviewBuyingAfterSale(input: $input) {\n      success\n    }\n  }\n": types.CreateUserReviewBuyingAfterSaleDocument,
    "\n  mutation createUserReviewBuyingContract($input: CreateUserReviewBuyingContractInput!) {\n    createUserReviewBuyingContract(input: $input) {\n      success\n    }\n  }\n": types.CreateUserReviewBuyingContractDocument,
    "\n  mutation createUserReview($input: CreateUserReviewInput!) {\n    createUserReview(input: $input) {\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CreateUserReviewDocument,
    "\n  fragment CarIndex_CarMovies_carStock on CarStock {\n    id\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n    ...CarMoviePopup_carStock\n  }\n": types.CarIndex_CarMovies_CarStockFragmentDoc,
    "\n  query ClosedCarStocksIndexComponent($first: Int, $salesStatus: CarSalesStatusEnum, $prefectureCode: Int) {\n    carStocks(first: $first, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarsIndex_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.ClosedCarStocksIndexComponentDocument,
    "\n  query HasVideoCarStocksIndexComponent($first: Int, $salesStatus: CarSalesStatusEnum, $hasVideo: Boolean) {\n    carStocks(first: $first, salesStatus: $salesStatus, hasVideo: $hasVideo) {\n      edges {\n        node {\n          id\n          ...CarsIndex_carStock\n        }\n      }\n    }\n  }\n": types.HasVideoCarStocksIndexComponentDocument,
    "\n  fragment CarsIndex_carStock on CarStock {\n    id\n    ...CarCardGroup_carStock\n    ...CarIndex_CarMovies_carStock\n    ...CarsSection_carStock\n  }\n": types.CarsIndex_CarStockFragmentDoc,
    "\n  fragment CarsSection_carStock on CarStock {\n    id\n    ...SmallCarCard_carStock\n  }\n": types.CarsSection_CarStockFragmentDoc,
    "\n  fragment CarCard_carStock on CarStock {\n    id\n    ulid\n    mileageKm\n    carModelName\n    shortGradeName\n    salesStatus\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n  }\n": types.CarCard_CarStockFragmentDoc,
    "\n  fragment CarMovies_carStock on CarStock {\n    id\n    ...CarCard_carStock\n    ...CarMoviePopup_carStock\n  }\n": types.CarMovies_CarStockFragmentDoc,
    "\n  query CarsMoviesComponent(\n    $first: Int\n    $after: String\n    $hasVideo: Boolean\n    $salesStatus: CarSalesStatusEnum\n    $prefectureCode: Int\n  ) {\n    carStocks(first: $first, after: $after, hasVideo: $hasVideo, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarMovies_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.CarsMoviesComponentDocument,
    "\n  fragment CarsSearch_carStock on CarStock {\n    id\n    ...OneColumnCarCard_carStock\n    ...TwoColumnCarCard_carStock\n  }\n": types.CarsSearch_CarStockFragmentDoc,
    "\n  fragment OneColumnCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    makeName\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    imageUrls\n    oneClickContractCounts\n    tags {\n      id\n      name\n    }\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n  }\n": types.OneColumnCarCard_CarStockFragmentDoc,
    "\n  fragment TwoColumnCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    makeName\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    imageUrls\n    oneClickContractCounts\n    tags {\n      id\n      name\n    }\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n  }\n": types.TwoColumnCarCard_CarStockFragmentDoc,
    "\n  fragment CarEquipment_carStock on CarStock {\n    id\n    mainCarStockEquipments {\n      key\n      value\n      text\n    }\n    subCarStockEquipments {\n      active\n      text\n      group\n    }\n  }\n": types.CarEquipment_CarStockFragmentDoc,
    "\n  fragment CarFeature_carStock on CarStock {\n    id\n    carModelYear\n    mileageKm\n    inspectionExpiryDate\n    carStockDetail {\n      id\n      repairHistoryCategory\n    }\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      description\n    }\n    dealerBranch {\n      id\n      prefectureName\n    }\n  }\n": types.CarFeature_CarStockFragmentDoc,
    "\n  fragment CarFooter_carStock on CarStock {\n    id\n    ulid\n    makeName\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    salesPrice\n    otherPrice\n    salesStatus\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n  }\n": types.CarFooter_CarStockFragmentDoc,
    "\n  fragment CarImages_carStock on CarStock {\n    id\n    images {\n      id\n      url\n      comment\n    }\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n    ...CarMoviePopup_carStock\n  }\n": types.CarImages_CarStockFragmentDoc,
    "\n  fragment CarScore_carStock on CarStock {\n    id\n    vihicleConditionReportUrl\n    carStockDetail {\n      evaluationScore\n      interiorEvaluationScore\n    }\n    promotion {\n      id\n      staffComment\n      scores {\n        axis\n        score\n      }\n    }\n  }\n": types.CarScore_CarStockFragmentDoc,
    "\n  fragment CarSpec_carStock on CarStock {\n    id\n    slideDoorSpecText\n    carStockDetail {\n      seatingCapacity\n      displaySystematicColor\n      transmissionTypeGroup\n      engineDisplacement\n      fuelTypeText\n    }\n  }\n": types.CarSpec_CarStockFragmentDoc,
    "\n  fragment CarStaff_carStock on CarStock {\n    id\n    promotion {\n      id\n      postedBy {\n        id\n        lastName\n        imageUrl\n        comment\n        slug\n      }\n    }\n  }\n": types.CarStaff_CarStockFragmentDoc,
    "\n  query CarsListComponent(\n    $prefectureCode: Int\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $bodyType: CarStockSearchBodyTypeEnum\n  ) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, bodyType: $bodyType) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarCardGroup_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.CarsListComponentDocument,
    "\n  fragment CarsShow_carStock on CarStock {\n    id\n    ulid\n    salesStatus\n    makeName\n    carModelName\n    carModelYear\n    searchBodyType\n    oneClickContractCounts\n    ...CarFeature_carStock\n    ...CarFooter_carStock\n    ...CarImages_carStock\n    ...CarScore_carStock\n    ...CarEquipment_carStock\n    ...CarStaff_carStock\n    ...CarSpec_carStock\n  }\n": types.CarsShow_CarStockFragmentDoc,
    "\n  mutation ViewCarStock($input: ViewCarStockInput!) {\n    viewCarStock(input: $input) {\n      errors {\n        message\n      }\n    }\n  }\n": types.ViewCarStockDocument,
    "\n  mutation InquirePurchaseNewInquireSelling($input: InquireSellingInput!) {\n    inquireSelling(input: $input) {\n      inquiry {\n        id\n        user {\n          id\n          firstName\n          lastName\n          zipCode\n        }\n      }\n      inspectionCertificateImageUrl\n      carEstimateValuationPath\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.InquirePurchaseNewInquireSellingDocument,
    "\n  query InquiresNewComponent($ulids: [String!], $prefectureCode: Int) {\n    carStocks(ulids: $ulids) {\n      nodes {\n        id\n        landTransportCost(prefectureCode: $prefectureCode)\n        ...SmallCarCard_carStock\n      }\n    }\n  }\n": types.InquiresNewComponentDocument,
    "\n  mutation InquiresNewInquireCarStock($input: InquireCarStocksInput!) {\n    inquireCarStocks(input: $input) {\n      inquiry {\n        id\n        user {\n          id\n          firstName\n          lastName\n          zipCode\n          inquiries {\n            id\n            carStocks {\n              id\n            }\n          }\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.InquiresNewInquireCarStockDocument,
    "\n  query ContentLinkShow($key: String!) {\n    contentLink(key: $key) {\n      id\n      contentable {\n        urlToRedirect\n        id\n      }\n    }\n  }\n": types.ContentLinkShowDocument,
    "\n  query MypageCallbackPage {\n    viewer {\n      id\n      lastName\n      firstName\n      publicDeals {\n        id\n      }\n    }\n  }\n": types.MypageCallbackPageDocument,
    "\n  mutation UpdateUserName_UserEdit($input: UpdateUserNameInput!) {\n    updateUserName(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UpdateUserName_UserEditDocument,
    "\n  fragment DealStatus_deal on Deal {\n    id\n    dealTasks {\n      id\n      taskType\n      completed\n    }\n    mypageDealTasks {\n      type\n      group\n      status\n    }\n    ...TaskList_deal\n  }\n": types.DealStatus_DealFragmentDoc,
    "\n  query MypageDealShowPage($dealId: ID!) {\n    deal(id: $dealId) {\n      id\n      user {\n        id\n        fullName\n      }\n      salesStaff {\n        id\n        fullName\n        imageUrl\n      }\n      ...NotificationCard_deal\n      ...OrderCar_deal\n      ...DealStatus_deal\n      ...InspectionCertificate_deal\n      ...PaymentCard_deal\n    }\n  }\n": types.MypageDealShowPageDocument,
    "\n  fragment InspectionCertificate_deal on Deal {\n    id\n    dealAttachments {\n      id\n      attachmentType\n      url\n    }\n  }\n": types.InspectionCertificate_DealFragmentDoc,
    "\n  fragment NotificationCard_deal on Deal {\n    id\n    mypageDealNotice\n  }\n": types.NotificationCard_DealFragmentDoc,
    "\n  fragment OrderCar_deal on Deal {\n    id\n    carStock {\n      id\n      carModelYear\n      mileageKm\n      carModelName\n      shortGradeName\n      makeName\n      images {\n        id\n        url\n      }\n    }\n    dealDetail {\n      id\n      deliveryDate\n      deliveryTimeRangeText\n    }\n  }\n": types.OrderCar_DealFragmentDoc,
    "\n  fragment PaymentCard_deal on Deal {\n    id\n    payment {\n      id\n      deadline\n      paymentMethod\n      amount\n      status\n    }\n    salesStaff {\n      id\n      dealerBranch {\n        id\n        bank {\n          bankName\n          accountType\n          accountNumber\n          accountName\n          branchCode\n          branchName\n        }\n      }\n    }\n  }\n": types.PaymentCard_DealFragmentDoc,
    "\n  fragment TaskList_deal on Deal {\n    id\n    dealContractDeadline\n    dealContractSentEmail\n    dealTasks {\n      id\n      taskType\n      completed\n    }\n    dealDetail {\n      id\n      expectedCarRegistrationCompletionDate\n      deliveryDate\n      deliveryTimeRangeText\n    }\n    mypageDealTasks {\n      type\n      group\n      status\n      title\n    }\n    dealContract {\n      id\n      userAuthenticationMethod\n      deadline\n      email\n      smsTel\n    }\n  }\n": types.TaskList_DealFragmentDoc,
    "\n  fragment OwnedCarEdit_ownedCar on OwnedCar {\n    id\n    color\n    hasOdor\n    isDirty\n    hasAccidentHistory\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionType\n    }\n  }\n": types.OwnedCarEdit_OwnedCarFragmentDoc,
    "\n  query OwnedCarEditQuery($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarEdit_ownedCar\n    }\n  }\n": types.OwnedCarEditQueryDocument,
    "\n  query OwnedCarEdit_OwnedCarOptionOptions {\n    ownedCarOptionOptions {\n      optionType\n      optionTypeText\n    }\n  }\n": types.OwnedCarEdit_OwnedCarOptionOptionsDocument,
    "\n  mutation OwnedCarEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...OwnedCarEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.OwnedCarEdit_UpdateOwnedCarDocument,
    "\n  fragment OwnedCarEditBase_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    carOrigin\n    driveSystem\n    latestMileageKm\n  }\n": types.OwnedCarEditBase_OwnedCarFragmentDoc,
    "\n  query OwnedCarEditBaseQuery($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarEditBase_ownedCar\n    }\n  }\n": types.OwnedCarEditBaseQueryDocument,
    "\n  mutation OwnedCarEditBase_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...OwnedCarEditBase_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.OwnedCarEditBase_UpdateOwnedCarDocument,
    "\n  fragment OwnedCarShow_CarBaseTable_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    editDisabled\n    carOriginText\n    driveSystemText\n    isProhibitUpdateFromUser\n    latestMileageKm\n  }\n": types.OwnedCarShow_CarBaseTable_OwnedCarFragmentDoc,
    "\n  fragment OwnedCarShow_CarCard_ownedCar on OwnedCar {\n    id\n    color\n    hasOdor\n    isDirty\n    hasAccidentHistory\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    editDisabled\n    isProhibitUpdateFromUser\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n": types.OwnedCarShow_CarCard_OwnedCarFragmentDoc,
    "\n  fragment OwnedCarShow_CarImagesCard_ownedCar on OwnedCar {\n    id\n    editDisabled\n    isProhibitUpdateFromUser\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n": types.OwnedCarShow_CarImagesCard_OwnedCarFragmentDoc,
    "\n  query OwnedCarShow_CarImagesCard_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n    }\n  }\n": types.OwnedCarShow_CarImagesCard_OwnedCarImageTypesDocument,
    "\n  query OwnedCarShow_CarImagesCard_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n": types.OwnedCarShow_CarImagesCard_OwnedCarDocument,
    "\n  mutation DeleteOwnedCar($input: DeleteOwnedCarInput!) {\n    deleteOwnedCar(input: $input) {\n      isSuccess\n    }\n  }\n": types.DeleteOwnedCarDocument,
    "\n  fragment OwnedCarShow_CurrentCarValuationButton_ownedCar on OwnedCar {\n    id\n    carEstimateValuations {\n      id\n      ulid\n      status\n      createdAt\n    }\n    carOfficialValuations {\n      id\n      ulid\n      status\n      createdAt\n    }\n  }\n": types.OwnedCarShow_CurrentCarValuationButton_OwnedCarFragmentDoc,
    "\n  query OwnedCarShow($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarShow_CarBaseTable_ownedCar\n      ...CarDetailTable_ownedCar\n      ...OwnedCarShow_CarCard_ownedCar\n      ...OwnedCarShow_CarImagesCard_ownedCar\n      ...OwnedCarShow_CurrentCarValuationButton_ownedCar\n    }\n  }\n": types.OwnedCarShowDocument,
    "\n  query Viewer {\n    viewer {\n      id\n      fullName\n    }\n  }\n": types.ViewerDocument,
    "\n  query OwnedCars {\n    ownedCars {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      images {\n        id\n        url\n        imageType\n      }\n      carEstimateValuations {\n        id\n        __typename\n        ulid\n        status\n        createdAt\n        expiredPriceValidDeadline\n      }\n      carOfficialValuations {\n        id\n        __typename\n        ulid\n        status\n        createdAt\n        expiredPriceValidDeadline\n        isAccepted\n        offers {\n          id\n          isAccepted\n        }\n      }\n    }\n  }\n": types.OwnedCarsDocument,
    "\n  fragment CarEstimateValuationEdit_ownedCar on OwnedCar {\n    id\n    carModelName\n    gradeName\n    driveSystem\n    firstRegistrationYear\n    latestMileageKm\n    hasAccidentHistory\n    accidentComment\n    color\n    isDirty\n    hasOdor\n    comment\n    carOrigin\n    inspectionCertificateImage {\n      id\n      url\n    }\n  }\n": types.CarEstimateValuationEdit_OwnedCarFragmentDoc,
    "\n  fragment CarEstimateValuationEdit_carEstimateValuation on CarEstimateValuation {\n    id\n    detail {\n      id\n      reason\n      reasonComment\n      preferredPrice\n    }\n  }\n": types.CarEstimateValuationEdit_CarEstimateValuationFragmentDoc,
    "\n  query CarEstimateValuationEdit_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...CarEstimateValuationEdit_ownedCar\n    }\n  }\n": types.CarEstimateValuationEdit_OwnedCarDocument,
    "\n  query CarEstimateValuationEdit_CarEstimateValuation($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarEstimateValuationEdit_carEstimateValuation\n    }\n  }\n": types.CarEstimateValuationEdit_CarEstimateValuationDocument,
    "\n  mutation CarEstimateValuationEdit_UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n        zipCode\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarEstimateValuationEdit_UpdateUserDocument,
    "\n  mutation CarEstimateValuationEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarEstimateValuationEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarEstimateValuationEdit_UpdateOwnedCarDocument,
    "\n  mutation CarEstimateValuationEdit_UpdateCarEstimateValuation($input: UpdateCarEstimateValuationInput!) {\n    updateCarEstimateValuation(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ...CarEstimateValuationEdit_carEstimateValuation\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarEstimateValuationEdit_UpdateCarEstimateValuationDocument,
    "\n  fragment CarEstimateValuationNew_ownedCar on OwnedCar {\n    id\n    carModelName\n    gradeName\n    driveSystem\n    firstRegistrationYear\n    latestMileageKm\n    hasAccidentHistory\n    accidentComment\n    color\n    isDirty\n    hasOdor\n    comment\n    carOrigin\n    inspectionCertificateImage {\n      id\n      url\n    }\n  }\n": types.CarEstimateValuationNew_OwnedCarFragmentDoc,
    "\n  fragment CarEstimateValuationNew_carEstimateValuation on CarEstimateValuation {\n    id\n    detail {\n      id\n      reason\n      reasonComment\n      preferredPrice\n    }\n  }\n": types.CarEstimateValuationNew_CarEstimateValuationFragmentDoc,
    "\n  query CarEstimateValuationNew_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...CarEstimateValuationNew_ownedCar\n    }\n  }\n": types.CarEstimateValuationNew_OwnedCarDocument,
    "\n  query CarEstimateValuationNew_CarEstimateValuation($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarEstimateValuationNew_carEstimateValuation\n    }\n  }\n": types.CarEstimateValuationNew_CarEstimateValuationDocument,
    "\n  mutation CarEstimateValuationNew_UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n        zipCode\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarEstimateValuationNew_UpdateUserDocument,
    "\n  mutation CarEstimateValuationNew_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarEstimateValuationNew_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarEstimateValuationNew_UpdateOwnedCarDocument,
    "\n  mutation CarEstimateValuationNew_CreateCarEstimateValuation($input: CreateCarEstimateValuationInput!) {\n    createCarEstimateValuation(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ...CarEstimateValuationNew_carEstimateValuation\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarEstimateValuationNew_CreateCarEstimateValuationDocument,
    "\n  fragment CarEstimateValuationShow_AutoCompleteView_AishameterButton_carLog on CarLog {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    minMileageKm\n    maxMileageKm\n    ownedCarId\n  }\n": types.CarEstimateValuationShow_AutoCompleteView_AishameterButton_CarLogFragmentDoc,
    "\n  fragment CarEstimateValuationShow_AutoCompleteView_carLog on CarLog {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    minMileageKm\n    maxMileageKm\n  }\n": types.CarEstimateValuationShow_AutoCompleteView_CarLogFragmentDoc,
    "\n  fragment CarEstimateValuationShow_AutoCompleteView_OwnedCarEditModal_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCar {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      editDisabled\n    }\n    carLog {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n    }\n  }\n": types.CarEstimateValuationShow_AutoCompleteView_OwnedCarEditModal_CarEstimateValuationFragmentDoc,
    "\n  fragment CarEstimateValuationShow_AutoCompleteView_carEstimateValuation on CarEstimateValuation {\n    id\n    minPrice\n    maxPrice\n    ownedCarId\n    ...CardHeader_carEstimateValuation\n    ...StatusView_carEstimateValuation\n    ...CarEstimateValuationShow_AutoCompleteView_OwnedCarEditModal_carEstimateValuation\n    carLog {\n      id\n      ...CarEstimateValuationShow_AutoCompleteView_carLog\n      ...CarEstimateValuationShow_AutoCompleteView_AishameterButton_carLog\n    }\n  }\n": types.CarEstimateValuationShow_AutoCompleteView_CarEstimateValuationFragmentDoc,
    "\n  mutation AutoCompleteView_OwnedCarEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.AutoCompleteView_OwnedCarEdit_UpdateOwnedCarDocument,
    "\n  fragment CarDetailTable_carEstimateValuation on CarEstimateValuation {\n    id\n    ulid\n    status\n    detail {\n      id\n      ...ValuationArea_valuationDetail\n    }\n    ownedCar {\n      id\n      ...CarDetailTable_ownedCar\n      ...ValuationArea_ownedCar\n    }\n    carLog {\n      id\n      ...CarDetailTable_carLog\n      ...ValuationArea_carLog\n    }\n  }\n": types.CarDetailTable_CarEstimateValuationFragmentDoc,
    "\n  fragment ValuationArea_ownedCar on OwnedCar {\n    id\n    latestMileageKm\n  }\n": types.ValuationArea_OwnedCarFragmentDoc,
    "\n  fragment ValuationArea_carLog on CarLog {\n    id\n    mileageKm\n  }\n": types.ValuationArea_CarLogFragmentDoc,
    "\n  fragment ValuationArea_valuationDetail on CarValuationDetail {\n    id\n    reasonText\n    reasonComment\n    preferredPrice\n  }\n": types.ValuationArea_ValuationDetailFragmentDoc,
    "\n  query CarEstimateValuationShow($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n      expiredPriceValidDeadline\n      completedAuto\n      ...CardHeader_carEstimateValuation\n      ...StatusView_carEstimateValuation\n      ...CarDetailTable_carEstimateValuation\n      ...CarEstimateValuationShow_AutoCompleteView_carEstimateValuation\n      ownedCar {\n        id\n        isProhibitCreateCarEstimateValuation\n        isProhibitCreateCarOfficialValuation\n        ...CarBaseTable_ownedCar\n        ...OfficialValuationGuideView_ownedCar\n      }\n    }\n  }\n": types.CarEstimateValuationShowDocument,
    "\n  fragment CardHeader_carEstimateValuation on CarEstimateValuation {\n    id\n    createdAt\n    completedAt\n  }\n": types.CardHeader_CarEstimateValuationFragmentDoc,
    "\n  fragment OfficialValuationGuideView_ownedCar on OwnedCar {\n    id\n    isProhibitCreateCarOfficialValuation\n  }\n": types.OfficialValuationGuideView_OwnedCarFragmentDoc,
    "\n  mutation OfficialValuationGuideView_CreateCarOfficialValuation($input: CreateCarOfficialValuationInput!) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.OfficialValuationGuideView_CreateCarOfficialValuationDocument,
    "\n  fragment StatusView_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCarId\n    ulid\n    status\n    minPrice\n    maxPrice\n    expiredPriceValidDeadline\n    priceValidDeadline\n    ownedCar {\n      id\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n": types.StatusView_CarEstimateValuationFragmentDoc,
    "\n  fragment CarDetailTable_carOfficialValuation on CarOfficialValuation {\n    id\n    reason\n    reasonComment\n    preferredPrice\n    ownedCar {\n      id\n      carOriginText\n      driveSystemText\n      carModelYear\n      color\n      hasAccidentHistory\n      accidentComment\n      isDirty\n      hasOdor\n      comment\n      inspectionCertificateImage {\n        id\n        url\n      }\n    }\n  }\n": types.CarDetailTable_CarOfficialValuationFragmentDoc,
    "\n  fragment CarImageList_ownedCar on OwnedCar {\n    id\n    images {\n      id\n      url\n      imageType\n    }\n  }\n": types.CarImageList_OwnedCarFragmentDoc,
    "\n  query CarOfficialValuationShow($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n      proposedPrice\n      expiredPriceValidDeadline\n      isAccepted\n      ...CarOfficialValuationShow_PreparingView_carOfficialValuation\n      ...CarOfficialValuationShow_NomalView_carOfficialValuation\n      ownedCar {\n        id\n        ...CarBaseTable_ownedCar\n        ...CarImageList_ownedCar\n        carEstimateValuations {\n          id\n          createdAt\n        }\n      }\n    }\n  }\n": types.CarOfficialValuationShowDocument,
    "\n  fragment CarOfficialValuationShow_CancelButton_CarOfficialValuation on CarOfficialValuation {\n    id\n    status\n  }\n": types.CarOfficialValuationShow_CancelButton_CarOfficialValuationFragmentDoc,
    "\n  mutation CarOfficialValuationShow_CancelButton_CancelCarOfficialValuation($input: CancelCarOfficialValuationInput!) {\n    cancelCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        status\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarOfficialValuationShow_CancelButton_CancelCarOfficialValuationDocument,
    "\n  mutation CarOfficialValuationShow_CancelButton_CreateCarOfficialValuation($input: CreateCarOfficialValuationInput!) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarOfficialValuationShow_CancelButton_CreateCarOfficialValuationDocument,
    "\n  fragment CarOfficialValuationShow_CancelView_CarOfficialValuation on CarOfficialValuation {\n    id\n    ...OfferList_carOfficialValuation\n    ownedCar {\n      id\n      isProhibitCreateCarOfficialValuation\n    }\n  }\n": types.CarOfficialValuationShow_CancelView_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuationShow_CarImagesArea_ownedCar on OwnedCar {\n    id\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n": types.CarOfficialValuationShow_CarImagesArea_OwnedCarFragmentDoc,
    "\n  fragment CarOfficialValuation_CompleteView_carOfficialValuation on CarOfficialValuation {\n    id\n    priceValidDeadline\n    expiredPriceValidDeadline\n    ...OfferList_carOfficialValuation\n  }\n": types.CarOfficialValuation_CompleteView_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuationShow_ConfirmingView_CarOfficialValuation on CarOfficialValuation {\n    id\n    ...CarOfficialValuationShow_CancelButton_CarOfficialValuation\n  }\n": types.CarOfficialValuationShow_ConfirmingView_CarOfficialValuationFragmentDoc,
    "\n  mutation CarOfficialValuationShow_CreateOfficialValuationButton_CreateCarOfficialValuation(\n    $input: CreateCarOfficialValuationInput!\n  ) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarOfficialValuationShow_CreateOfficialValuationButton_CreateCarOfficialValuationDocument,
    "\n  fragment CarOfficialValuation_InProgressView_carOfficialValuation on CarOfficialValuation {\n    id\n    createOfferDeadline\n    ...OfferList_carOfficialValuation\n    ...CarOfficialValuationShow_CancelButton_CarOfficialValuation\n    offers {\n      id\n    }\n  }\n": types.CarOfficialValuation_InProgressView_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuationShow_NomalView_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    status\n    createdAt\n    pickupZipCode\n    ...CarOfficialValuationShow_ConfirmingView_CarOfficialValuation\n    ...CarOfficialValuation_InProgressView_carOfficialValuation\n    ...CarOfficialValuation_CompleteView_carOfficialValuation\n    ...CarOfficialValuationShow_CancelView_CarOfficialValuation\n    detail {\n      id\n      ...ValuationArea_valuationDetail\n    }\n    ownedCar {\n      id\n      ...CarDetailTable_ownedCar\n      ...CarOfficialValuationShow_CarImagesArea_ownedCar\n      ...ValuationArea_ownedCar\n    }\n    carLog {\n      id\n      ...CarDetailTable_carLog\n      ...ValuationArea_carLog\n    }\n  }\n": types.CarOfficialValuationShow_NomalView_CarOfficialValuationFragmentDoc,
    "\n  fragment OfferList_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    isOfferAcceptable\n    createOfferDeadline\n    priceValidDeadline\n    status\n    offers {\n      id\n      proposedPrice\n      bringInProposedPrice\n      acceptPriceType\n      createdAt\n      comment\n      deliveryAcceptDeadline\n      expiredDeliveryAcceptDeadline\n      bringInAcceptDeadline\n      expiredBringInAcceptDeadline\n      dealerBranch {\n        id\n        nameForValuation\n      }\n    }\n  }\n": types.OfferList_CarOfficialValuationFragmentDoc,
    "\n  fragment CarOfficialValuationShow_CarCard_ownedCar on OwnedCar {\n    id\n    color\n    hasOdor\n    isDirty\n    hasAccidentHistory\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    fulfilledForCarOfficialValuation\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionType\n      optionTypeText\n    }\n  }\n": types.CarOfficialValuationShow_CarCard_OwnedCarFragmentDoc,
    "\n  fragment CarOfficialValuationShow_CarImagesCard_ownedCar on OwnedCar {\n    id\n    fulfilledForCarOfficialValuationByImages\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n": types.CarOfficialValuationShow_CarImagesCard_OwnedCarFragmentDoc,
    "\n  query CarOfficialValuationShow_CarImagesCard_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n    }\n  }\n": types.CarOfficialValuationShow_CarImagesCard_OwnedCarImageTypesDocument,
    "\n  query CarOfficialValuationShow_CarImagesCard_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n": types.CarOfficialValuationShow_CarImagesCard_OwnedCarDocument,
    "\n  query CarOfficialValuationShow_OfficialValuationForm_ownedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      carEstimateValuations {\n        id\n        status\n      }\n    }\n  }\n": types.CarOfficialValuationShow_OfficialValuationForm_OwnedCarDocument,
    "\n  query CarOfficialValuationShow_OfficialValuationForm_viewer {\n    viewer {\n      id\n      zipCode\n      zipCodeAddress {\n        id\n        prefecture\n        city\n      }\n    }\n  }\n": types.CarOfficialValuationShow_OfficialValuationForm_ViewerDocument,
    "\n  fragment CarOfficialValuationShow_OfficialValuationForm_carOfficialValuation on CarOfficialValuation {\n    id\n    pickupZipCode\n    ownedCar {\n      id\n      latestMileageKm\n    }\n    detail {\n      id\n      reason\n      reasonComment\n      preferredPrice\n    }\n  }\n": types.CarOfficialValuationShow_OfficialValuationForm_CarOfficialValuationFragmentDoc,
    "\n  query useOfficialValuationForm_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      pickupZipCode\n      detail {\n        id\n        reason\n        reasonComment\n        preferredPrice\n      }\n      ownedCar {\n        id\n        latestMileageKm\n      }\n    }\n  }\n": types.UseOfficialValuationForm_CarOfficialValuationDocument,
    "\n  mutation useOfficialValuationForm_UpdateCarOfficialValuation($input: UpdateCarOfficialValuationInput!) {\n    updateCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ...CarOfficialValuationShow_OfficialValuationForm_carOfficialValuation\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UseOfficialValuationForm_UpdateCarOfficialValuationDocument,
    "\n  mutation useOfficialValuationForm_requestCarOfficialValuation($input: RequestCarOfficialValuationInput!) {\n    requestCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UseOfficialValuationForm_RequestCarOfficialValuationDocument,
    "\n  mutation useOfficialValuationForm_updateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UseOfficialValuationForm_UpdateOwnedCarDocument,
    "\n  fragment CarOfficialValuationShow_PreparingView_carOfficialValuation on CarOfficialValuation {\n    id\n    ...CarOfficialValuationShow_OfficialValuationForm_carOfficialValuation\n    ownedCar {\n      id\n      fulfilledForCarOfficialValuation\n      fulfilledForCarOfficialValuationByImages\n      ...CarOfficialValuationShow_CarCard_ownedCar\n      ...CarOfficialValuationShow_CarImagesCard_ownedCar\n    }\n  }\n": types.CarOfficialValuationShow_PreparingView_CarOfficialValuationFragmentDoc,
    "\n  query CarOfficialValuationShow_PreparingView_viewer {\n    viewer {\n      id\n      fulfilledForCarOfficialValuation\n    }\n  }\n": types.CarOfficialValuationShow_PreparingView_ViewerDocument,
    "\n  query CarValuationOffer_NoticeCard_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n    }\n  }\n": types.CarValuationOffer_NoticeCard_CarOfficialValuationDocument,
    "\n  mutation CarValuationOffer_NoticeCard_CreateCarOfficialValuation($input: CreateCarOfficialValuationInput!) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarValuationOffer_NoticeCard_CreateCarOfficialValuationDocument,
    "\n  query CarValuationOffer_Term_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n    }\n  }\n": types.CarValuationOffer_Term_CarOfficialValuationDocument,
    "\n  mutation CarValuationOffer_Term_CreateCarOfficialValuation($input: CreateCarOfficialValuationInput!) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarValuationOffer_Term_CreateCarOfficialValuationDocument,
    "\n  mutation CarValuationOffer_Term_AcceptCarValuationOffer($input: AcceptCarValuationOfferInput!) {\n    acceptCarValuationOffer(input: $input) {\n      carValuationOffer {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CarValuationOffer_Term_AcceptCarValuationOfferDocument,
    "\n  query CarValuationList($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      carEstimateValuations {\n        id\n        status\n        ulid\n        minPrice\n        maxPrice\n        expiredPriceValidDeadline\n        priceValidDeadline\n        createdAt\n        completedAt\n        carLog {\n          id\n          mileageKm\n        }\n      }\n      carOfficialValuations {\n        id\n        status\n        ulid\n        proposedPrice\n        expiredPriceValidDeadline\n        priceValidDeadline\n        createdAt\n        completedAt\n        offers {\n          id\n          proposedPrice\n          acceptedPrice\n          isAccepted\n        }\n      }\n    }\n  }\n": types.CarValuationListDocument,
    "\n  fragment CarBaseTable_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    carOriginText\n    driveSystemText\n    latestMileageKm\n  }\n": types.CarBaseTable_OwnedCarFragmentDoc,
    "\n  fragment CarDetailTable_ownedCar on OwnedCar {\n    id\n    color\n    hasOdor\n    isDirty\n    hasAccidentHistory\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n": types.CarDetailTable_OwnedCarFragmentDoc,
    "\n  fragment CarDetailTable_carLog on CarLog {\n    id\n    color\n    hasOdor\n    isDirty\n    hasAccidentHistory\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    mileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n": types.CarDetailTable_CarLogFragmentDoc,
    "\n  query CarDetailTable_Viewer {\n    viewer {\n      id\n      fullName\n      zipCode\n      zipCodeAddress {\n        id\n        prefecture\n        city\n      }\n    }\n  }\n": types.CarDetailTable_ViewerDocument,
    "\n  query useImageTake_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n      optional\n    }\n  }\n": types.UseImageTake_OwnedCarImageTypesDocument,
    "\n  query useImageTake_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n": types.UseImageTake_OwnedCarDocument,
    "\n  mutation useImageTake_EditOwnedCarImage($input: EditOwnedCarImageInput!) {\n    editOwnedCarImage(input: $input) {\n      ownedCarImage {\n        id\n        url\n        imageType\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UseImageTake_EditOwnedCarImageDocument,
    "\n  query OwnedCarImages_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n    }\n  }\n": types.OwnedCarImages_OwnedCarImageTypesDocument,
    "\n  query OwnedCarImages_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n": types.OwnedCarImages_OwnedCarDocument,
    "\n  mutation OwnedCarImages_createOwnedCarImages($input: CreateOwnedCarImagesInput!) {\n    createOwnedCarImages(input: $input) {\n      ownedCarImages {\n        id\n        imageType\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.OwnedCarImages_CreateOwnedCarImagesDocument,
    "\n  query OwnedCarSelectCarEstimateValuation_OwnedCarsQuery {\n    ownedCars {\n      id\n      ...OwnedCarSelect_OwnedCarCard_ownedCar\n    }\n  }\n": types.OwnedCarSelectCarEstimateValuation_OwnedCarsQueryDocument,
    "\n  mutation OwnedCarSelectCarEstimateValuation_CompleteAutoCarEstimateValuation(\n    $input: CompleteAutoCarEstimateValuationInput!\n  ) {\n    completeAutoCarEstimateValuation(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n": types.OwnedCarSelectCarEstimateValuation_CompleteAutoCarEstimateValuationDocument,
    "\n  fragment OwnedCarSelect_OwnedCarCard_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    latestMileageKm\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n": types.OwnedCarSelect_OwnedCarCard_OwnedCarFragmentDoc,
    "\n  mutation UpdateUser_UserEdit($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n        fullName\n        zipCode\n        zipCodeAddress {\n          id\n          prefecture\n          city\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UpdateUser_UserEditDocument,
    "\n  fragment ReviewCard_userReview on UserReview {\n    id\n    reviewType\n    satisfactionRate\n    closedMonth\n    makeName\n    carModelName\n    title\n    conveniencePoint\n    staffComment\n    staff {\n      id\n      fullName\n    }\n  }\n": types.ReviewCard_UserReviewFragmentDoc,
    "\n  fragment ReviewsIndex_userReview on UserReview {\n    id\n    ...ReviewCard_userReview\n    ...ReviewModal_userReview\n  }\n": types.ReviewsIndex_UserReviewFragmentDoc,
    "\n  fragment ReviewModal_userReview on UserReview {\n    id\n    reviewType\n    satisfactionRate\n    closedMonth\n    makeName\n    carModelName\n    title\n    conveniencePoint\n    worryPoint\n    reason\n    staffComment\n    staff {\n      id\n      fullName\n      imageUrl\n    }\n  }\n": types.ReviewModal_UserReviewFragmentDoc,
    "\n  fragment CarCardGroup_carStock on CarStock {\n    id\n    ...LargeCarCard_carStock\n    ...SmallCarCard_carStock\n    ...CarIndex_CarMovies_carStock\n  }\n": types.CarCardGroup_CarStockFragmentDoc,
    "\n  fragment CarMoviePopup_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n        fullName\n      }\n    }\n    videos {\n      id\n      url\n      thumbnailUrl\n    }\n  }\n": types.CarMoviePopup_CarStockFragmentDoc,
    "\n  fragment LargeCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    dealerBranch {\n      id\n      landTransportArea\n    }\n    imageUrls\n  }\n": types.LargeCarCard_CarStockFragmentDoc,
    "\n  fragment SmallCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    dealerBranch {\n      id\n      landTransportArea\n    }\n    imageUrls\n  }\n": types.SmallCarCard_CarStockFragmentDoc,
    "\n  mutation useOwnedCarImage_DestroyOwnedCarImage($input: DestroyOwnedCarImageInput!) {\n    destroyOwnedCarImage(input: $input) {\n      id\n      isDestroyed\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UseOwnedCarImage_DestroyOwnedCarImageDocument,
    "\n  mutation createCarStockFavorite($input: CreateCarStockFavoriteInput!) {\n    createCarStockFavorite(input: $input) {\n      carStockFavorite {\n        id\n        carStock {\n          id\n          ...SmallCarCard_carStock\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.CreateCarStockFavoriteDocument,
    "\n  mutation destroyCarStockFavorite($input: DestroyCarStockFavoriteInput!) {\n    destroyCarStockFavorite(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.DestroyCarStockFavoriteDocument,
    "\n  mutation login($input: LoginInput!) {\n    login(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n        zipCode\n        lineAccount {\n          id\n          name\n        }\n        favoritedCarStocks {\n          id\n        }\n        inquiries {\n          id\n          carStocks {\n            id\n          }\n        }\n      }\n      isCreatedUser\n      errors {\n        path\n        message\n      }\n    }\n  }\n": types.LoginDocument,
    "\n  mutation updateLineAccountName($input: UpdateLineAccountNameInput!) {\n    updateLineAccountName(input: $input) {\n      lineAccount {\n        id\n        name\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n": types.UpdateLineAccountNameDocument,
    "\n  query convertLatlng($longitude: Float!, $latitude: Float!) {\n    convertLatlng(longitude: $longitude, latitude: $latitude) {\n      zipCode\n      address\n      prefectureCode\n      errors {\n        message\n      }\n    }\n  }\n": types.ConvertLatlngDocument,
    "\n  query user {\n    viewer {\n      id\n      lastName\n      firstName\n      zipCode\n      tracking\n      lineAccount {\n        id\n        name\n      }\n      favoritedCarStocks {\n        id\n      }\n      inquiries {\n        id\n        carStocks {\n          id\n        }\n      }\n      activeOneClickContracts {\n        id\n        carStock {\n          id\n        }\n      }\n    }\n  }\n": types.UserDocument,
    "\n  query fetchDirectUploadParams($requests: [DirectUploadRequest!]!) {\n    s3DirectUploadParams(requests: $requests) {\n      url\n      contentToken\n      fields\n    }\n  }\n": types.FetchDirectUploadParamsDocument,
    "\n  query CarStocksIndexPage($first: Int, $salesStatus: CarSalesStatusEnum) {\n    carStocks(first: $first, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...CarsIndex_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.CarStocksIndexPageDocument,
    "\n  query SpecialShowPage($first: Int, $after: String, $salesStatus: CarSalesStatusEnum, $tagGroup: TagGroupEnum) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, tagGroup: $tagGroup) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...SpecialShow_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.SpecialShowPageDocument,
    "\n  query CarStockShowPage($ulid: String!) {\n    carStock(ulid: $ulid) {\n      id\n      makeName\n      carModelName\n      carModelYear\n      shortGradeName\n      mileageKm\n      inspectionExpiryDate\n      images {\n        id\n        url\n      }\n      promotion {\n        id\n        description\n        catchCopy\n        scores {\n          score\n        }\n      }\n      carStockDetail {\n        id\n        repairHistoryCategory\n        interiorEvaluationScore\n      }\n      ...CarsShow_carStock\n      oneClickContractCounts\n    }\n  }\n": types.CarStockShowPageDocument,
    "\n  query CarsMoviesPage($first: Int, $after: String, $hasVideo: Boolean, $salesStatus: CarSalesStatusEnum) {\n    carStocks(first: $first, after: $after, hasVideo: $hasVideo, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...CarMovies_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.CarsMoviesPageDocument,
    "\n  mutation Logout($input: LogoutInput!) {\n    logout(input: $input) {\n      errors {\n        message\n      }\n    }\n  }\n": types.LogoutDocument,
    "\n  query CarStock($ulid: String!) {\n    carStock(ulid: $ulid) {\n      id\n    }\n  }\n": types.CarStockDocument,
    "\n  query formOptionQuery {\n    formOption {\n      id\n      paymentMethodOption {\n        id\n        value\n        label\n      }\n      requestTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n": types.FormOptionQueryDocument,
    "\n  query formOptionOneClickNew {\n    formOption {\n      id\n      requestTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n": types.FormOptionOneClickNewDocument,
    "\n  query ReviewsPage($reviewType: UserReviewReviewTypeEnum!, $first: Int, $after: String) {\n    userReviews(reviewType: $reviewType, first: $first, after: $after) {\n      nodes {\n        ...ReviewsIndex_userReview\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.ReviewsPageDocument,
    "\n  query CarStocksSearchPage(\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $bodyType: CarStockSearchBodyTypeEnum\n    $amountType: CarStockSearchAmountTypeEnum\n    $sortType: CarStockSortTypeEnum\n    $carModelNameKeyword: String\n    $hasVideo: Boolean\n    $includeTotalCount: Boolean!\n  ) {\n    carStocks(\n      first: $first\n      after: $after\n      salesStatus: $salesStatus\n      bodyType: $bodyType\n      amountType: $amountType\n      sortType: $sortType\n      carModelNameKeyword: $carModelNameKeyword\n      hasVideo: $hasVideo\n    ) {\n      edges {\n        node {\n          ...CarsSearch_carStock\n        }\n      }\n      totalCount @include(if: $includeTotalCount)\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n": types.CarStocksSearchPageDocument,
    "\n  query UserReviewFormOptionQuery {\n    formOption {\n      id\n      userReviewTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n": types.UserReviewFormOptionQueryDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AishameterCushionOwnedCarsQuery {\n    ownedCars {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n    }\n  }\n"): (typeof documents)["\n  query AishameterCushionOwnedCarsQuery {\n    ownedCars {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CompleteAutoCarEstimateValuation($input: CompleteAutoCarEstimateValuationInput!) {\n    completeAutoCarEstimateValuation(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CompleteAutoCarEstimateValuation($input: CompleteAutoCarEstimateValuationInput!) {\n    completeAutoCarEstimateValuation(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RequestDocument($input: RequestDocumentInput!) {\n    requestDocument(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RequestDocument($input: RequestDocumentInput!) {\n    requestDocument(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        zipCode\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        zipCode\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FavoritesIndexComponent($prefectureCode: Int) {\n    viewer {\n      id\n      favoritedCarStocks {\n        id\n        ulid\n        landTransportCost(prefectureCode: $prefectureCode)\n        ...SmallCarCard_carStock\n      }\n    }\n  }\n"): (typeof documents)["\n  query FavoritesIndexComponent($prefectureCode: Int) {\n    viewer {\n      id\n      favoritedCarStocks {\n        id\n        ulid\n        landTransportCost(prefectureCode: $prefectureCode)\n        ...SmallCarCard_carStock\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateInflowSource($input: CreateInflowSourceInput!) {\n    createInflowSource(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateInflowSource($input: CreateInflowSourceInput!) {\n    createInflowSource(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InquireBuyingNewInquireBuying($input: InquireBuyingInput!) {\n    inquireBuying(input: $input) {\n      inquiry {\n        id\n        user {\n          id\n          lastName\n          zipCode\n          firstName\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation InquireBuyingNewInquireBuying($input: InquireBuyingInput!) {\n    inquireBuying(input: $input) {\n      inquiry {\n        id\n        user {\n          id\n          lastName\n          zipCode\n          firstName\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createOneClickDetail($input: CreateOneClickDetailInput!) {\n    createOneClickDetail(input: $input) {\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createOneClickDetail($input: CreateOneClickDetailInput!) {\n    createOneClickDetail(input: $input) {\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query convertZipCode($zipCode: String!) {\n    convertZipCode(zipCode: $zipCode) {\n      zipCode\n      address\n      prefectureCode\n      prefectureName\n      cityName\n      errors {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  query convertZipCode($zipCode: String!) {\n    convertZipCode(zipCode: $zipCode) {\n      zipCode\n      address\n      prefectureCode\n      prefectureName\n      cityName\n      errors {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query userActiveOneClickContractsDetail {\n    viewer {\n      id\n      activeOneClickContracts {\n        id\n        carStock {\n          id\n        }\n        detail {\n          id\n          lastName\n          firstName\n          zipCode\n          address\n          paymentMethod\n          requestType\n          requestComment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query userActiveOneClickContractsDetail {\n    viewer {\n      id\n      activeOneClickContracts {\n        id\n        carStock {\n          id\n        }\n        detail {\n          id\n          lastName\n          firstName\n          zipCode\n          address\n          paymentMethod\n          requestType\n          requestComment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation oneClickContract($input: CreateOneClickContractInput!) {\n    createOneClickContract(input: $input) {\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation oneClickContract($input: CreateOneClickContractInput!) {\n    createOneClickContract(input: $input) {\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SearchConditionShow {\n    viewer {\n      id\n      lineAccount {\n        id\n        carStockSearchCondition {\n          id\n          minAmount\n          maxAmount\n          maxMileageKm\n          minCarModeYearBeforeYear\n          bodyTypeGroups {\n            id\n            bodyTypeGroup\n          }\n          searchConditionCarNames {\n            id\n            carModelName\n            makeName\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SearchConditionShow {\n    viewer {\n      id\n      lineAccount {\n        id\n        carStockSearchCondition {\n          id\n          minAmount\n          maxAmount\n          maxMileageKm\n          minCarModeYearBeforeYear\n          bodyTypeGroups {\n            id\n            bodyTypeGroup\n          }\n          searchConditionCarNames {\n            id\n            carModelName\n            makeName\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SearchConditionShow_CarStockNames($carModelNameKeyword: String, $makeNameKeyword: String) {\n    carStockNames(carModelNameKeyword: $carModelNameKeyword, makeNameKeyword: $makeNameKeyword) {\n      carModelNames\n      makeNames\n    }\n  }\n"): (typeof documents)["\n  query SearchConditionShow_CarStockNames($carModelNameKeyword: String, $makeNameKeyword: String) {\n    carStockNames(carModelNameKeyword: $carModelNameKeyword, makeNameKeyword: $makeNameKeyword) {\n      carModelNames\n      makeNames\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateCarStockSearchCondition($input: UpdateCarStockSearchConditionInput!) {\n    updateCarStockSearchCondition(input: $input) {\n      carStockSearchCondition {\n        id\n        minAmount\n        maxAmount\n        maxMileageKm\n        minCarModeYearBeforeYear\n        bodyTypeGroups {\n          id\n          bodyTypeGroup\n        }\n        searchConditionCarNames {\n          id\n          carModelName\n          makeName\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCarStockSearchCondition($input: UpdateCarStockSearchConditionInput!) {\n    updateCarStockSearchCondition(input: $input) {\n      carStockSearchCondition {\n        id\n        minAmount\n        maxAmount\n        maxMileageKm\n        minCarModeYearBeforeYear\n        bodyTypeGroups {\n          id\n          bodyTypeGroup\n        }\n        searchConditionCarNames {\n          id\n          carModelName\n          makeName\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DestroyCarStockSearchCondition($input: DestroyCarStockSearchConditionInput!) {\n    destroyCarStockSearchCondition(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DestroyCarStockSearchCondition($input: DestroyCarStockSearchConditionInput!) {\n    destroyCarStockSearchCondition(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SpecialShow_carStock on CarStock {\n    id\n    ...CarCardGroup_carStock\n  }\n"): (typeof documents)["\n  fragment SpecialShow_carStock on CarStock {\n    id\n    ...CarCardGroup_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SpecialShowComponent(\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $tagGroup: TagGroupEnum\n    $prefectureCode: Int\n  ) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, tagGroup: $tagGroup) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...SpecialShow_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query SpecialShowComponent(\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $tagGroup: TagGroupEnum\n    $prefectureCode: Int\n  ) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, tagGroup: $tagGroup) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...SpecialShow_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createUserReviewBuyingAfterSale($input: CreateUserReviewBuyingAfterSaleInput!) {\n    createUserReviewBuyingAfterSale(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation createUserReviewBuyingAfterSale($input: CreateUserReviewBuyingAfterSaleInput!) {\n    createUserReviewBuyingAfterSale(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createUserReviewBuyingContract($input: CreateUserReviewBuyingContractInput!) {\n    createUserReviewBuyingContract(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation createUserReviewBuyingContract($input: CreateUserReviewBuyingContractInput!) {\n    createUserReviewBuyingContract(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createUserReview($input: CreateUserReviewInput!) {\n    createUserReview(input: $input) {\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createUserReview($input: CreateUserReviewInput!) {\n    createUserReview(input: $input) {\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarIndex_CarMovies_carStock on CarStock {\n    id\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n    ...CarMoviePopup_carStock\n  }\n"): (typeof documents)["\n  fragment CarIndex_CarMovies_carStock on CarStock {\n    id\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n    ...CarMoviePopup_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ClosedCarStocksIndexComponent($first: Int, $salesStatus: CarSalesStatusEnum, $prefectureCode: Int) {\n    carStocks(first: $first, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarsIndex_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query ClosedCarStocksIndexComponent($first: Int, $salesStatus: CarSalesStatusEnum, $prefectureCode: Int) {\n    carStocks(first: $first, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarsIndex_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query HasVideoCarStocksIndexComponent($first: Int, $salesStatus: CarSalesStatusEnum, $hasVideo: Boolean) {\n    carStocks(first: $first, salesStatus: $salesStatus, hasVideo: $hasVideo) {\n      edges {\n        node {\n          id\n          ...CarsIndex_carStock\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query HasVideoCarStocksIndexComponent($first: Int, $salesStatus: CarSalesStatusEnum, $hasVideo: Boolean) {\n    carStocks(first: $first, salesStatus: $salesStatus, hasVideo: $hasVideo) {\n      edges {\n        node {\n          id\n          ...CarsIndex_carStock\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarsIndex_carStock on CarStock {\n    id\n    ...CarCardGroup_carStock\n    ...CarIndex_CarMovies_carStock\n    ...CarsSection_carStock\n  }\n"): (typeof documents)["\n  fragment CarsIndex_carStock on CarStock {\n    id\n    ...CarCardGroup_carStock\n    ...CarIndex_CarMovies_carStock\n    ...CarsSection_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarsSection_carStock on CarStock {\n    id\n    ...SmallCarCard_carStock\n  }\n"): (typeof documents)["\n  fragment CarsSection_carStock on CarStock {\n    id\n    ...SmallCarCard_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarCard_carStock on CarStock {\n    id\n    ulid\n    mileageKm\n    carModelName\n    shortGradeName\n    salesStatus\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n  }\n"): (typeof documents)["\n  fragment CarCard_carStock on CarStock {\n    id\n    ulid\n    mileageKm\n    carModelName\n    shortGradeName\n    salesStatus\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarMovies_carStock on CarStock {\n    id\n    ...CarCard_carStock\n    ...CarMoviePopup_carStock\n  }\n"): (typeof documents)["\n  fragment CarMovies_carStock on CarStock {\n    id\n    ...CarCard_carStock\n    ...CarMoviePopup_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarsMoviesComponent(\n    $first: Int\n    $after: String\n    $hasVideo: Boolean\n    $salesStatus: CarSalesStatusEnum\n    $prefectureCode: Int\n  ) {\n    carStocks(first: $first, after: $after, hasVideo: $hasVideo, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarMovies_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarsMoviesComponent(\n    $first: Int\n    $after: String\n    $hasVideo: Boolean\n    $salesStatus: CarSalesStatusEnum\n    $prefectureCode: Int\n  ) {\n    carStocks(first: $first, after: $after, hasVideo: $hasVideo, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarMovies_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarsSearch_carStock on CarStock {\n    id\n    ...OneColumnCarCard_carStock\n    ...TwoColumnCarCard_carStock\n  }\n"): (typeof documents)["\n  fragment CarsSearch_carStock on CarStock {\n    id\n    ...OneColumnCarCard_carStock\n    ...TwoColumnCarCard_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OneColumnCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    makeName\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    imageUrls\n    oneClickContractCounts\n    tags {\n      id\n      name\n    }\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment OneColumnCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    makeName\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    imageUrls\n    oneClickContractCounts\n    tags {\n      id\n      name\n    }\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TwoColumnCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    makeName\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    imageUrls\n    oneClickContractCounts\n    tags {\n      id\n      name\n    }\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n  }\n"): (typeof documents)["\n  fragment TwoColumnCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    makeName\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    imageUrls\n    oneClickContractCounts\n    tags {\n      id\n      name\n    }\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEquipment_carStock on CarStock {\n    id\n    mainCarStockEquipments {\n      key\n      value\n      text\n    }\n    subCarStockEquipments {\n      active\n      text\n      group\n    }\n  }\n"): (typeof documents)["\n  fragment CarEquipment_carStock on CarStock {\n    id\n    mainCarStockEquipments {\n      key\n      value\n      text\n    }\n    subCarStockEquipments {\n      active\n      text\n      group\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarFeature_carStock on CarStock {\n    id\n    carModelYear\n    mileageKm\n    inspectionExpiryDate\n    carStockDetail {\n      id\n      repairHistoryCategory\n    }\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      description\n    }\n    dealerBranch {\n      id\n      prefectureName\n    }\n  }\n"): (typeof documents)["\n  fragment CarFeature_carStock on CarStock {\n    id\n    carModelYear\n    mileageKm\n    inspectionExpiryDate\n    carStockDetail {\n      id\n      repairHistoryCategory\n    }\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      description\n    }\n    dealerBranch {\n      id\n      prefectureName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarFooter_carStock on CarStock {\n    id\n    ulid\n    makeName\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    salesPrice\n    otherPrice\n    salesStatus\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n  }\n"): (typeof documents)["\n  fragment CarFooter_carStock on CarStock {\n    id\n    ulid\n    makeName\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    salesPrice\n    otherPrice\n    salesStatus\n    landTransportCosts {\n      cost\n      prefectureCode\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarImages_carStock on CarStock {\n    id\n    images {\n      id\n      url\n      comment\n    }\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n    ...CarMoviePopup_carStock\n  }\n"): (typeof documents)["\n  fragment CarImages_carStock on CarStock {\n    id\n    images {\n      id\n      url\n      comment\n    }\n    video {\n      id\n      url\n      thumbnailUrl\n    }\n    ...CarMoviePopup_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarScore_carStock on CarStock {\n    id\n    vihicleConditionReportUrl\n    carStockDetail {\n      evaluationScore\n      interiorEvaluationScore\n    }\n    promotion {\n      id\n      staffComment\n      scores {\n        axis\n        score\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CarScore_carStock on CarStock {\n    id\n    vihicleConditionReportUrl\n    carStockDetail {\n      evaluationScore\n      interiorEvaluationScore\n    }\n    promotion {\n      id\n      staffComment\n      scores {\n        axis\n        score\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarSpec_carStock on CarStock {\n    id\n    slideDoorSpecText\n    carStockDetail {\n      seatingCapacity\n      displaySystematicColor\n      transmissionTypeGroup\n      engineDisplacement\n      fuelTypeText\n    }\n  }\n"): (typeof documents)["\n  fragment CarSpec_carStock on CarStock {\n    id\n    slideDoorSpecText\n    carStockDetail {\n      seatingCapacity\n      displaySystematicColor\n      transmissionTypeGroup\n      engineDisplacement\n      fuelTypeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarStaff_carStock on CarStock {\n    id\n    promotion {\n      id\n      postedBy {\n        id\n        lastName\n        imageUrl\n        comment\n        slug\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CarStaff_carStock on CarStock {\n    id\n    promotion {\n      id\n      postedBy {\n        id\n        lastName\n        imageUrl\n        comment\n        slug\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarsListComponent(\n    $prefectureCode: Int\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $bodyType: CarStockSearchBodyTypeEnum\n  ) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, bodyType: $bodyType) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarCardGroup_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarsListComponent(\n    $prefectureCode: Int\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $bodyType: CarStockSearchBodyTypeEnum\n  ) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, bodyType: $bodyType) {\n      edges {\n        node {\n          id\n          landTransportCost(prefectureCode: $prefectureCode)\n          ...CarCardGroup_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarsShow_carStock on CarStock {\n    id\n    ulid\n    salesStatus\n    makeName\n    carModelName\n    carModelYear\n    searchBodyType\n    oneClickContractCounts\n    ...CarFeature_carStock\n    ...CarFooter_carStock\n    ...CarImages_carStock\n    ...CarScore_carStock\n    ...CarEquipment_carStock\n    ...CarStaff_carStock\n    ...CarSpec_carStock\n  }\n"): (typeof documents)["\n  fragment CarsShow_carStock on CarStock {\n    id\n    ulid\n    salesStatus\n    makeName\n    carModelName\n    carModelYear\n    searchBodyType\n    oneClickContractCounts\n    ...CarFeature_carStock\n    ...CarFooter_carStock\n    ...CarImages_carStock\n    ...CarScore_carStock\n    ...CarEquipment_carStock\n    ...CarStaff_carStock\n    ...CarSpec_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ViewCarStock($input: ViewCarStockInput!) {\n    viewCarStock(input: $input) {\n      errors {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ViewCarStock($input: ViewCarStockInput!) {\n    viewCarStock(input: $input) {\n      errors {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InquirePurchaseNewInquireSelling($input: InquireSellingInput!) {\n    inquireSelling(input: $input) {\n      inquiry {\n        id\n        user {\n          id\n          firstName\n          lastName\n          zipCode\n        }\n      }\n      inspectionCertificateImageUrl\n      carEstimateValuationPath\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation InquirePurchaseNewInquireSelling($input: InquireSellingInput!) {\n    inquireSelling(input: $input) {\n      inquiry {\n        id\n        user {\n          id\n          firstName\n          lastName\n          zipCode\n        }\n      }\n      inspectionCertificateImageUrl\n      carEstimateValuationPath\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query InquiresNewComponent($ulids: [String!], $prefectureCode: Int) {\n    carStocks(ulids: $ulids) {\n      nodes {\n        id\n        landTransportCost(prefectureCode: $prefectureCode)\n        ...SmallCarCard_carStock\n      }\n    }\n  }\n"): (typeof documents)["\n  query InquiresNewComponent($ulids: [String!], $prefectureCode: Int) {\n    carStocks(ulids: $ulids) {\n      nodes {\n        id\n        landTransportCost(prefectureCode: $prefectureCode)\n        ...SmallCarCard_carStock\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InquiresNewInquireCarStock($input: InquireCarStocksInput!) {\n    inquireCarStocks(input: $input) {\n      inquiry {\n        id\n        user {\n          id\n          firstName\n          lastName\n          zipCode\n          inquiries {\n            id\n            carStocks {\n              id\n            }\n          }\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation InquiresNewInquireCarStock($input: InquireCarStocksInput!) {\n    inquireCarStocks(input: $input) {\n      inquiry {\n        id\n        user {\n          id\n          firstName\n          lastName\n          zipCode\n          inquiries {\n            id\n            carStocks {\n              id\n            }\n          }\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ContentLinkShow($key: String!) {\n    contentLink(key: $key) {\n      id\n      contentable {\n        urlToRedirect\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query ContentLinkShow($key: String!) {\n    contentLink(key: $key) {\n      id\n      contentable {\n        urlToRedirect\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MypageCallbackPage {\n    viewer {\n      id\n      lastName\n      firstName\n      publicDeals {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query MypageCallbackPage {\n    viewer {\n      id\n      lastName\n      firstName\n      publicDeals {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUserName_UserEdit($input: UpdateUserNameInput!) {\n    updateUserName(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserName_UserEdit($input: UpdateUserNameInput!) {\n    updateUserName(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DealStatus_deal on Deal {\n    id\n    dealTasks {\n      id\n      taskType\n      completed\n    }\n    mypageDealTasks {\n      type\n      group\n      status\n    }\n    ...TaskList_deal\n  }\n"): (typeof documents)["\n  fragment DealStatus_deal on Deal {\n    id\n    dealTasks {\n      id\n      taskType\n      completed\n    }\n    mypageDealTasks {\n      type\n      group\n      status\n    }\n    ...TaskList_deal\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MypageDealShowPage($dealId: ID!) {\n    deal(id: $dealId) {\n      id\n      user {\n        id\n        fullName\n      }\n      salesStaff {\n        id\n        fullName\n        imageUrl\n      }\n      ...NotificationCard_deal\n      ...OrderCar_deal\n      ...DealStatus_deal\n      ...InspectionCertificate_deal\n      ...PaymentCard_deal\n    }\n  }\n"): (typeof documents)["\n  query MypageDealShowPage($dealId: ID!) {\n    deal(id: $dealId) {\n      id\n      user {\n        id\n        fullName\n      }\n      salesStaff {\n        id\n        fullName\n        imageUrl\n      }\n      ...NotificationCard_deal\n      ...OrderCar_deal\n      ...DealStatus_deal\n      ...InspectionCertificate_deal\n      ...PaymentCard_deal\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment InspectionCertificate_deal on Deal {\n    id\n    dealAttachments {\n      id\n      attachmentType\n      url\n    }\n  }\n"): (typeof documents)["\n  fragment InspectionCertificate_deal on Deal {\n    id\n    dealAttachments {\n      id\n      attachmentType\n      url\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment NotificationCard_deal on Deal {\n    id\n    mypageDealNotice\n  }\n"): (typeof documents)["\n  fragment NotificationCard_deal on Deal {\n    id\n    mypageDealNotice\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OrderCar_deal on Deal {\n    id\n    carStock {\n      id\n      carModelYear\n      mileageKm\n      carModelName\n      shortGradeName\n      makeName\n      images {\n        id\n        url\n      }\n    }\n    dealDetail {\n      id\n      deliveryDate\n      deliveryTimeRangeText\n    }\n  }\n"): (typeof documents)["\n  fragment OrderCar_deal on Deal {\n    id\n    carStock {\n      id\n      carModelYear\n      mileageKm\n      carModelName\n      shortGradeName\n      makeName\n      images {\n        id\n        url\n      }\n    }\n    dealDetail {\n      id\n      deliveryDate\n      deliveryTimeRangeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PaymentCard_deal on Deal {\n    id\n    payment {\n      id\n      deadline\n      paymentMethod\n      amount\n      status\n    }\n    salesStaff {\n      id\n      dealerBranch {\n        id\n        bank {\n          bankName\n          accountType\n          accountNumber\n          accountName\n          branchCode\n          branchName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment PaymentCard_deal on Deal {\n    id\n    payment {\n      id\n      deadline\n      paymentMethod\n      amount\n      status\n    }\n    salesStaff {\n      id\n      dealerBranch {\n        id\n        bank {\n          bankName\n          accountType\n          accountNumber\n          accountName\n          branchCode\n          branchName\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TaskList_deal on Deal {\n    id\n    dealContractDeadline\n    dealContractSentEmail\n    dealTasks {\n      id\n      taskType\n      completed\n    }\n    dealDetail {\n      id\n      expectedCarRegistrationCompletionDate\n      deliveryDate\n      deliveryTimeRangeText\n    }\n    mypageDealTasks {\n      type\n      group\n      status\n      title\n    }\n    dealContract {\n      id\n      userAuthenticationMethod\n      deadline\n      email\n      smsTel\n    }\n  }\n"): (typeof documents)["\n  fragment TaskList_deal on Deal {\n    id\n    dealContractDeadline\n    dealContractSentEmail\n    dealTasks {\n      id\n      taskType\n      completed\n    }\n    dealDetail {\n      id\n      expectedCarRegistrationCompletionDate\n      deliveryDate\n      deliveryTimeRangeText\n    }\n    mypageDealTasks {\n      type\n      group\n      status\n      title\n    }\n    dealContract {\n      id\n      userAuthenticationMethod\n      deadline\n      email\n      smsTel\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OwnedCarEdit_ownedCar on OwnedCar {\n    id\n    color\n    hasOdor\n    isDirty\n    hasAccidentHistory\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionType\n    }\n  }\n"): (typeof documents)["\n  fragment OwnedCarEdit_ownedCar on OwnedCar {\n    id\n    color\n    hasOdor\n    isDirty\n    hasAccidentHistory\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarEditQuery($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarEdit_ownedCar\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarEditQuery($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarEdit_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarEdit_OwnedCarOptionOptions {\n    ownedCarOptionOptions {\n      optionType\n      optionTypeText\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarEdit_OwnedCarOptionOptions {\n    ownedCarOptionOptions {\n      optionType\n      optionTypeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OwnedCarEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...OwnedCarEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OwnedCarEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...OwnedCarEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OwnedCarEditBase_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    carOrigin\n    driveSystem\n    latestMileageKm\n  }\n"): (typeof documents)["\n  fragment OwnedCarEditBase_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    carOrigin\n    driveSystem\n    latestMileageKm\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarEditBaseQuery($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarEditBase_ownedCar\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarEditBaseQuery($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarEditBase_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OwnedCarEditBase_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...OwnedCarEditBase_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OwnedCarEditBase_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...OwnedCarEditBase_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OwnedCarShow_CarBaseTable_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    editDisabled\n    carOriginText\n    driveSystemText\n    isProhibitUpdateFromUser\n    latestMileageKm\n  }\n"): (typeof documents)["\n  fragment OwnedCarShow_CarBaseTable_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    editDisabled\n    carOriginText\n    driveSystemText\n    isProhibitUpdateFromUser\n    latestMileageKm\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OwnedCarShow_CarCard_ownedCar on OwnedCar {\n    id\n    color\n    hasOdor\n    isDirty\n    hasAccidentHistory\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    editDisabled\n    isProhibitUpdateFromUser\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n"): (typeof documents)["\n  fragment OwnedCarShow_CarCard_ownedCar on OwnedCar {\n    id\n    color\n    hasOdor\n    isDirty\n    hasAccidentHistory\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    editDisabled\n    isProhibitUpdateFromUser\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OwnedCarShow_CarImagesCard_ownedCar on OwnedCar {\n    id\n    editDisabled\n    isProhibitUpdateFromUser\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n"): (typeof documents)["\n  fragment OwnedCarShow_CarImagesCard_ownedCar on OwnedCar {\n    id\n    editDisabled\n    isProhibitUpdateFromUser\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarShow_CarImagesCard_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarShow_CarImagesCard_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarShow_CarImagesCard_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarShow_CarImagesCard_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteOwnedCar($input: DeleteOwnedCarInput!) {\n    deleteOwnedCar(input: $input) {\n      isSuccess\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteOwnedCar($input: DeleteOwnedCarInput!) {\n    deleteOwnedCar(input: $input) {\n      isSuccess\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OwnedCarShow_CurrentCarValuationButton_ownedCar on OwnedCar {\n    id\n    carEstimateValuations {\n      id\n      ulid\n      status\n      createdAt\n    }\n    carOfficialValuations {\n      id\n      ulid\n      status\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  fragment OwnedCarShow_CurrentCarValuationButton_ownedCar on OwnedCar {\n    id\n    carEstimateValuations {\n      id\n      ulid\n      status\n      createdAt\n    }\n    carOfficialValuations {\n      id\n      ulid\n      status\n      createdAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarShow($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarShow_CarBaseTable_ownedCar\n      ...CarDetailTable_ownedCar\n      ...OwnedCarShow_CarCard_ownedCar\n      ...OwnedCarShow_CarImagesCard_ownedCar\n      ...OwnedCarShow_CurrentCarValuationButton_ownedCar\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarShow($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...OwnedCarShow_CarBaseTable_ownedCar\n      ...CarDetailTable_ownedCar\n      ...OwnedCarShow_CarCard_ownedCar\n      ...OwnedCarShow_CarImagesCard_ownedCar\n      ...OwnedCarShow_CurrentCarValuationButton_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Viewer {\n    viewer {\n      id\n      fullName\n    }\n  }\n"): (typeof documents)["\n  query Viewer {\n    viewer {\n      id\n      fullName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCars {\n    ownedCars {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      images {\n        id\n        url\n        imageType\n      }\n      carEstimateValuations {\n        id\n        __typename\n        ulid\n        status\n        createdAt\n        expiredPriceValidDeadline\n      }\n      carOfficialValuations {\n        id\n        __typename\n        ulid\n        status\n        createdAt\n        expiredPriceValidDeadline\n        isAccepted\n        offers {\n          id\n          isAccepted\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query OwnedCars {\n    ownedCars {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      images {\n        id\n        url\n        imageType\n      }\n      carEstimateValuations {\n        id\n        __typename\n        ulid\n        status\n        createdAt\n        expiredPriceValidDeadline\n      }\n      carOfficialValuations {\n        id\n        __typename\n        ulid\n        status\n        createdAt\n        expiredPriceValidDeadline\n        isAccepted\n        offers {\n          id\n          isAccepted\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationEdit_ownedCar on OwnedCar {\n    id\n    carModelName\n    gradeName\n    driveSystem\n    firstRegistrationYear\n    latestMileageKm\n    hasAccidentHistory\n    accidentComment\n    color\n    isDirty\n    hasOdor\n    comment\n    carOrigin\n    inspectionCertificateImage {\n      id\n      url\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationEdit_ownedCar on OwnedCar {\n    id\n    carModelName\n    gradeName\n    driveSystem\n    firstRegistrationYear\n    latestMileageKm\n    hasAccidentHistory\n    accidentComment\n    color\n    isDirty\n    hasOdor\n    comment\n    carOrigin\n    inspectionCertificateImage {\n      id\n      url\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationEdit_carEstimateValuation on CarEstimateValuation {\n    id\n    detail {\n      id\n      reason\n      reasonComment\n      preferredPrice\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationEdit_carEstimateValuation on CarEstimateValuation {\n    id\n    detail {\n      id\n      reason\n      reasonComment\n      preferredPrice\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarEstimateValuationEdit_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...CarEstimateValuationEdit_ownedCar\n    }\n  }\n"): (typeof documents)["\n  query CarEstimateValuationEdit_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...CarEstimateValuationEdit_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarEstimateValuationEdit_CarEstimateValuation($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarEstimateValuationEdit_carEstimateValuation\n    }\n  }\n"): (typeof documents)["\n  query CarEstimateValuationEdit_CarEstimateValuation($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarEstimateValuationEdit_carEstimateValuation\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarEstimateValuationEdit_UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n        zipCode\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarEstimateValuationEdit_UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n        zipCode\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarEstimateValuationEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarEstimateValuationEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarEstimateValuationEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarEstimateValuationEdit_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarEstimateValuationEdit_UpdateCarEstimateValuation($input: UpdateCarEstimateValuationInput!) {\n    updateCarEstimateValuation(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ...CarEstimateValuationEdit_carEstimateValuation\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarEstimateValuationEdit_UpdateCarEstimateValuation($input: UpdateCarEstimateValuationInput!) {\n    updateCarEstimateValuation(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ...CarEstimateValuationEdit_carEstimateValuation\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationNew_ownedCar on OwnedCar {\n    id\n    carModelName\n    gradeName\n    driveSystem\n    firstRegistrationYear\n    latestMileageKm\n    hasAccidentHistory\n    accidentComment\n    color\n    isDirty\n    hasOdor\n    comment\n    carOrigin\n    inspectionCertificateImage {\n      id\n      url\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationNew_ownedCar on OwnedCar {\n    id\n    carModelName\n    gradeName\n    driveSystem\n    firstRegistrationYear\n    latestMileageKm\n    hasAccidentHistory\n    accidentComment\n    color\n    isDirty\n    hasOdor\n    comment\n    carOrigin\n    inspectionCertificateImage {\n      id\n      url\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationNew_carEstimateValuation on CarEstimateValuation {\n    id\n    detail {\n      id\n      reason\n      reasonComment\n      preferredPrice\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationNew_carEstimateValuation on CarEstimateValuation {\n    id\n    detail {\n      id\n      reason\n      reasonComment\n      preferredPrice\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarEstimateValuationNew_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...CarEstimateValuationNew_ownedCar\n    }\n  }\n"): (typeof documents)["\n  query CarEstimateValuationNew_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      ...CarEstimateValuationNew_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarEstimateValuationNew_CarEstimateValuation($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarEstimateValuationNew_carEstimateValuation\n    }\n  }\n"): (typeof documents)["\n  query CarEstimateValuationNew_CarEstimateValuation($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      ...CarEstimateValuationNew_carEstimateValuation\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarEstimateValuationNew_UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n        zipCode\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarEstimateValuationNew_UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n        zipCode\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarEstimateValuationNew_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarEstimateValuationNew_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarEstimateValuationNew_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n        ...CarEstimateValuationNew_ownedCar\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarEstimateValuationNew_CreateCarEstimateValuation($input: CreateCarEstimateValuationInput!) {\n    createCarEstimateValuation(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ...CarEstimateValuationNew_carEstimateValuation\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarEstimateValuationNew_CreateCarEstimateValuation($input: CreateCarEstimateValuationInput!) {\n    createCarEstimateValuation(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ...CarEstimateValuationNew_carEstimateValuation\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationShow_AutoCompleteView_AishameterButton_carLog on CarLog {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    minMileageKm\n    maxMileageKm\n    ownedCarId\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationShow_AutoCompleteView_AishameterButton_carLog on CarLog {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    minMileageKm\n    maxMileageKm\n    ownedCarId\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationShow_AutoCompleteView_carLog on CarLog {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    minMileageKm\n    maxMileageKm\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationShow_AutoCompleteView_carLog on CarLog {\n    id\n    makeName\n    carModelName\n    gradeName\n    firstRegistrationYear\n    minMileageKm\n    maxMileageKm\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationShow_AutoCompleteView_OwnedCarEditModal_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCar {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      editDisabled\n    }\n    carLog {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationShow_AutoCompleteView_OwnedCarEditModal_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCar {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n      editDisabled\n    }\n    carLog {\n      id\n      makeName\n      carModelName\n      gradeName\n      firstRegistrationYear\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarEstimateValuationShow_AutoCompleteView_carEstimateValuation on CarEstimateValuation {\n    id\n    minPrice\n    maxPrice\n    ownedCarId\n    ...CardHeader_carEstimateValuation\n    ...StatusView_carEstimateValuation\n    ...CarEstimateValuationShow_AutoCompleteView_OwnedCarEditModal_carEstimateValuation\n    carLog {\n      id\n      ...CarEstimateValuationShow_AutoCompleteView_carLog\n      ...CarEstimateValuationShow_AutoCompleteView_AishameterButton_carLog\n    }\n  }\n"): (typeof documents)["\n  fragment CarEstimateValuationShow_AutoCompleteView_carEstimateValuation on CarEstimateValuation {\n    id\n    minPrice\n    maxPrice\n    ownedCarId\n    ...CardHeader_carEstimateValuation\n    ...StatusView_carEstimateValuation\n    ...CarEstimateValuationShow_AutoCompleteView_OwnedCarEditModal_carEstimateValuation\n    carLog {\n      id\n      ...CarEstimateValuationShow_AutoCompleteView_carLog\n      ...CarEstimateValuationShow_AutoCompleteView_AishameterButton_carLog\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AutoCompleteView_OwnedCarEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AutoCompleteView_OwnedCarEdit_UpdateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarDetailTable_carEstimateValuation on CarEstimateValuation {\n    id\n    ulid\n    status\n    detail {\n      id\n      ...ValuationArea_valuationDetail\n    }\n    ownedCar {\n      id\n      ...CarDetailTable_ownedCar\n      ...ValuationArea_ownedCar\n    }\n    carLog {\n      id\n      ...CarDetailTable_carLog\n      ...ValuationArea_carLog\n    }\n  }\n"): (typeof documents)["\n  fragment CarDetailTable_carEstimateValuation on CarEstimateValuation {\n    id\n    ulid\n    status\n    detail {\n      id\n      ...ValuationArea_valuationDetail\n    }\n    ownedCar {\n      id\n      ...CarDetailTable_ownedCar\n      ...ValuationArea_ownedCar\n    }\n    carLog {\n      id\n      ...CarDetailTable_carLog\n      ...ValuationArea_carLog\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ValuationArea_ownedCar on OwnedCar {\n    id\n    latestMileageKm\n  }\n"): (typeof documents)["\n  fragment ValuationArea_ownedCar on OwnedCar {\n    id\n    latestMileageKm\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ValuationArea_carLog on CarLog {\n    id\n    mileageKm\n  }\n"): (typeof documents)["\n  fragment ValuationArea_carLog on CarLog {\n    id\n    mileageKm\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ValuationArea_valuationDetail on CarValuationDetail {\n    id\n    reasonText\n    reasonComment\n    preferredPrice\n  }\n"): (typeof documents)["\n  fragment ValuationArea_valuationDetail on CarValuationDetail {\n    id\n    reasonText\n    reasonComment\n    preferredPrice\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarEstimateValuationShow($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n      expiredPriceValidDeadline\n      completedAuto\n      ...CardHeader_carEstimateValuation\n      ...StatusView_carEstimateValuation\n      ...CarDetailTable_carEstimateValuation\n      ...CarEstimateValuationShow_AutoCompleteView_carEstimateValuation\n      ownedCar {\n        id\n        isProhibitCreateCarEstimateValuation\n        isProhibitCreateCarOfficialValuation\n        ...CarBaseTable_ownedCar\n        ...OfficialValuationGuideView_ownedCar\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarEstimateValuationShow($ownedCarId: ID!, $ulid: String!) {\n    carEstimateValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n      expiredPriceValidDeadline\n      completedAuto\n      ...CardHeader_carEstimateValuation\n      ...StatusView_carEstimateValuation\n      ...CarDetailTable_carEstimateValuation\n      ...CarEstimateValuationShow_AutoCompleteView_carEstimateValuation\n      ownedCar {\n        id\n        isProhibitCreateCarEstimateValuation\n        isProhibitCreateCarOfficialValuation\n        ...CarBaseTable_ownedCar\n        ...OfficialValuationGuideView_ownedCar\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CardHeader_carEstimateValuation on CarEstimateValuation {\n    id\n    createdAt\n    completedAt\n  }\n"): (typeof documents)["\n  fragment CardHeader_carEstimateValuation on CarEstimateValuation {\n    id\n    createdAt\n    completedAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OfficialValuationGuideView_ownedCar on OwnedCar {\n    id\n    isProhibitCreateCarOfficialValuation\n  }\n"): (typeof documents)["\n  fragment OfficialValuationGuideView_ownedCar on OwnedCar {\n    id\n    isProhibitCreateCarOfficialValuation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OfficialValuationGuideView_CreateCarOfficialValuation($input: CreateCarOfficialValuationInput!) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OfficialValuationGuideView_CreateCarOfficialValuation($input: CreateCarOfficialValuationInput!) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment StatusView_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCarId\n    ulid\n    status\n    minPrice\n    maxPrice\n    expiredPriceValidDeadline\n    priceValidDeadline\n    ownedCar {\n      id\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n"): (typeof documents)["\n  fragment StatusView_carEstimateValuation on CarEstimateValuation {\n    id\n    ownedCarId\n    ulid\n    status\n    minPrice\n    maxPrice\n    expiredPriceValidDeadline\n    priceValidDeadline\n    ownedCar {\n      id\n      ...OfficialValuationGuideView_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarDetailTable_carOfficialValuation on CarOfficialValuation {\n    id\n    reason\n    reasonComment\n    preferredPrice\n    ownedCar {\n      id\n      carOriginText\n      driveSystemText\n      carModelYear\n      color\n      hasAccidentHistory\n      accidentComment\n      isDirty\n      hasOdor\n      comment\n      inspectionCertificateImage {\n        id\n        url\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CarDetailTable_carOfficialValuation on CarOfficialValuation {\n    id\n    reason\n    reasonComment\n    preferredPrice\n    ownedCar {\n      id\n      carOriginText\n      driveSystemText\n      carModelYear\n      color\n      hasAccidentHistory\n      accidentComment\n      isDirty\n      hasOdor\n      comment\n      inspectionCertificateImage {\n        id\n        url\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarImageList_ownedCar on OwnedCar {\n    id\n    images {\n      id\n      url\n      imageType\n    }\n  }\n"): (typeof documents)["\n  fragment CarImageList_ownedCar on OwnedCar {\n    id\n    images {\n      id\n      url\n      imageType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationShow($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n      proposedPrice\n      expiredPriceValidDeadline\n      isAccepted\n      ...CarOfficialValuationShow_PreparingView_carOfficialValuation\n      ...CarOfficialValuationShow_NomalView_carOfficialValuation\n      ownedCar {\n        id\n        ...CarBaseTable_ownedCar\n        ...CarImageList_ownedCar\n        carEstimateValuations {\n          id\n          createdAt\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationShow($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n      proposedPrice\n      expiredPriceValidDeadline\n      isAccepted\n      ...CarOfficialValuationShow_PreparingView_carOfficialValuation\n      ...CarOfficialValuationShow_NomalView_carOfficialValuation\n      ownedCar {\n        id\n        ...CarBaseTable_ownedCar\n        ...CarImageList_ownedCar\n        carEstimateValuations {\n          id\n          createdAt\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_CancelButton_CarOfficialValuation on CarOfficialValuation {\n    id\n    status\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_CancelButton_CarOfficialValuation on CarOfficialValuation {\n    id\n    status\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarOfficialValuationShow_CancelButton_CancelCarOfficialValuation($input: CancelCarOfficialValuationInput!) {\n    cancelCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        status\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarOfficialValuationShow_CancelButton_CancelCarOfficialValuation($input: CancelCarOfficialValuationInput!) {\n    cancelCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        status\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarOfficialValuationShow_CancelButton_CreateCarOfficialValuation($input: CreateCarOfficialValuationInput!) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarOfficialValuationShow_CancelButton_CreateCarOfficialValuation($input: CreateCarOfficialValuationInput!) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_CancelView_CarOfficialValuation on CarOfficialValuation {\n    id\n    ...OfferList_carOfficialValuation\n    ownedCar {\n      id\n      isProhibitCreateCarOfficialValuation\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_CancelView_CarOfficialValuation on CarOfficialValuation {\n    id\n    ...OfferList_carOfficialValuation\n    ownedCar {\n      id\n      isProhibitCreateCarOfficialValuation\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_CarImagesArea_ownedCar on OwnedCar {\n    id\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_CarImagesArea_ownedCar on OwnedCar {\n    id\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuation_CompleteView_carOfficialValuation on CarOfficialValuation {\n    id\n    priceValidDeadline\n    expiredPriceValidDeadline\n    ...OfferList_carOfficialValuation\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuation_CompleteView_carOfficialValuation on CarOfficialValuation {\n    id\n    priceValidDeadline\n    expiredPriceValidDeadline\n    ...OfferList_carOfficialValuation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_ConfirmingView_CarOfficialValuation on CarOfficialValuation {\n    id\n    ...CarOfficialValuationShow_CancelButton_CarOfficialValuation\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_ConfirmingView_CarOfficialValuation on CarOfficialValuation {\n    id\n    ...CarOfficialValuationShow_CancelButton_CarOfficialValuation\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarOfficialValuationShow_CreateOfficialValuationButton_CreateCarOfficialValuation(\n    $input: CreateCarOfficialValuationInput!\n  ) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarOfficialValuationShow_CreateOfficialValuationButton_CreateCarOfficialValuation(\n    $input: CreateCarOfficialValuationInput!\n  ) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuation_InProgressView_carOfficialValuation on CarOfficialValuation {\n    id\n    createOfferDeadline\n    ...OfferList_carOfficialValuation\n    ...CarOfficialValuationShow_CancelButton_CarOfficialValuation\n    offers {\n      id\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuation_InProgressView_carOfficialValuation on CarOfficialValuation {\n    id\n    createOfferDeadline\n    ...OfferList_carOfficialValuation\n    ...CarOfficialValuationShow_CancelButton_CarOfficialValuation\n    offers {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_NomalView_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    status\n    createdAt\n    pickupZipCode\n    ...CarOfficialValuationShow_ConfirmingView_CarOfficialValuation\n    ...CarOfficialValuation_InProgressView_carOfficialValuation\n    ...CarOfficialValuation_CompleteView_carOfficialValuation\n    ...CarOfficialValuationShow_CancelView_CarOfficialValuation\n    detail {\n      id\n      ...ValuationArea_valuationDetail\n    }\n    ownedCar {\n      id\n      ...CarDetailTable_ownedCar\n      ...CarOfficialValuationShow_CarImagesArea_ownedCar\n      ...ValuationArea_ownedCar\n    }\n    carLog {\n      id\n      ...CarDetailTable_carLog\n      ...ValuationArea_carLog\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_NomalView_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    status\n    createdAt\n    pickupZipCode\n    ...CarOfficialValuationShow_ConfirmingView_CarOfficialValuation\n    ...CarOfficialValuation_InProgressView_carOfficialValuation\n    ...CarOfficialValuation_CompleteView_carOfficialValuation\n    ...CarOfficialValuationShow_CancelView_CarOfficialValuation\n    detail {\n      id\n      ...ValuationArea_valuationDetail\n    }\n    ownedCar {\n      id\n      ...CarDetailTable_ownedCar\n      ...CarOfficialValuationShow_CarImagesArea_ownedCar\n      ...ValuationArea_ownedCar\n    }\n    carLog {\n      id\n      ...CarDetailTable_carLog\n      ...ValuationArea_carLog\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OfferList_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    isOfferAcceptable\n    createOfferDeadline\n    priceValidDeadline\n    status\n    offers {\n      id\n      proposedPrice\n      bringInProposedPrice\n      acceptPriceType\n      createdAt\n      comment\n      deliveryAcceptDeadline\n      expiredDeliveryAcceptDeadline\n      bringInAcceptDeadline\n      expiredBringInAcceptDeadline\n      dealerBranch {\n        id\n        nameForValuation\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment OfferList_carOfficialValuation on CarOfficialValuation {\n    id\n    ulid\n    isOfferAcceptable\n    createOfferDeadline\n    priceValidDeadline\n    status\n    offers {\n      id\n      proposedPrice\n      bringInProposedPrice\n      acceptPriceType\n      createdAt\n      comment\n      deliveryAcceptDeadline\n      expiredDeliveryAcceptDeadline\n      bringInAcceptDeadline\n      expiredBringInAcceptDeadline\n      dealerBranch {\n        id\n        nameForValuation\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_CarCard_ownedCar on OwnedCar {\n    id\n    color\n    hasOdor\n    isDirty\n    hasAccidentHistory\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    fulfilledForCarOfficialValuation\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionType\n      optionTypeText\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_CarCard_ownedCar on OwnedCar {\n    id\n    color\n    hasOdor\n    isDirty\n    hasAccidentHistory\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    fulfilledForCarOfficialValuation\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionType\n      optionTypeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_CarImagesCard_ownedCar on OwnedCar {\n    id\n    fulfilledForCarOfficialValuationByImages\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_CarImagesCard_ownedCar on OwnedCar {\n    id\n    fulfilledForCarOfficialValuationByImages\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationShow_CarImagesCard_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationShow_CarImagesCard_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationShow_CarImagesCard_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationShow_CarImagesCard_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationShow_OfficialValuationForm_ownedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      carEstimateValuations {\n        id\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationShow_OfficialValuationForm_ownedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      carEstimateValuations {\n        id\n        status\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationShow_OfficialValuationForm_viewer {\n    viewer {\n      id\n      zipCode\n      zipCodeAddress {\n        id\n        prefecture\n        city\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationShow_OfficialValuationForm_viewer {\n    viewer {\n      id\n      zipCode\n      zipCodeAddress {\n        id\n        prefecture\n        city\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_OfficialValuationForm_carOfficialValuation on CarOfficialValuation {\n    id\n    pickupZipCode\n    ownedCar {\n      id\n      latestMileageKm\n    }\n    detail {\n      id\n      reason\n      reasonComment\n      preferredPrice\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_OfficialValuationForm_carOfficialValuation on CarOfficialValuation {\n    id\n    pickupZipCode\n    ownedCar {\n      id\n      latestMileageKm\n    }\n    detail {\n      id\n      reason\n      reasonComment\n      preferredPrice\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query useOfficialValuationForm_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      pickupZipCode\n      detail {\n        id\n        reason\n        reasonComment\n        preferredPrice\n      }\n      ownedCar {\n        id\n        latestMileageKm\n      }\n    }\n  }\n"): (typeof documents)["\n  query useOfficialValuationForm_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      pickupZipCode\n      detail {\n        id\n        reason\n        reasonComment\n        preferredPrice\n      }\n      ownedCar {\n        id\n        latestMileageKm\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation useOfficialValuationForm_UpdateCarOfficialValuation($input: UpdateCarOfficialValuationInput!) {\n    updateCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ...CarOfficialValuationShow_OfficialValuationForm_carOfficialValuation\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation useOfficialValuationForm_UpdateCarOfficialValuation($input: UpdateCarOfficialValuationInput!) {\n    updateCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ...CarOfficialValuationShow_OfficialValuationForm_carOfficialValuation\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation useOfficialValuationForm_requestCarOfficialValuation($input: RequestCarOfficialValuationInput!) {\n    requestCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation useOfficialValuationForm_requestCarOfficialValuation($input: RequestCarOfficialValuationInput!) {\n    requestCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation useOfficialValuationForm_updateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation useOfficialValuationForm_updateOwnedCar($input: UpdateOwnedCarInput!) {\n    updateOwnedCar(input: $input) {\n      ownedCar {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarOfficialValuationShow_PreparingView_carOfficialValuation on CarOfficialValuation {\n    id\n    ...CarOfficialValuationShow_OfficialValuationForm_carOfficialValuation\n    ownedCar {\n      id\n      fulfilledForCarOfficialValuation\n      fulfilledForCarOfficialValuationByImages\n      ...CarOfficialValuationShow_CarCard_ownedCar\n      ...CarOfficialValuationShow_CarImagesCard_ownedCar\n    }\n  }\n"): (typeof documents)["\n  fragment CarOfficialValuationShow_PreparingView_carOfficialValuation on CarOfficialValuation {\n    id\n    ...CarOfficialValuationShow_OfficialValuationForm_carOfficialValuation\n    ownedCar {\n      id\n      fulfilledForCarOfficialValuation\n      fulfilledForCarOfficialValuationByImages\n      ...CarOfficialValuationShow_CarCard_ownedCar\n      ...CarOfficialValuationShow_CarImagesCard_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarOfficialValuationShow_PreparingView_viewer {\n    viewer {\n      id\n      fulfilledForCarOfficialValuation\n    }\n  }\n"): (typeof documents)["\n  query CarOfficialValuationShow_PreparingView_viewer {\n    viewer {\n      id\n      fulfilledForCarOfficialValuation\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarValuationOffer_NoticeCard_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n    }\n  }\n"): (typeof documents)["\n  query CarValuationOffer_NoticeCard_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarValuationOffer_NoticeCard_CreateCarOfficialValuation($input: CreateCarOfficialValuationInput!) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarValuationOffer_NoticeCard_CreateCarOfficialValuation($input: CreateCarOfficialValuationInput!) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarValuationOffer_Term_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n    }\n  }\n"): (typeof documents)["\n  query CarValuationOffer_Term_CarOfficialValuation($ownedCarId: ID!, $ulid: String!) {\n    carOfficialValuation(ownedCarId: $ownedCarId, ulid: $ulid) {\n      id\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarValuationOffer_Term_CreateCarOfficialValuation($input: CreateCarOfficialValuationInput!) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarValuationOffer_Term_CreateCarOfficialValuation($input: CreateCarOfficialValuationInput!) {\n    createCarOfficialValuation(input: $input) {\n      carOfficialValuation {\n        id\n        ulid\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CarValuationOffer_Term_AcceptCarValuationOffer($input: AcceptCarValuationOfferInput!) {\n    acceptCarValuationOffer(input: $input) {\n      carValuationOffer {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CarValuationOffer_Term_AcceptCarValuationOffer($input: AcceptCarValuationOfferInput!) {\n    acceptCarValuationOffer(input: $input) {\n      carValuationOffer {\n        id\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarValuationList($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      carEstimateValuations {\n        id\n        status\n        ulid\n        minPrice\n        maxPrice\n        expiredPriceValidDeadline\n        priceValidDeadline\n        createdAt\n        completedAt\n        carLog {\n          id\n          mileageKm\n        }\n      }\n      carOfficialValuations {\n        id\n        status\n        ulid\n        proposedPrice\n        expiredPriceValidDeadline\n        priceValidDeadline\n        createdAt\n        completedAt\n        offers {\n          id\n          proposedPrice\n          acceptedPrice\n          isAccepted\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarValuationList($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      carEstimateValuations {\n        id\n        status\n        ulid\n        minPrice\n        maxPrice\n        expiredPriceValidDeadline\n        priceValidDeadline\n        createdAt\n        completedAt\n        carLog {\n          id\n          mileageKm\n        }\n      }\n      carOfficialValuations {\n        id\n        status\n        ulid\n        proposedPrice\n        expiredPriceValidDeadline\n        priceValidDeadline\n        createdAt\n        completedAt\n        offers {\n          id\n          proposedPrice\n          acceptedPrice\n          isAccepted\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarBaseTable_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    carOriginText\n    driveSystemText\n    latestMileageKm\n  }\n"): (typeof documents)["\n  fragment CarBaseTable_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    carOriginText\n    driveSystemText\n    latestMileageKm\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarDetailTable_ownedCar on OwnedCar {\n    id\n    color\n    hasOdor\n    isDirty\n    hasAccidentHistory\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n"): (typeof documents)["\n  fragment CarDetailTable_ownedCar on OwnedCar {\n    id\n    color\n    hasOdor\n    isDirty\n    hasAccidentHistory\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    latestMileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarDetailTable_carLog on CarLog {\n    id\n    color\n    hasOdor\n    isDirty\n    hasAccidentHistory\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    mileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n"): (typeof documents)["\n  fragment CarDetailTable_carLog on CarLog {\n    id\n    color\n    hasOdor\n    isDirty\n    hasAccidentHistory\n    accidentComment\n    hasMendHistory\n    mendComment\n    comment\n    mileageKm\n    inspectionCertificateImage {\n      id\n      url\n    }\n    options {\n      id\n      optionTypeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarDetailTable_Viewer {\n    viewer {\n      id\n      fullName\n      zipCode\n      zipCodeAddress {\n        id\n        prefecture\n        city\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarDetailTable_Viewer {\n    viewer {\n      id\n      fullName\n      zipCode\n      zipCodeAddress {\n        id\n        prefecture\n        city\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query useImageTake_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n      optional\n    }\n  }\n"): (typeof documents)["\n  query useImageTake_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n      optional\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query useImageTake_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n"): (typeof documents)["\n  query useImageTake_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation useImageTake_EditOwnedCarImage($input: EditOwnedCarImageInput!) {\n    editOwnedCarImage(input: $input) {\n      ownedCarImage {\n        id\n        url\n        imageType\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation useImageTake_EditOwnedCarImage($input: EditOwnedCarImageInput!) {\n    editOwnedCarImage(input: $input) {\n      ownedCarImage {\n        id\n        url\n        imageType\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarImages_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarImages_OwnedCarImageTypes {\n    ownedCarImageTypes {\n      imageType\n      imageTypeText\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarImages_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarImages_OwnedCar($id: ID!) {\n    ownedCar(id: $id) {\n      id\n      imagesForIndex {\n        id\n        url\n        imageType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OwnedCarImages_createOwnedCarImages($input: CreateOwnedCarImagesInput!) {\n    createOwnedCarImages(input: $input) {\n      ownedCarImages {\n        id\n        imageType\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OwnedCarImages_createOwnedCarImages($input: CreateOwnedCarImagesInput!) {\n    createOwnedCarImages(input: $input) {\n      ownedCarImages {\n        id\n        imageType\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OwnedCarSelectCarEstimateValuation_OwnedCarsQuery {\n    ownedCars {\n      id\n      ...OwnedCarSelect_OwnedCarCard_ownedCar\n    }\n  }\n"): (typeof documents)["\n  query OwnedCarSelectCarEstimateValuation_OwnedCarsQuery {\n    ownedCars {\n      id\n      ...OwnedCarSelect_OwnedCarCard_ownedCar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OwnedCarSelectCarEstimateValuation_CompleteAutoCarEstimateValuation(\n    $input: CompleteAutoCarEstimateValuationInput!\n  ) {\n    completeAutoCarEstimateValuation(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation OwnedCarSelectCarEstimateValuation_CompleteAutoCarEstimateValuation(\n    $input: CompleteAutoCarEstimateValuationInput!\n  ) {\n    completeAutoCarEstimateValuation(input: $input) {\n      carEstimateValuation {\n        id\n        ulid\n        ownedCarId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OwnedCarSelect_OwnedCarCard_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    latestMileageKm\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n"): (typeof documents)["\n  fragment OwnedCarSelect_OwnedCarCard_ownedCar on OwnedCar {\n    id\n    makeName\n    carModelName\n    firstRegistrationYear\n    gradeName\n    latestMileageKm\n    imagesForIndex {\n      id\n      url\n      imageType\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUser_UserEdit($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n        fullName\n        zipCode\n        zipCodeAddress {\n          id\n          prefecture\n          city\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUser_UserEdit($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n        fullName\n        zipCode\n        zipCodeAddress {\n          id\n          prefecture\n          city\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ReviewCard_userReview on UserReview {\n    id\n    reviewType\n    satisfactionRate\n    closedMonth\n    makeName\n    carModelName\n    title\n    conveniencePoint\n    staffComment\n    staff {\n      id\n      fullName\n    }\n  }\n"): (typeof documents)["\n  fragment ReviewCard_userReview on UserReview {\n    id\n    reviewType\n    satisfactionRate\n    closedMonth\n    makeName\n    carModelName\n    title\n    conveniencePoint\n    staffComment\n    staff {\n      id\n      fullName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ReviewsIndex_userReview on UserReview {\n    id\n    ...ReviewCard_userReview\n    ...ReviewModal_userReview\n  }\n"): (typeof documents)["\n  fragment ReviewsIndex_userReview on UserReview {\n    id\n    ...ReviewCard_userReview\n    ...ReviewModal_userReview\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ReviewModal_userReview on UserReview {\n    id\n    reviewType\n    satisfactionRate\n    closedMonth\n    makeName\n    carModelName\n    title\n    conveniencePoint\n    worryPoint\n    reason\n    staffComment\n    staff {\n      id\n      fullName\n      imageUrl\n    }\n  }\n"): (typeof documents)["\n  fragment ReviewModal_userReview on UserReview {\n    id\n    reviewType\n    satisfactionRate\n    closedMonth\n    makeName\n    carModelName\n    title\n    conveniencePoint\n    worryPoint\n    reason\n    staffComment\n    staff {\n      id\n      fullName\n      imageUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarCardGroup_carStock on CarStock {\n    id\n    ...LargeCarCard_carStock\n    ...SmallCarCard_carStock\n    ...CarIndex_CarMovies_carStock\n  }\n"): (typeof documents)["\n  fragment CarCardGroup_carStock on CarStock {\n    id\n    ...LargeCarCard_carStock\n    ...SmallCarCard_carStock\n    ...CarIndex_CarMovies_carStock\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CarMoviePopup_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n        fullName\n      }\n    }\n    videos {\n      id\n      url\n      thumbnailUrl\n    }\n  }\n"): (typeof documents)["\n  fragment CarMoviePopup_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n        fullName\n      }\n    }\n    videos {\n      id\n      url\n      thumbnailUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment LargeCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    dealerBranch {\n      id\n      landTransportArea\n    }\n    imageUrls\n  }\n"): (typeof documents)["\n  fragment LargeCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    dealerBranch {\n      id\n      landTransportArea\n    }\n    imageUrls\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SmallCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    dealerBranch {\n      id\n      landTransportArea\n    }\n    imageUrls\n  }\n"): (typeof documents)["\n  fragment SmallCarCard_carStock on CarStock {\n    id\n    ulid\n    carModelYear\n    mileageKm\n    carModelName\n    shortGradeName\n    totalPaymentAmount\n    salesStatus\n    publishedAt\n    tags {\n      id\n      name\n    }\n    promotion {\n      id\n      catchCopy\n      staffComment\n      postedBy {\n        id\n        imageUrl\n      }\n    }\n    dealerBranch {\n      id\n      landTransportArea\n    }\n    imageUrls\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation useOwnedCarImage_DestroyOwnedCarImage($input: DestroyOwnedCarImageInput!) {\n    destroyOwnedCarImage(input: $input) {\n      id\n      isDestroyed\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation useOwnedCarImage_DestroyOwnedCarImage($input: DestroyOwnedCarImageInput!) {\n    destroyOwnedCarImage(input: $input) {\n      id\n      isDestroyed\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createCarStockFavorite($input: CreateCarStockFavoriteInput!) {\n    createCarStockFavorite(input: $input) {\n      carStockFavorite {\n        id\n        carStock {\n          id\n          ...SmallCarCard_carStock\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createCarStockFavorite($input: CreateCarStockFavoriteInput!) {\n    createCarStockFavorite(input: $input) {\n      carStockFavorite {\n        id\n        carStock {\n          id\n          ...SmallCarCard_carStock\n        }\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation destroyCarStockFavorite($input: DestroyCarStockFavoriteInput!) {\n    destroyCarStockFavorite(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation destroyCarStockFavorite($input: DestroyCarStockFavoriteInput!) {\n    destroyCarStockFavorite(input: $input) {\n      isSuccess\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation login($input: LoginInput!) {\n    login(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n        zipCode\n        lineAccount {\n          id\n          name\n        }\n        favoritedCarStocks {\n          id\n        }\n        inquiries {\n          id\n          carStocks {\n            id\n          }\n        }\n      }\n      isCreatedUser\n      errors {\n        path\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation login($input: LoginInput!) {\n    login(input: $input) {\n      user {\n        id\n        lastName\n        firstName\n        zipCode\n        lineAccount {\n          id\n          name\n        }\n        favoritedCarStocks {\n          id\n        }\n        inquiries {\n          id\n          carStocks {\n            id\n          }\n        }\n      }\n      isCreatedUser\n      errors {\n        path\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateLineAccountName($input: UpdateLineAccountNameInput!) {\n    updateLineAccountName(input: $input) {\n      lineAccount {\n        id\n        name\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateLineAccountName($input: UpdateLineAccountNameInput!) {\n    updateLineAccountName(input: $input) {\n      lineAccount {\n        id\n        name\n      }\n      errors {\n        message\n        path\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query convertLatlng($longitude: Float!, $latitude: Float!) {\n    convertLatlng(longitude: $longitude, latitude: $latitude) {\n      zipCode\n      address\n      prefectureCode\n      errors {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  query convertLatlng($longitude: Float!, $latitude: Float!) {\n    convertLatlng(longitude: $longitude, latitude: $latitude) {\n      zipCode\n      address\n      prefectureCode\n      errors {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query user {\n    viewer {\n      id\n      lastName\n      firstName\n      zipCode\n      tracking\n      lineAccount {\n        id\n        name\n      }\n      favoritedCarStocks {\n        id\n      }\n      inquiries {\n        id\n        carStocks {\n          id\n        }\n      }\n      activeOneClickContracts {\n        id\n        carStock {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query user {\n    viewer {\n      id\n      lastName\n      firstName\n      zipCode\n      tracking\n      lineAccount {\n        id\n        name\n      }\n      favoritedCarStocks {\n        id\n      }\n      inquiries {\n        id\n        carStocks {\n          id\n        }\n      }\n      activeOneClickContracts {\n        id\n        carStock {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query fetchDirectUploadParams($requests: [DirectUploadRequest!]!) {\n    s3DirectUploadParams(requests: $requests) {\n      url\n      contentToken\n      fields\n    }\n  }\n"): (typeof documents)["\n  query fetchDirectUploadParams($requests: [DirectUploadRequest!]!) {\n    s3DirectUploadParams(requests: $requests) {\n      url\n      contentToken\n      fields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarStocksIndexPage($first: Int, $salesStatus: CarSalesStatusEnum) {\n    carStocks(first: $first, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...CarsIndex_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarStocksIndexPage($first: Int, $salesStatus: CarSalesStatusEnum) {\n    carStocks(first: $first, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...CarsIndex_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SpecialShowPage($first: Int, $after: String, $salesStatus: CarSalesStatusEnum, $tagGroup: TagGroupEnum) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, tagGroup: $tagGroup) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...SpecialShow_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query SpecialShowPage($first: Int, $after: String, $salesStatus: CarSalesStatusEnum, $tagGroup: TagGroupEnum) {\n    carStocks(first: $first, after: $after, salesStatus: $salesStatus, tagGroup: $tagGroup) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...SpecialShow_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarStockShowPage($ulid: String!) {\n    carStock(ulid: $ulid) {\n      id\n      makeName\n      carModelName\n      carModelYear\n      shortGradeName\n      mileageKm\n      inspectionExpiryDate\n      images {\n        id\n        url\n      }\n      promotion {\n        id\n        description\n        catchCopy\n        scores {\n          score\n        }\n      }\n      carStockDetail {\n        id\n        repairHistoryCategory\n        interiorEvaluationScore\n      }\n      ...CarsShow_carStock\n      oneClickContractCounts\n    }\n  }\n"): (typeof documents)["\n  query CarStockShowPage($ulid: String!) {\n    carStock(ulid: $ulid) {\n      id\n      makeName\n      carModelName\n      carModelYear\n      shortGradeName\n      mileageKm\n      inspectionExpiryDate\n      images {\n        id\n        url\n      }\n      promotion {\n        id\n        description\n        catchCopy\n        scores {\n          score\n        }\n      }\n      carStockDetail {\n        id\n        repairHistoryCategory\n        interiorEvaluationScore\n      }\n      ...CarsShow_carStock\n      oneClickContractCounts\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarsMoviesPage($first: Int, $after: String, $hasVideo: Boolean, $salesStatus: CarSalesStatusEnum) {\n    carStocks(first: $first, after: $after, hasVideo: $hasVideo, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...CarMovies_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarsMoviesPage($first: Int, $after: String, $hasVideo: Boolean, $salesStatus: CarSalesStatusEnum) {\n    carStocks(first: $first, after: $after, hasVideo: $hasVideo, salesStatus: $salesStatus) {\n      edges {\n        node {\n          id\n          landTransportCosts {\n            cost\n            prefectureCode\n          }\n          ...CarMovies_carStock\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Logout($input: LogoutInput!) {\n    logout(input: $input) {\n      errors {\n        message\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Logout($input: LogoutInput!) {\n    logout(input: $input) {\n      errors {\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarStock($ulid: String!) {\n    carStock(ulid: $ulid) {\n      id\n    }\n  }\n"): (typeof documents)["\n  query CarStock($ulid: String!) {\n    carStock(ulid: $ulid) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query formOptionQuery {\n    formOption {\n      id\n      paymentMethodOption {\n        id\n        value\n        label\n      }\n      requestTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n"): (typeof documents)["\n  query formOptionQuery {\n    formOption {\n      id\n      paymentMethodOption {\n        id\n        value\n        label\n      }\n      requestTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query formOptionOneClickNew {\n    formOption {\n      id\n      requestTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n"): (typeof documents)["\n  query formOptionOneClickNew {\n    formOption {\n      id\n      requestTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReviewsPage($reviewType: UserReviewReviewTypeEnum!, $first: Int, $after: String) {\n    userReviews(reviewType: $reviewType, first: $first, after: $after) {\n      nodes {\n        ...ReviewsIndex_userReview\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReviewsPage($reviewType: UserReviewReviewTypeEnum!, $first: Int, $after: String) {\n    userReviews(reviewType: $reviewType, first: $first, after: $after) {\n      nodes {\n        ...ReviewsIndex_userReview\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CarStocksSearchPage(\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $bodyType: CarStockSearchBodyTypeEnum\n    $amountType: CarStockSearchAmountTypeEnum\n    $sortType: CarStockSortTypeEnum\n    $carModelNameKeyword: String\n    $hasVideo: Boolean\n    $includeTotalCount: Boolean!\n  ) {\n    carStocks(\n      first: $first\n      after: $after\n      salesStatus: $salesStatus\n      bodyType: $bodyType\n      amountType: $amountType\n      sortType: $sortType\n      carModelNameKeyword: $carModelNameKeyword\n      hasVideo: $hasVideo\n    ) {\n      edges {\n        node {\n          ...CarsSearch_carStock\n        }\n      }\n      totalCount @include(if: $includeTotalCount)\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"): (typeof documents)["\n  query CarStocksSearchPage(\n    $first: Int\n    $after: String\n    $salesStatus: CarSalesStatusEnum\n    $bodyType: CarStockSearchBodyTypeEnum\n    $amountType: CarStockSearchAmountTypeEnum\n    $sortType: CarStockSortTypeEnum\n    $carModelNameKeyword: String\n    $hasVideo: Boolean\n    $includeTotalCount: Boolean!\n  ) {\n    carStocks(\n      first: $first\n      after: $after\n      salesStatus: $salesStatus\n      bodyType: $bodyType\n      amountType: $amountType\n      sortType: $sortType\n      carModelNameKeyword: $carModelNameKeyword\n      hasVideo: $hasVideo\n    ) {\n      edges {\n        node {\n          ...CarsSearch_carStock\n        }\n      }\n      totalCount @include(if: $includeTotalCount)\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserReviewFormOptionQuery {\n    formOption {\n      id\n      userReviewTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n"): (typeof documents)["\n  query UserReviewFormOptionQuery {\n    formOption {\n      id\n      userReviewTypeOption {\n        id\n        value\n        label\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;