import dayjs from 'dayjs';

export const priceNumber = (totalPaymentAmount: number, landTransportCost?: number | null) => {
  const totalAmount = totalPaymentAmount + (landTransportCost || 0);
  return totalAmount >= 10000 ? Math.round(totalAmount / 1000) / 10 : totalAmount;
};

export const priceText = (totalPaymentAmount: number, landTransportCost?: number | null) => {
  const totalAmount = totalPaymentAmount + (landTransportCost || 0);
  return `${totalAmount >= 10000 ? '万' : ''}円 ${landTransportCost == null ? '+ 配送料' : ''}`;
};

export const displayMileageKm = (mileageKm: number) =>
  mileageKm >= 10000 ? `${Math.floor(mileageKm / 1000) / 10}万` : mileageKm;

export const displayInspectionExpiryDate = (inspectionExpiryDate?: string) => {
  if (!inspectionExpiryDate) return '車検整備付';

  const inspectionExpiryDateYear = Number(inspectionExpiryDate.slice(0, 4));
  return `${
    inspectionExpiryDateYear >= 2020
      ? `R${('0' + (inspectionExpiryDateYear - 2018)).slice(-2)}`
      : inspectionExpiryDateYear
  }/${inspectionExpiryDate.slice(4, 6)}`;
};

export const shortCarModelYear = (carModelYear?: string) => carModelYear?.slice(0, 4);

export const isNewPublished = (publishedAt?: string | null): boolean => {
  if (!publishedAt) return false;

  return dayjs(publishedAt).isAfter(dayjs().subtract(3, 'day'));
};
