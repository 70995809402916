import { useState, type FunctionComponent } from 'react';
import { useAnalytics } from '~/hooks';
import styles from '~/styles/shared/inspectionCertificateScanModal/capture-notice.module.css';

const blockClass = 'capture-notice';

type Props = {
  onUploadedImage: (imageFile: File) => void;
  setShowLoading: (showLoading: boolean) => void;
  onError: () => void;
};

export const CaptureNotice: FunctionComponent<Props> = (props) => {
  const { onUploadedImage, setShowLoading, onError } = props;
  const [open, setOpen] = useState(true);
  const { sendTrackEvent } = useAnalytics();

  if (!open) {
    return <></>;
  }

  const uploadInspectionImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    sendTrackEvent('click_selling_form_camera_upload_button');
    setShowLoading(true);

    const files = e.target.files;
    const file = files?.[0];
    if (!file) {
      setShowLoading(false);
      onError();
      return;
    }

    onUploadedImage(file);
    setShowLoading(false);
  };

  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__top`]}>
        <div className={styles[`${blockClass}__info`]} />
        <p className={styles[`${blockClass}__text`]}>車検証全体が写るよう撮影してください</p>
        <div className={styles[`${blockClass}__close`]} onClick={() => setOpen(false)} />
      </div>
      <label htmlFor='inspection-image' className={styles[`${blockClass}__label`]}>
        <span className={styles[`${blockClass}__upload`]}>保存済みの写真を添付する方はこちら</span>
        <input
          type='file'
          id='inspection-image'
          onChange={uploadInspectionImage}
          accept='image/*'
          style={{ display: 'none' }} />

      </label>
    </div>);

};