import { useEffect } from 'react';
import { useQuery } from 'urql';
import { type UserQuery } from '~/gql/generated/graphql';
import { userQueryDocument } from '~/gql/queries';
import { useAuthContext } from '~/providers';

type AuthHook = {
  fetching: boolean;
  isAuthenticated: boolean;
  user?: UserQuery['viewer'];
};

export const useAuth = (): AuthHook => {
  const {
    fetching: authFetching,
    isPause,
    isExecuteLogin,
    isExecuteUpdateLineUserName,
    login,
    updateLineUserName
  } = useAuthContext();
  const [{ data, fetching: userFetching }] = useQuery({ query: userQueryDocument, pause: isPause });
  const user = data?.viewer;

  useEffect(() => {
    if (userFetching) return;
    if (!user) {
      if (isExecuteLogin) return;

      login();
      return;
    }
    if (isExecuteUpdateLineUserName) return;

    updateLineUserName(user);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFetching, user?.lineAccount?.name]);

  return {
    fetching: (!user && authFetching) || userFetching,
    isAuthenticated: !!user,
    user
  };
};
