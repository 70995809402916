import { useTimeout } from '@mantine/hooks';
import { useEffect, useState, type FunctionComponent } from 'react';
import { InspectionCertificateSampleModal, Modal } from '~/components/shared';
import styles from '~/styles/shared/inspectionCertificateScanModal/inspection-certificate-scan-modal.module.css';
import { Capture } from './Capture';
import { CaptureConfirm } from './CaptureConfirm';

const blockClass = 'inspection-certificate-scan-modal';

const InspectionCertificateSampleModalWrapper: FunctionComponent = () => {
  const [opened, setOpened] = useState(true);

  return <InspectionCertificateSampleModal opened={opened} close={() => setOpened(false)} isDisplayNextButton={true} />;
};

type Props = {
  opened: boolean;
  close: () => void;
  onUploaded: ({ contentToken, imageFile }: {contentToken: string;imageFile: File;}) => void;
};

export const InspectionCertificateScanModal: FunctionComponent<Props> = (props) => {
  const { opened, close, onUploaded } = props;
  const [showLoading, setShowLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { start: startCloseTimer } = useTimeout(() => setHasError(false), 2000);
  const [imageFile, setImageFile] = useState<File | null>(null);

  useEffect(() => {
    if (!hasError) return;
    startCloseTimer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  const handleClickClose = () => {
    setImageFile(null);
    close();
  };

  const handleOnUploadedImage = ({ contentToken, imageFile }: {contentToken: string;imageFile: File;}) => {
    onUploaded({ contentToken, imageFile });
    setImageFile(null);
  };

  return (
    <Modal
      isOpen={opened}
      className={styles[blockClass]}
      onRequestClose={close}
      style={{
        overlay: {
          zIndex: 201,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }
      }}>

      {opened &&
      <div style={{ height: '100%' }}>
          <div className={styles[`${blockClass}__header`]}>
            <p className={styles[`${blockClass}__header-text`]}>{imageFile ? '車検証の確認' : '車検証の撮影'}</p>
            <div className={styles[`${blockClass}__header-close`]} onClick={handleClickClose} />
            {hasError &&
          <p className={styles[`${blockClass}__header-error`]}>エラーが発生しました。もう一度お試しください。</p>
          }
          </div>
          {imageFile ?
        <CaptureConfirm
          imageFile={imageFile}
          backToCapture={() => setImageFile(null)}
          onUploadedImage={handleOnUploadedImage}
          onError={() => setHasError(true)} /> :


        <Capture
          onCaptureImage={setImageFile}
          onUploadedImage={setImageFile}
          setShowLoading={setShowLoading}
          onError={() => setHasError(true)}
          onClose={close} />

        }
          {showLoading &&
        <div className={styles[`${blockClass}__loading`]}>
              <div className={styles[`${blockClass}__loader`]} />
            </div>
        }
          <InspectionCertificateSampleModalWrapper />
        </div>
      }
    </Modal>);

};