import { type FunctionComponent } from 'react';
import styles from '~/styles/shared/review-section-selling.module.css';

const blockClass = 'review-section-selling';

export const ReviewSectionSelling: FunctionComponent = () => {
  const reviews = [
  {
    name: '福井 K.H',
    title: '新車から195000キロも走った愛車との別れ',
    icon: '/images/reviews/img_240229.jpg',
    text: 'この車は急を用して購入したこともあり、当初はそれほど思い入れがなかったのですが、流石に新車から195000キロも走ったので、たくさんの思い出が詰まっています。（仕事で失敗した時、何度も車内で泣きました笑）こいつは、どんな時も僕の側にいてくれた、立派な相棒でした。きっと輸出になるのかなと寂しくも感じましたが、それでも、どこかの国で誰かが笑顔で乗ってくれるかも、なんてことを考えながら、一生懸命きれいにしました。手はガサカサになりましたが、車はピカピカになりました！こんな気持ちになれたのもきっと、中野さんや由佐さんの熱いハートと思いをリアルに感じることができたからです。心より、感謝しております。長文、失礼しました。'
  },
  {
    name: '石川 Y.S',
    title: '満足する価格で買い取ってもらえた',
    icon: '/images/reviews/img_0228_sn.jpg',
    text: '社会人になって5年乗り続けたフィットの車検が近づき、夫娘でずっと乗ってみたかったオデッセイを探していたところ、条件のいい一台を見つけるも、下取り価格は到底希望額に近づかずで諦めかけてました...そんな時にバディカダイレクトを知り、その日の夜にすぐフィットの査定をLINE相談しました。担当していただいた長谷川さんは、今の市場価値を含め丁寧に教えていただいた上で、結果として、下取りで提示されていた価格を上回り、満足する価格で納得して買い取っていただくことができました！5年も乗り愛着もあったフィットを営業マンとして尊敬する長谷川さんそしてバディカさんにお任せすることができて本当によかったです！これからも夫娟共々未永くよろしくお願します！'
  },
  {
    name: '愛知県 M・K',
    title: '写真だけの中古車査定でしたが、予想以上に高い価格で買い取ってもらえた',
    icon: '/images/reviews/img_1229_mk.jpg',
    text: '子供が生まれるため、現在の車を売却し、スライドドア付きの軽自動車を購入することを考え、その際にバディカさんのYoutubeチャンネルを発見しました。中野さんから高く売り、安く買うための知識を得ましたが、「この際全てをバディカさんにおまかせすれば良いのでは？」と思っていると、ちょうどバディカダイレクトのモニター募集が始まりました。遠方からの陸送費や中古車査定方法など不安がありましたが、とりあえず連絡してみることにしました。初めてのZoom商談は新鮮で、自宅でリラックスして話せて良かったです。担当してくれた由佐さんはとても話しやすく、質疑応答も満足にできました。妻も商談を楽しんでいたようです。写真だけの中古車査定でしたが、予想以上に高い価格で買い取ってもらえました。信頼さえできれば非常に便利な取引システムだと思います。中野さんや由佐さん、店長さん達は各所に顔を出して堂々と商売している訳ですから、バディカさんは安心して取引できる企業だと感じています。今回は中古車を購入するにあたりバディカ様を選んで良かったです。'
  }];


  return (
    <div className={styles[blockClass]}>
      <div className={styles[`${blockClass}__header`]}>
        <p className={styles[`${blockClass}__header-title`]}>愛車査定サービス利用者の声</p>
      </div>
      <div className={styles[`${blockClass}__reviews`]}>
        {reviews.map((review, index) =>
        <div className={styles[`${blockClass}__reviews-item`]} key={index}>
            <p className={styles[`${blockClass}__reviews-item__title`]}>{review.title}</p>
            <p className={styles[`${blockClass}__reviews-item__name`]}>{review.name}様</p>
            <div className={styles[`${blockClass}__reviews-item__img`]}>
              <img src={review.icon} alt='' />
            </div>
            <div className={styles[`${blockClass}__reviews-item__text`]}>
              {review.text.split(/\n/).map((line, index) =>
            <p key={index}>{line}</p>
            )}
            </div>
          </div>
        )}
      </div>
    </div>);

};