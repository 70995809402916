import { useClient } from 'urql';
import { graphql } from '~/gql/generated';
import type { FetchDirectUploadParamsQuery } from '~/gql/generated/graphql';

const fetchDirectUploadParamsQueryDocument = graphql(`
  query fetchDirectUploadParams($requests: [DirectUploadRequest!]!) {
    s3DirectUploadParams(requests: $requests) {
      url
      contentToken
      fields
    }
  }
`);

type DirectUploadResponse = {
  contentToken: string;
};

type S3DirectUploadHook = {
  directUpload: (file: File) => Promise<DirectUploadResponse>;
};

type S3DirectUploadParams = FetchDirectUploadParamsQuery['s3DirectUploadParams'][number];

export const useS3DirectUpload = (): S3DirectUploadHook => {
  const client = useClient();

  const directUpload = async (file: File): Promise<DirectUploadResponse> => {
    try {
      const directUploadParams = await fetchDirectUploadParams(file);
      if (!directUploadParams) {
        throw new Error('Failed to fetch direct upload params');
      }
      await uploadFile(file, directUploadParams);
      return { contentToken: directUploadParams.contentToken };
    } catch (e) {
      throw new Error('Failed direct upload');
    }
  };

  const fetchDirectUploadParams = async (file: File): Promise<S3DirectUploadParams | undefined> => {
    try {
      const result = await client.query(
        fetchDirectUploadParamsQueryDocument,
        {
          requests: [{ contentType: file.type, filename: file.name, size: file.size }]
        },
        {
          requestPolicy: 'network-only'
        }
      );
      return result.data?.s3DirectUploadParams[0];
    } catch (e) {
      return undefined;
    }
  };

  const uploadFile = async (file: File, directUploadParams: S3DirectUploadParams) => {
    const formData = new FormData();
    const parsedFields = JSON.parse(directUploadParams.fields);
    Object.keys(parsedFields).forEach((key) => {
      formData.append(key, parsedFields[key]);
    });
    formData.append('file', file);

    const response = await fetch(directUploadParams.url, {
      method: 'POST',
      headers: { Accept: 'multipart/form-data' },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;
  };

  return { directUpload };
};
