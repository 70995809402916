import { type FunctionComponent } from 'react';
import styles from '~/styles/shared/youtube-section.module.css';

const blockClass = 'youtube-section';

export const YoutubeSection: FunctionComponent = () => {
  const youtubes = [
  {
    url: 'https://www.youtube.com/watch?v=0P2lX6E8tdk',
    image: 'https://img.youtube.com/vi/0P2lX6E8tdk/hqdefault.jpg'
  },
  {
    url: 'https://www.youtube.com/watch?v=hEGTHrC7nxA',
    image: 'https://img.youtube.com/vi/hEGTHrC7nxA/hqdefault.jpg'
  },
  {
    url: 'https://www.youtube.com/watch?v=qurk6no7kIw',
    image: 'https://img.youtube.com/vi/qurk6no7kIw/hqdefault.jpg'
  },
  {
    url: 'https://www.youtube.com/watch?v=bdeOTfmQn2g',
    image: 'https://img.youtube.com/vi/bdeOTfmQn2g/hqdefault.jpg'
  },
  {
    url: 'https://www.youtube.com/watch?v=tXqcSl-Cjik',
    image: 'https://img.youtube.com/vi/tXqcSl-Cjik/hqdefault.jpg'
  },
  {
    url: 'https://www.youtube.com/watch?v=RJ7IdoZZJ6g',
    image: 'https://img.youtube.com/vi/RJ7IdoZZJ6g/hqdefault.jpg'
  },
  {
    url: 'https://www.youtube.com/watch?v=SdeeSvvS3cA',
    image: 'https://img.youtube.com/vi/SdeeSvvS3cA/hqdefault.jpg'
  },
  {
    url: 'https://www.youtube.com/watch?v=GNtaTR2iKZ8',
    image: 'https://img.youtube.com/vi/GNtaTR2iKZ8/hqdefault.jpg'
  }];

  return (
    <div className={styles[blockClass]}>
      <p className={styles[`${blockClass}__text`]}>登録者数35万人突破!</p>
      <div className={styles[`${blockClass}__header`]}>
        <p className={styles[`${blockClass}__header-title`]}>中野優作のYouTube</p>
        <a href='https://www.youtube.com/@yuusaku_buddica' className={styles[`${blockClass}__header-link`]}>
          もっと見る
        </a>
      </div>
      <div className={styles[`${blockClass}__images`]}>
        {youtubes.map((youtube, index) =>
        <a href={youtube.url} key={index} className={styles[`${blockClass}__images-item`]}>
            <img src={youtube.image} alt='youtube' />
          </a>
        )}
      </div>
    </div>);

};