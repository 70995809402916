import liff from '@line/liff';
import { type FunctionComponent } from 'react';
import { useAnalytics } from '~/hooks';
import { useLineLoginMethods } from '~/hooks/useLineLoginMethods';
import styles from '~/styles/shared/login-retry-modal.module.css';
import { Modal } from '../Modal/Modal';

type Props = {
  opened: boolean;
  redirectPath: string;
};

export const LoginRetryModal: FunctionComponent<Props> = (props) => {
  const { opened, redirectPath } = props;
  const { lineUrlSchemeLogin, browserLogin, lineAddFriend } = useLineLoginMethods({ redirectPath });
  const { sendTrackEvent } = useAnalytics();

  const handleClickButton = () => {
    sendTrackEvent('login_retry_modal_button_click');

    if (liff.getOS() === 'web') {
      browserLogin();
      return;
    }

    lineUrlSchemeLogin();
  };

  const handleClickLink = () => {
    sendTrackEvent('login_retry_modal_add_friend_link_click');
    lineAddFriend();
  };

  return (
    <Modal
      isOpen={opened}
      className={styles.main}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 201
        }
      }}>

      {opened &&
      <>
          <span className={styles.button} onClick={handleClickButton}>
            LINEアプリで開く
          </span>
          <p className={styles.text}>
            ボタンを押してもLINEアプリに
            <br />
            遷移しない場合は
            <span className={styles.link} onClick={handleClickLink}>
              こちら
            </span>
          </p>
        </>
      }
    </Modal>);

};