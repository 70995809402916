import liff from '@line/liff';
import { type FunctionComponent } from 'react';
import { useClientEnvContext, useErrorFlashContext, useFlashContext, useLiffContext } from '~/providers';
import styles from '~/styles/shared/banners/miniapp-shortcut-banner.module.css';

export const MiniappShortcutBanner: FunctionComponent = () => {
  const { isMiniApp } = useLiffContext();
  const { miniappLiffId } = useClientEnvContext();
  const { open: openFlash } = useFlashContext();
  const { open: openErrorFlash } = useErrorFlashContext();

  const clickBanner = async () => {
    liff.
    createShortcutOnHomeScreen({ url: `https://miniapp.line.me/${miniappLiffId}` }).
    then(() => openFlash(<p>ホーム画面に追加しました！</p>)).
    catch(() => openErrorFlash());
  };

  if (!isMiniApp) {
    return <></>;
  }

  return (
    <div className={styles['miniapp-shortcut-banner']}>
      <img src={'/images/banners/miniapp_shortcut.png'} alt='ミニアプリ' width={'100%'} onClick={clickBanner} />
    </div>);

};