import { type FunctionComponent } from 'react';
import ReactModal from 'react-modal';

type Props = ReactModal.Props;

export const Modal: FunctionComponent<Props> = (props) => {
  return (
    <ReactModal
      {...props}
      appElement={typeof window !== 'undefined' ? window.document.getElementById('root') || undefined : undefined}
    />
  );
};
