import { type FunctionComponent } from 'react';
import styles from '~/styles/shared/inspectionCertificateScanModal/camera-access-popup.module.css';

const blockClass = 'camera-access-popup';

type Props = {
  close?: () => void;
};

export const CameraAccessPopup: FunctionComponent<Props> = (props) => {
  const { close } = props;

  return (
    <div className={styles[blockClass]}>
      {close && <div className={styles[`${blockClass}__close`]} onClick={close} />}
      <p className={styles[`${blockClass}__title`]}>
        端末のカメラの使用を
        <br />
        許可してください
      </p>
      <img className={styles[`${blockClass}__camera`]} src='/images/icons/ic_camera_orange.svg' alt='カメラ' />
      <p className={styles[`${blockClass}__text`]}>車検証の撮影をする為にカメラを起動します。</p>
    </div>);

};