import { Link } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import styles from '~/styles/shared/banners/staff-popup-banner.module.css';

export const StaffPopupBanner: FunctionComponent = () => {
  return (
    <Link to='/add_line_friend?inflow_source_message=call'>
      <div className={styles.banner}>
        <div className={styles.wrapper}>
          <img src={'/images/banners/staff_popup.png'} alt='接客スタッフオンライン中 LINE電話相談' width={100} />
        </div>
      </div>
    </Link>);

};