import styles from '~/styles/shared/car-empty.module.css';
import { CarRequest } from '../CarRequest/CarRequest';
const blockClass = 'car-empty';

export const CarEmpty = () => {
  return (
    <>
      <div className={styles[`${blockClass}__block`]}>
        <img src='/images/icons/ic_caution.svg' alt='caution' />
        <p className={styles[`${blockClass}__block-text`]}>
          申し訳ありません
          <br />
          お探しのクルマは見つかりませんでした
        </p>
      </div>

      <CarRequest />
    </>);

};